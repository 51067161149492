import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const SettingsComp = React.lazy(
  () => import('../../components/PositionHandover/Settings')
);

const Settings = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.positionHandoverTrader}
    >
      <SettingsComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default Settings;
