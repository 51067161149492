/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import React from 'react';
import { Option, Select } from '@sede-x/shell-ds-react-framework';
import { ISelectProps } from '@sede-x/shell-ds-react-framework/build/esm/components/Select/Select.types';
import { Controller } from 'react-hook-form';
import ErrorMessage from '../../../../UI/ErrorMessage';
import { IMemoisedComponentProp } from '../../../../types/Common.type';
import useGetFormulas from '../../../../hooks/UKOps/useGetFormulas';

interface IFormControlSelect extends IMemoisedComponentProp {
  name: string;
  label: string;
  placeholder: string;
  required: boolean | string;
  selectProps?: ISelectProps;
}

const FormControlSelectFormula: React.FC<IFormControlSelect> = ({
  methods,
  name,
  required,
  label,
  placeholder,
  selectProps,
}) => {
  const { formulas, loadingFormula } = useGetFormulas();

  const {
    formState: { errors },
    control,
  } = methods;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value } }) => (
          <div>
            <span>{label}</span>
            <Select
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              invalid={!!errors[name]}
              loading={loadingFormula}
              {...selectProps}
              optionFilterProp='name'
              optionLabelProp='name'
            >
              {formulas.map((item) => (
                <Option
                  key={`${item.id}_${item.name}`}
                  value={item.id}
                  name={item.name}
                >
                  <div>{item.name}</div>
                  <div className='formula'>{item.formula}</div>
                </Option>
              ))}
            </Select>
          </div>
        )}
      />
      {errors[name] && errors[name]?.message && (
        <ErrorMessage>{errors[name]?.message}</ErrorMessage>
      )}
    </>
  );
};
export default FormControlSelectFormula;
