export const chartColorOption1 = [
  '#6065d0',
  '#3ecd6f',
  '#ea72c8',
  '#00b8d4',
  '#98e7ff',
  '#9c27b0',
  '#000000',
  '#f44336',
  '#673ab7',
  '#03a9f4',
  '#4caf50',
  '#ffeb3b',
  '#ff5722',
  '#e91e63',
  '#3f51b5',
  '#00bcd4',
  '#8bc34a',
  '#ffc107',
  '#795548',
  '#9c27b0',
  '#2196f3',
  '#009688',
  '#cddc39',
  '#ff9800',
  '#9e9e9e',
  '#ef9a9a',
  '#b39ddb',
  '#81d4fa',
  '#a5d6a7',
  '#fff59d',
  '#37474f',
  '#ffab91',
  '#f48fb1',
  '#9fa8da',
  '#80deea',
  '#c5e1a5',
  '#ffe082',
  '#bcaaa4',
  '#ce93d8',
  '#90caf9',
  '#80cbc4',
  '#e6ee9c',
  '#ffcc80',
];

export const chartColorOption2 = ['#6065d0', '#3ecd6f'];

export const Titles = {
  ACTIVE: 'Are you sure you want to Active?',
  INACTIVE: 'Are you sure you want to Inactive?',
};

export const EMAIL_REGEX = /^[_A-Za-z0-9.+-]+(\\.[_A-Za-z0-9-]+)*@shell.com$/;

export const themeColors = {
  DARK: 'rgb(54, 61, 68)',
  LIGHT: '#fff',
};
