import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Heading, Icons, Button } from '@sede-x/shell-ds-react-framework';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userRoleActions } from '../../store/User';
import routepaths from '../../router/routepaths';

const LogoutContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.clear();
    dispatch(userRoleActions.logOut());
  }, [dispatch]);
  const logIn = () => {
    navigate(routepaths.login);
  };

  const { LogOut: LogOutIcon } = Icons;

  return (
    <LogoutContainer>
      <div>
        <LogOutIcon width={100} height={100} />
      </div>
      <Heading type='h1'>You are logged out</Heading>
      <div>
        <Button size='medium' onClick={logIn}>
          Log In
        </Button>
      </div>
    </LogoutContainer>
  );
};

export default Logout;
