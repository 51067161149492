import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import { FullStateI } from '../../../../types/All.type';
import NotificationWrapper from './style';
import Loader from '../../../../UI/Loader';

dayjs.extend(utc);
dayjs.extend(timezone);

const FieldNomsNotifications = () => {
  const { loading, data } = useSelector(
    (state: FullStateI) => state.FieldNoms.notifications
  );

  return (
    <NotificationWrapper>
      {loading && <Loader />}
      {data.map((item) => (
        <div key={uuidv4()} className='notification_container'>
          <div className='title'>{item.note}</div>
          <div className='deadline-time'>
            <span>Deadline Time:-</span>
            {dayjs(`01-01-2000 ${item.timer}`).format('hh:mm A')}
          </div>
          <Divider size='small' />
        </div>
      ))}
    </NotificationWrapper>
  );
};

export default FieldNomsNotifications;
