import React from 'react';
import routepaths from './routepaths';
import { LOCATION_ID } from '../constants/CredentialsStore';

import CredstoreViewCredentials from '../pages/credstore/CredStoreView';
import CredstoreAddCredentials from '../pages/credstore/CredStoreAdd';
import CredstoreEditCredentials from '../pages/credstore/CredStoreEdit';

import IAppRoute from './AppRoute.type';

const CredentialsStoreRoutes: IAppRoute[] = [
  {
    path: routepaths.credstoreViewCredentials,
    element: (
      <CredstoreViewCredentials
        key='compseelViewCredentials'
        locationId={LOCATION_ID.SEEL}
      />
    ),
  },
  {
    path: routepaths.senaPasswordhubViewCredentials,
    element: (
      <CredstoreViewCredentials
        key='compsenaViewCredentials'
        locationId={LOCATION_ID.SENA}
      />
    ),
  },
  {
    path: routepaths.credstoreAddCredentials,
    element: (
      <CredstoreAddCredentials
        key='compseelAddCredentials'
        locationId={LOCATION_ID.SEEL}
      />
    ),
  },
  {
    path: routepaths.senaPasswordhubAddCredentials,
    element: (
      <CredstoreAddCredentials
        key='compsenaAddCredentials'
        locationId={LOCATION_ID.SENA}
      />
    ),
  },
  {
    path: routepaths.credstoreEditCredentials,
    element: (
      <CredstoreEditCredentials
        key='compseelEditCredentials'
        locationId={LOCATION_ID.SEEL}
      />
    ),
  },
  {
    path: routepaths.senaPasswordhubEditCredentials,
    element: (
      <CredstoreEditCredentials
        key='compsenaAddCredentials'
        locationId={LOCATION_ID.SENA}
      />
    ),
  },
  {
    path: routepaths.credstoreViewCredentials,
    element: (
      <CredstoreViewCredentials
        key='compseelViewCredentials'
        locationId={LOCATION_ID.SEEL}
      />
    ),
  },
  {
    path: routepaths.senaPasswordhubViewCredentials,
    element: (
      <CredstoreViewCredentials
        key='compsenaViewCredentials'
        locationId={LOCATION_ID.SENA}
      />
    ),
  },
  {
    path: routepaths.credstoreViewCredentials,
    element: (
      <CredstoreViewCredentials
        key='compseelViewCredentials'
        locationId={LOCATION_ID.SEEL}
      />
    ),
  },
];
export default CredentialsStoreRoutes;
