import React from 'react';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import moment from 'moment';
import { COPApi } from '../../../../services/COPApi';
import { DownloadExcelAPI } from '../../../../constants/OpsChecklist';
import { TaskAllInfoI } from '../../../../types/OpsChecklist.type';
import { ITaskComp } from './type';
import searchByText from '../../../../utils/searchFunction';

const { Edit: EditIcon, TrashAltSolid: DeleteIcon } = Icons;

export const onDownloadTaskClick = () => {
  COPApi({
    url: DownloadExcelAPI,
    method: 'POST',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Tasks(${moment().format('YYYY-MM-DDTHH.mm.ssZ')}).xlsx`
    );
    document.body.appendChild(link);
    link.click();
  });
};

export const mapTasks = (
  tasks: TaskAllInfoI[],
  tOpsTeam: number | undefined,
  market: number | undefined,
  shift: number | undefined,
  selectedRecurringType: number | undefined,
  keyWord: string,
  isDark: boolean,
  onClickHandler: (item: TaskAllInfoI, action: string) => void,
  isReadOnly: boolean
): ITaskComp[] => {
  const newTaskList: ITaskComp[] = [];

  tasks.forEach((task) => {
    // if (tOpsTeam && task.teamId !== tOpsTeam) {
    //   return;
    // }
    // if (market && task.countryId !== market) {
    //   return;
    // }
    // if (shift && task.shiftId !== shift) {
    //   return;
    // }
    // if (
    //   selectedRecurringType &&
    //   task.recurringTypeId !== selectedRecurringType
    // ) {
    //   return;
    // }
    newTaskList.push({
      ...task,
      key: task.taskGroupKey,
      clone: (
        <Button
          size='small'
          variant='transparent'
          onClick={() => onClickHandler(task, 'clone')}
          disabled={isReadOnly}
        >
          <img
            src={`/copy-icon${isDark ? '-dark' : ''}.svg`}
            alt='copy icon'
            width={18}
            height={18}
          />
        </Button>
      ),
      edit: (
        <Button
          size='small'
          variant='transparent'
          onClick={() => onClickHandler(task, 'edit')}
          key={`tsk-edit-btn-${task.id}`}
          disabled={isReadOnly}
        >
          <EditIcon height={20} width={20} />
        </Button>
      ),
      delete: (
        <Button
          size='small'
          variant='transparent'
          onClick={() => onClickHandler(task, 'delete')}
          key={`tsk-dl-btn-${task.id}`}
          disabled={isReadOnly}
        >
          <DeleteIcon height={20} width={20} />
        </Button>
      ),
    });
  });
  if (keyWord) {
    return searchByText(newTaskList, keyWord.toLowerCase(), [
      'shortName',
      'description',
      'shiftName',
      'countryName',
      'dataSourceDescription',
      'manualSendReceiveProcessId',
      'recurringTypeId',
      'recurringTypeName',
      'weekDayName',
      'startTime',
      'endTime',
      'priority',
      'alertEmails',
      'comment',
    ]);
  }
  return newTaskList;
};

export const mapOpportunities = (
  opportunities: any[],
  keyWord: string
): any[] => {
  const newOppsList: any[] = [];

  opportunities.forEach((item) => {
    newOppsList.push({
      ...item,
    });
  });
  if (keyWord) {
    const result = searchByText(newOppsList, keyWord.toLowerCase(), [
      'name',
      'description',
      'status',
      'createdDate',
    ]);
    return result;
  }
  return newOppsList;
};
