import React from 'react';
import routepaths from './routepaths';

import SenaComp from '../pages/Sena';
import SenaSettingsComp from '../pages/SenaSettings';
import EPSQCalculatorComp from '../pages/EPSQCalculator';

import IAppRoute from './AppRoute.type';

const SENARoutes: IAppRoute[] = [
  { path: routepaths.sena, element: <SenaComp /> },
  { path: routepaths.senaSettings, element: <SenaSettingsComp /> },
  { path: routepaths.epsqCalculator, element: <EPSQCalculatorComp /> },
];

export default SENARoutes;
