import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const ScheduleRunComponent = React.lazy(
  () => import('../components/FirstUtility/ScheduleRun')
);

const ScheduleRun: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.firstUtility}>
      <ScheduleRunComponent />
    </CheckAuthorizationWithSuspense>
  );
};

export default ScheduleRun;
