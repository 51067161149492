/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import React from 'react';
import { SingleDatePicker } from '@sede-x/shell-ds-react-framework';
import { ISingleDatePickerProps } from '@sede-x/shell-ds-react-framework/build/esm/components/DatePicker/components/SingleDatePicker/SingleDatePicker.types';
import { Controller } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import ErrorMessage from '../ErrorMessage';
import { IMemoisedComponentProp } from '../../types/Common.type';

interface ITextInputControl extends IMemoisedComponentProp {
  name: string;
  label: string;
  required: boolean | string;
  handleOnChange?: (date: Dayjs | null) => void;
  inputProps?: ISingleDatePickerProps;
}

const FormControlDatePicker: React.FC<ITextInputControl> = ({
  methods,
  name,
  required,
  label,
  handleOnChange,
  inputProps,
}) => {
  const {
    formState: { errors },
    control,
  } = methods;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required,
        }}
        render={({ field: { onChange, value } }) => (
          <div>
            <span>{label}</span>
            <SingleDatePicker
              onChange={(date) => {
                if (handleOnChange) handleOnChange(date);
                onChange(date);
              }}
              value={value}
              invalid={!!errors[name]}
              {...inputProps}
            />
            {errors[name] && errors[name]?.message && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </div>
        )}
      />
    </>
  );
};
export default FormControlDatePicker;
