import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { fetchPendingTask } from '../store/Checklist/CLPendingTask';
import { FullStateI } from '../types/All.type';

const useDispatchOnLoadActions = () => {
  const dispatch = useDispatch();
  const {
    User: { isAuthorized },
  } = useSelector((state: FullStateI) => state);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(fetchPendingTask());
    }
  }, [dispatch, isAuthorized]);
};
export default useDispatchOnLoadActions;
