/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Heading,
  Icons,
  Loading,
  RadioButton,
} from '@sede-x/shell-ds-react-framework';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CardSection } from '../../../UI/Card';
import Flexbox from '../../../UI/Flexbox';
import { Col, FormRow, Row } from '../../../UI/Grid';
import {
  GetCapacityBookedDailyVolumeAPI,
  GetCapacityBookedMonthlyVolumeAPI,
} from '../../../constants/Dashboard';
import { chartColorOption1 } from '../../../constants/general';
import useCOPGetApi from '../../../hooks/useCOPGetApi';
import routepaths from '../../../router/routepaths';
import { FullStateI } from '../../../types/All.type';
import {
  TVolumeDailyReportList,
  TVolumeMonthlyReportList,
} from '../../../types/Dashboard.type';
import { getOptions, sortDatesUtil } from '../common';

const { Cross: CrossIcon, ArrowCcw: ArrowCcwIcon } = Icons;

const HistoryContainer = styled.div`
  .refresh-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .form-row {
    margin-bottom: unset;
  }

  .trad-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const TradContainer = styled.div`
  padding: 10px 15px;

  .trad-option {
    margin: 5px 0;
  }
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IMarketDailyVolume {
  [key: string]: number[];
}

const getChartData = (
  volumeBooked: TVolumeDailyReportList | TVolumeMonthlyReportList
) => {
  let labels: string[] = [];
  const marketDailyVolume: IMarketDailyVolume = {};

  volumeBooked.forEach((item) => {
    const date = moment(item.bookedDate).format('DD MMM YYYY');
    const label = item?.deliveryMonth
      ? `${item.deliveryMonth} ${item.deliveryYear}`
      : date;
    if (!labels.includes(label)) {
      labels.push(label);
    }
  });

  labels = sortDatesUtil({ labels, order: 'asc' });

  volumeBooked.forEach((item) => {
    const date = moment(item.bookedDate).format('DD MMM YYYY');
    const label = item?.deliveryMonth
      ? `${item.deliveryMonth} ${item.deliveryYear}`
      : date;

    if (item.countryCode in marketDailyVolume) {
      marketDailyVolume[item.countryCode][labels.indexOf(label)] = parseFloat(
        item.volumeBooked
      );
    } else {
      marketDailyVolume[item.countryCode] = labels.map((objLabel) => {
        if (label === objLabel) return parseFloat(item.volumeBooked);
        return 0;
      });
    }
  });

  const datasets = Object.keys(marketDailyVolume).map((countryCode, index) => {
    return {
      label: countryCode,
      data: marketDailyVolume[countryCode],
      backgroundColor: chartColorOption1[index],
    };
  });

  return {
    labels,
    datasets,
  };
};

const CapacityVolumeBookedHistory = () => {
  const navigate = useNavigate();
  const { isDark } = useSelector((state: FullStateI) => state.Theme);
  const options = useMemo(() => getOptions(isDark), [isDark]);
  const { type } = useParams();

  const [refreshCount, setRefreshCount] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [tradOptions, setTradOptions] = useState<string>('daily');
  const [headerText, setHeaderText] = useState<string>('daily');
  const [data, setData] = useState<any>([]);
  const [unit, setUnit] = useState('');

  const { isLoading, data: chartData } = useCOPGetApi<TVolumeDailyReportList>(
    GetCapacityBookedDailyVolumeAPI(7),
    [],
    refreshCount
  );

  const { isLoading: isLoadingMonthly, data: chartDataMonthly } =
    useCOPGetApi<TVolumeMonthlyReportList>(
      GetCapacityBookedMonthlyVolumeAPI(7),
      [],
      refreshCount
    );

  useEffect(() => {
    if (type) {
      setTradOptions(type);
      if (type !== 'monthly' && type !== 'daily') {
        navigate(-1);
      }
    }
  }, []);

  const onCloseClick = () => {
    navigate(routepaths.dashboard);
  };

  function waitForTwoSeconds() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('Waited for 2 seconds');
      }, 2000); // 2000 milliseconds (2 seconds)
    });
  }

  const onRefreshClick = async () => {
    setIsRefreshing(true);
    await waitForTwoSeconds();
    setRefreshCount((count) => count + 1);
    setIsRefreshing(false);
  };

  useEffect(() => {
    let title = '';
    switch (tradOptions) {
      case 'daily':
        setUnit(chartData?.[0]?.volumeBookedUnit);
        setData([...chartData]);
        title = 'Daily';
        break;
      case 'monthly':
        setUnit(chartDataMonthly?.[0]?.volumeBookedUnit);
        setData([...chartDataMonthly]);
        title = 'Monthly';
        break;
      default:
        setData([]);
        title = '';
        break;
    }
    setHeaderText(title);
  }, [tradOptions, chartData, chartDataMonthly]);

  const handleRadioOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTradOptions(event.target.value);
  };

  return (
    <HistoryContainer>
      <CardSection>
        <FormRow className='form-row'>
          <Col size={12}>
            <FormRow className='form-row'>
              <Col size={4}>
                <Heading type='h3'>
                  {`Capacity Volume Booked - ${headerText}`}
                </Heading>
              </Col>
              <Col className='refresh-wrapper' size={8}>
                <Flexbox gap='15px' alignItems='center'>
                  {isLoading || isLoadingMonthly || isRefreshing ? (
                    <Loading size='large' />
                  ) : (
                    <>
                      <div>
                        {chartData.length > 0 && !isRefreshing && !isLoading
                          ? `Last updated at ${moment
                              .utc(
                                chartData?.[chartData.length - 1]?.bookedDate
                              )
                              .local()
                              .format('Do MMMM YYYY, hh:mm A')}`
                          : ''}
                      </div>
                      <Button
                        variant='outlined'
                        size='medium'
                        icon={<ArrowCcwIcon />}
                        onClick={onRefreshClick}
                        iconOnly
                      />
                    </>
                  )}
                  <Button
                    variant='outlined'
                    size='medium'
                    icon={<CrossIcon />}
                    onClick={onCloseClick}
                    iconOnly
                  />
                </Flexbox>
              </Col>
            </FormRow>
          </Col>
        </FormRow>
      </CardSection>
      <CardSection>
        <Row>
          <Col size={2}>
            <TradContainer>
              <RadioButton
                className='trad-option'
                label='Daily'
                checked={tradOptions === 'daily'}
                onChange={handleRadioOnChange}
                value='daily'
                size='small'
              />
              <RadioButton
                label='Monthly'
                className='trad-option'
                checked={tradOptions === 'monthly'}
                onChange={handleRadioOnChange}
                value='monthly'
                size='small'
              />
            </TradContainer>
          </Col>
          <Col size={8}>
            <Bar options={options} data={getChartData(data)} />
            <div className='trad-container'>
              <div>
                {isLoading || isLoadingMonthly ? (
                  ''
                ) : (
                  <small>{unit && `In ${unit}`}</small>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </CardSection>
    </HistoryContainer>
  );
};

export default CapacityVolumeBookedHistory;
