import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const AddUserWithRoleComp = React.lazy(
  () => import('../../components/UserAdministration/AddUserWithRole')
);

const AddUserWithRole = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.userAdministration}
      // role={userRole.readWrite}
    >
      <AddUserWithRoleComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default AddUserWithRole;
