// Loader.js
import React from 'react';
import ReactDOM from 'react-dom';
import './Loader.css'; // Your loader styling
import { Loading } from '@sede-x/shell-ds-react-framework';

const Loader = () => {
  return ReactDOM.createPortal(
    <div className='loader-overlay'>
      <Loading size='large' />
    </div>,
    document.body
  );
};

export default Loader;
