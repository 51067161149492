import React from 'react';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';
import FieldNomination from '../pages/UKOps/FieldNomination';
import TerminalMapping from '../components/UKOps/FieldNomination/TerminalMapping';

const UKOpsRoutes: IAppRoute[] = [
  {
    path: routePaths.fieldNomination,
    element: <FieldNomination />,
  },
  {
    path: routePaths.terminalMapping,
    element: <TerminalMapping />,
  },
];

export default UKOpsRoutes;
