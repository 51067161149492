import styled, { css } from 'styled-components';

type Align = BaseFlexAlign | 'baseline' | 'stretch';
type AlignContent =
  | BaseFlexAlign
  | 'space-around'
  | 'space-between'
  | 'stretch';
type BaseFlexAlign = 'flex-start' | 'center' | 'flex-end';
type Display = 'flex' | 'inline-flex';
type FlexDirection = 'column-reverse' | 'column' | 'row-reverse' | 'row';
type FlexWrap = 'nowrap' | 'wrap-reverse' | 'wrap';
type JustifyContent = BaseFlexAlign | 'space-around' | 'space-between';

interface FlexboxProps {
  readonly alignContent?: AlignContent;
  readonly alignItems?: Align;
  readonly alignSelf?: Align;
  readonly display?: Display;
  readonly flex?: string | number;
  readonly flexBasis?: string | number;
  readonly flexDirection?: FlexDirection;
  readonly flexGrow?: string | number;
  readonly flexShrink?: string | number;
  readonly flexWrap?: FlexWrap;
  readonly justifyContent?: JustifyContent;
  readonly order?: number;
  readonly gap?: string;
}

const Flexbox = styled.div.attrs<FlexboxProps>(
  ({ gap, flexBasis, flexGrow, flexShrink, order }) => ({
    style: {
      flexBasis,
      flexGrow,
      flexShrink,
      order,
      gap,
    },
  })
)<FlexboxProps>`
  ${({
    flex,
    display,
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    alignContent,
    alignSelf,
  }) => css`
    flex: ${flex};
    display: ${display ?? 'flex'};
    ${flexDirection && `flex-direction: ${flexDirection}`};
    ${flexWrap && `flex-wrap: ${flexWrap}`};
    ${justifyContent && `justify-content: ${justifyContent}`};
    ${alignItems && `align-items: ${alignItems}`};
    ${alignContent && `align-content: ${alignContent}`};
    ${alignSelf && `align-self: ${alignSelf}`};
  `}
`;

export default Flexbox;
