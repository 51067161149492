/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IToggle {
  isToggle: boolean;
}
const isToggleVal = localStorage.getItem('toggleMenu');
const isToggle = isToggleVal !== 'false';
const initialState: IToggle = {
  isToggle,
};

const Toggle = createSlice({
  name: 'toggle',
  initialState,
  reducers: {
    updateToggle: (state: IToggle, { payload }: PayloadAction<IToggle>) => {
      localStorage.setItem('toggleName', payload.isToggle ? 'true' : 'false');
      state.isToggle = payload.isToggle;
    },
  },
});

export const toggleActions = Toggle.actions;
export default Toggle.reducer;
