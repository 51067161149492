import { ISideNavMenu } from './type';

export default function processMenuAndSubMenu(menu: ISideNavMenu[]) {
  const accessMap = {};
  const processItem = (item) => {
    if (!item) return;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { linkedApplication, access, subMenu, internalMenu } = item;

    if (linkedApplication && access) {
      if (!accessMap[linkedApplication]) {
        accessMap[linkedApplication] = access;
      } else {
        accessMap[linkedApplication] = Array.from(
          new Set([accessMap[linkedApplication], access])
        ).join(',');
      }
    }

    if (subMenu) {
      subMenu.forEach(processItem);
    }

    if (internalMenu) {
      internalMenu.forEach(processItem);
    }
  };
  menu.forEach(processItem);
  return accessMap;
}

export function changeKeyNameAndRemoveNullPath(arr, oldKey, newKey) {
  return arr.map((item) => {
    // Create a new object to avoid modifying the original object
    const newItem = { ...item };

    // Check if the oldKey exists in the object
    // eslint-disable-next-line no-prototype-builtins
    if (newItem.hasOwnProperty(oldKey)) {
      // Rename the key
      newItem[newKey] = newItem[oldKey];
      delete newItem[oldKey]; // Remove the old key

      // Convert the value to a string if it is an 'id'
      if (newKey === 'id' && typeof newItem[newKey] !== 'string') {
        newItem[newKey] = String(newItem[newKey]);
      }
    }

    // Check if the "path" property is null, and remove it
    if (newItem.path === null) {
      delete newItem.path;
    }

    // Check if the object has subMenu and recursively change keys
    if (newItem.subMenu && newItem.subMenu.length > 0) {
      newItem.subMenu = changeKeyNameAndRemoveNullPath(
        newItem.subMenu,
        oldKey,
        newKey
      );
    }

    return newItem;
  });
}
