import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const OclEditTaskComp = React.lazy(
  () => import('../components/OpsChecklist/EditTask')
);

const OclEditTask: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.checkListAddTask}>
      <OclEditTaskComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default OclEditTask;
