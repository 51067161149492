import React from 'react';
import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const ViewLogs = React.lazy(() => import('./ViewLogs'));
// const GtoSettings = React.lazy(() => import('./GtoSettings'));
const GTOAttachments = React.lazy(() => import('./GTOAttachments'));

type GtoMainProps = 'gtoLogs' | 'gtoSettings' | 'gtoAttachments';

interface GtoMainCompProps {
  type: GtoMainProps;
}

const GtoMainComp: React.FC<GtoMainCompProps> = ({ type }) => {
  const pageName = applicationName.gtoLogs;

  return (
    <CheckAuthorizationWithSuspense pageName={pageName}>
      {type === 'gtoLogs' && <ViewLogs />}
      {type === 'gtoAttachments' && <GTOAttachments />}
      {/* {type === 'gtoSettings' && <GtoSettings />} */}
    </CheckAuthorizationWithSuspense>
  );
};

export default GtoMainComp;
