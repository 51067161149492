export const CHECKLIST_TYPE_ID = {
  WEEKEND_SETUP_TASK: 1,
  WEEKDAY_SETUP_TASK: 2,
  MONTHLY_SETUP_TASK: 3,
  MONTHLY_GAS_SETUP_TASK: 4,
  MONTHLY_RENEWABLE_SETUP_TASK: 5,
  MONTHLY_POWERPORTFOLIO_SETUP_TASK: 6,
  WEEKDAY_CHECKLIST_POWERPORTFOLIO: 7,
  MONTHLY_DEAL_ACTUALISATION: 8,
  MONTHLY_DEAL_ACTUALISATION_POWER: 9,
  MONTHLY_CONFIRMATIONS:10
};

export const monthlyChecklistTypeList = [
  CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK,
  CHECKLIST_TYPE_ID.MONTHLY_GAS_SETUP_TASK,
  CHECKLIST_TYPE_ID.MONTHLY_RENEWABLE_SETUP_TASK,
  CHECKLIST_TYPE_ID.MONTHLY_POWERPORTFOLIO_SETUP_TASK,
  CHECKLIST_TYPE_ID.MONTHLY_DEAL_ACTUALISATION,
  CHECKLIST_TYPE_ID.MONTHLY_DEAL_ACTUALISATION_POWER,
  CHECKLIST_TYPE_ID.MONTHLY_CONFIRMATIONS
];

export const CHECKLIST_TYPE_TITLE = {
  [CHECKLIST_TYPE_ID.WEEKEND_SETUP_TASK]: 'Ops Checklist - Weekend',
  [CHECKLIST_TYPE_ID.WEEKDAY_SETUP_TASK]: 'Ops Checklist - Weekday',
  [CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK]: 'Ops Checklist - Monthly',
  [CHECKLIST_TYPE_ID.MONTHLY_GAS_SETUP_TASK]: 'Ops Checklist - Monthly',
  [CHECKLIST_TYPE_ID.MONTHLY_RENEWABLE_SETUP_TASK]: 'Ops Checklist - Monthly',
  [CHECKLIST_TYPE_ID.MONTHLY_POWERPORTFOLIO_SETUP_TASK]:
    'Ops Checklist - Monthly',
  [CHECKLIST_TYPE_ID.WEEKDAY_CHECKLIST_POWERPORTFOLIO]:
    'Ops Checklist - Weekday',
  [CHECKLIST_TYPE_ID.MONTHLY_DEAL_ACTUALISATION]: 'Ops Checklist Gas - Monthly',
  [CHECKLIST_TYPE_ID.MONTHLY_DEAL_ACTUALISATION_POWER]:
    'Ops Checklist Power - Monthly',
  [CHECKLIST_TYPE_ID.MONTHLY_CONFIRMATIONS]:
    'Ops Checklist Confirmations - Monthly',
};

export const GetAllTaskGroupAPI = {
  [CHECKLIST_TYPE_ID.WEEKEND_SETUP_TASK]:
    '/api/TaskGroup/GetAllWeekendTaskGroup?checklistType=1',
  [CHECKLIST_TYPE_ID.WEEKDAY_SETUP_TASK]:
    '/api/TaskGroup/GetAllWeekdayTaskGroup',
  [CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=3',
  [CHECKLIST_TYPE_ID.MONTHLY_GAS_SETUP_TASK]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=4',
  [CHECKLIST_TYPE_ID.MONTHLY_RENEWABLE_SETUP_TASK]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=5',
  [CHECKLIST_TYPE_ID.MONTHLY_POWERPORTFOLIO_SETUP_TASK]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=6',
  [CHECKLIST_TYPE_ID.WEEKDAY_CHECKLIST_POWERPORTFOLIO]:
    '/api/TaskGroup/GetAllWeekdayTaskGroup?checklistType=7',
  [CHECKLIST_TYPE_ID.MONTHLY_DEAL_ACTUALISATION]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=8',
  [CHECKLIST_TYPE_ID.MONTHLY_DEAL_ACTUALISATION_POWER]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=9',
  [CHECKLIST_TYPE_ID.MONTHLY_CONFIRMATIONS]:
    '/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=10',
};
export const NewTaskGroup = '/api/TaskGroup/NewTaskGroup';
export const UpdateTaskGroup = '/api/TaskGroup/UpdateTaskGroup';
export const DeleteTaskGroup = '/api/TaskGroup/DeleteTaskGroup';
export const GetAllWeekendSetupTaskAPI = (checklistTypeId: number) =>
  `/api/cop/Task/GetAllWeekendSetupTask?checklistType=${checklistTypeId}`;
export const ChecklistGetAllWeekendSetupTaskAPI =
  '/api/cop/Checklist/GetAllWeekendSetupTask';

export const GetAllWeekdaySetupTaskAPI = (checklistTypeId: number) =>
  `/api/cop/Task/GetAllWeekdaySetupTask?checklistType=${checklistTypeId}`;

export const ChecklistGetAllWeekdaySetupTaskAPI =
  '/api/cop/Checklist/GetAllWeekdaySetupTask';
export const GetAllMonthlyTaskAPI = '/api/cop/Checklist/GetAllMonthlyTask';

export const UpdateTaskStatusAPI = '/api/cop/Checklist/UpdateTaskStatus';

export const GetWeekendSetupTaskDetail =
  '/api/cop/Checklist/GetWeekendSetupTaskDetail';

export const GetTaskWithTaskGroupKeyAPI = (taskGroupKey: string) =>
  `/api/cop/Task/GetTaskWithTaskGroupKey?taskGroupKey=${taskGroupKey}`;

export const UpdateTaskWithTaskGroupKeyAPI =
  '/api/cop/Task/UpdateTaskWithTaskGroupKey';

export const AddTaskAPI = '/api/cop/Task/AddTask';

export const GetAllMonthlySetupTaskAPI = (checklistTypeId: number) =>
  `/api/cop/Checklist/GetAllMonthlyTask?checklistTypeId=${checklistTypeId}`;

export const GetAllMonthlyTaskGroupAPI = (checklistTypeId: number) =>
  `/api/TaskGroup/GetAllMonthlyTaskGroup?checklistTypeId=${checklistTypeId}`;

export const GetDetailsForTaskAPI = 'api/cop/Checklist/GetDetailsForTask';

export const TaskGetAllMonthlyTaskAPI = (checklistTypeId: number) =>
  `/api/cop/Task/GetAllMonthlyTask?checklistTypeId=${checklistTypeId}`;

export const MonthlyGetDetailsForTaskAPI = 'api/checklist/GetDetailsForTask';

export const TASK_ACTION = {
  CHECKED: 9,
  UNCHECKED: 10,
  READY_FOR_REVIEW: 6,
};

export const TASK_STATUS = {
  REVERT_CANCEL: 0,
  OPEN: 1,
  CLOSE: 2,
  CANCEL: 3,
  CHECKED: 4,
  UNCHECKED: 5,
  READY_FOR_REVIEW: 6,
};
