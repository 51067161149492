export const AddOrUpdateCommercialActivity = `/api/cop/GtoLog/AddOrUpdateCommercialActivity`;
export const AddOrUpdateFuturePortfolio = `/api/cop/GtoLog/AddOrUpdateFuturePortfolio`;
export const AddOrUpdateFutureSteer = `/api/cop/GtoLog/AddOrUpdateFutureSteer`;
export const AddOrUpdateWithinDayPosition = `/api/cop/GtoLog/AddOrUpdateWithinDayPosition`;
export const AddOrUpdateWithinDayPortfolio = `/api/cop/GtoLog/AddOrUpdateWithinDayPortfolio`;
export const AddOrUpdateOperationalIncident = `/api/cop/GtoLog/AddOrUpdateOperationalIncident`;
export const AddOrUpdateCommentUsingSectionId = `/api/cop/GtoLog/AddOrUpdateCommentUsingSectionId`;
export const AddOrUpdatePositionLeftToCashOut = `/api/cop/GtoLog/AddOrUpdatePositionLeftToCashOut`;
export const GetAllAttachments = (gtoLogId: number) =>
  `/api/cop/GtoLog/GetAllAttachments?gtoLogId=${gtoLogId}`;
export const AddAttachments = `/api/cop/GtoLog/AddAttachments`;
export const PreviewAttachment = (id: number) =>
  `/api/cop/GtoLog/PreviewAttachment?id=${id}`;
export const SendMail = (formattedDate, shiftId) =>
  `/api/cop/GtoLog/SendEmailForGtoLog?date=${formattedDate}&shiftId=${shiftId}`;
export const GetAllCommercialActivityUsingLogId = (
  gtoLogId: number | undefined
) => `/api/cop/GtoLog/GetAllCommercialActivityUsingLogId?gtoLogId=${gtoLogId}`;
export const DeleteCA = `/api/cop/GtoLog/DeleteCommercialActivity`;
export const GetAllFuturePortfolioUsingLogId = (gtoLogId: number | undefined) =>
  `/api/cop/GtoLog/GetAllFuturePortfolioUsingLogId?gtoLogId=${gtoLogId}`;
export const DeleteFuturePortfolio = `/api/cop/GtoLog/DeleteFuturePortfolio`;
export const GetAllFutureSteerUsingLogId = (gtoLogId: number | undefined) =>
  `/api/cop/GtoLog/GetAllFutureSteerUsingLogId?gtoLogId=${gtoLogId}`;
export const DeleteFutureSteer = `/api/cop/GtoLog/DeleteFutureSteer`;
export const GetAllOperationalIncidentUsingLogId = (
  gtoLogId: number | undefined
) => `/api/cop/GtoLog/GetAllOperationalIncidentUsingLogId?gtoLogId=${gtoLogId}`;
export const DeleteOperationalIncident = `/api/cop/GtoLog/DeleteOperationalIncident`;
export const GetAllCommentUsingSectionId = (gtoLogId: number | undefined) =>
  `/api/cop/GtoLog/GetAllCommentUsingSectionId?logId=${gtoLogId}`;
export const DeleteCommentById = `/api/cop/GtoLog/DeleteCommentById`;
export const GetAllPositionsLeftToCashOut = (gtoLogId: number | undefined) =>
  `/api/cop/GtoLog/GetAllPositionsLeftToCashOut?gtoLogId=${gtoLogId}`;
export const DeletePositionLeftToCashOut = `/api/cop/GtoLog/DeletePositionLeftToCashOut`;
export const GetAllWithinDayPositionUsingLogId = (
  gtoLogId: number | undefined
) => `/api/cop/GtoLog/GetAllWithinDayPositionUsingLogId?gtoLogId=${gtoLogId}`;
export const DeleteWithinDayPosition = `/api/cop/GtoLog/DeleteWithinDayPosition`;
export const GetAllWithinDayPortfolioUsingLogId = (
  gtoLogId: number | undefined
) => `/api/cop/GtoLog/GetAllWithinDayPortfolioUsingLogId?gtoLogId=${gtoLogId}`;
export const DeleteWithinDayPortfolio = `/api/cop/GtoLog/DeleteWithinDayPortfolio`;
export const getAllLogs = (formattedDate, optionSelected) =>
  `/api/cop/GtoLog/GetLogUsingDateAndShift?date=${formattedDate}&shiftId=${optionSelected}&createLog=true`;
export const GetAllUnitsForVolume = `/api/cop/GtoLog/GetAllUnitsForVolume`;
export const GetAllCommercialValueType = `/api/cop/GtoLog/GetAllCommercialValueType`;
export const GetAllCurrency = `/api/cop/GtoLog/GetAllCurrency`;
export const GetAllMarkets = `/api/cop/GtoLog/GetAllMarkets`;
export const GetAllIncidentType = `/api/cop/GtoLog/GetAllIncidentType`;
export const GetAllUser = `/api/User/GetAllUser?team=1`;
export const GetAllGtoOperators = `api/cop/GtoLog/GetAllGtoOperators`;
export const AddGtoOperator = (userId) =>
  `api/cop/GtoLog/AddGtoOperator?userId=${userId}`;
export const RemoveGtoOperator = (logId, userId) =>
  `api/cop/GtoLog/RemoveGtoOperator?logId=${logId}&userId=${userId}`;
export const UpdateAttachmentStatus = `/api/cop/GtoLog/UpdateHaveAttachmentsStatus`;
