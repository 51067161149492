import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const UserAdminApplicationComp = React.lazy(
  () => import('../../components/UserAdministration/UserAdminApplication')
);

const UserAdminApplication = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.userAdministration}
    >
      <UserAdminApplicationComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default UserAdminApplication;
