import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavBar,
  Text,
  Icons,
  Dropdown as ShellDropdown,
  Button,
  Badge,
  Toggle,
} from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Notifications from '../../components/OtherComponents/Notifications';
import UserOptions from '../../components/OtherComponents/UserOptions';
import { FullStateI } from '../../types/All.type';
import { themeActions } from '../../store/Theme';
import {
  ThemeWrapper,
  Area1Container,
  MainMenuContainer,
  NotificationDrawer,
} from './style';
import { getDeadlineNotifications } from '../../store/UKOps/FieldNoms';

dayjs.extend(utc);
dayjs.extend(timezone);

type MainMenuType = {
  togelSideNav: () => void;
};

const NotificationMenuItem: React.FC = () => {
  const userState = useSelector((state: FullStateI) => state.User);
  const { count: notificationCount } = useSelector(
    (state: FullStateI) => state.FieldNoms.notifications
  );
  const { Bell: BellIcon } = Icons;
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);

  const getNotifications = () => {
    dispatch(
      getDeadlineNotifications(
        dayjs().tz('Europe/London').format('YYYY-MM-DDTHH:mm:ss')
      )
    );
  };

  useEffect(() => {
    const setNextCall = () => {
      const now = dayjs().tz('Europe/London');
      const nextHour = now.add(1, 'hour').startOf('hour');
      const timeUntilNextHour = nextHour.diff(now);

      // Fetch immediately if we are on the hour
      if (now.minute() === 0 && now.second() === 0) {
        getNotifications();
      }

      // Set timeout to call API at the start of the next hour
      setTimeout(() => {
        getNotifications();
        setInterval(getNotifications, 60 * 60 * 1000); // Set interval to call API every hour
      }, timeUntilNextHour);
    };

    if (userState.isAuthorized) {
      setNextCall();
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size='medium'
        variant='transparent'
        iconOnly
        icon={
          <>
            <BellIcon width={24} height={24} />
            {notificationCount > 0 && (
              <Badge color='negative' size='small'>
                {notificationCount}
              </Badge>
            )}
          </>
        }
        onClick={onOpen}
      />
      <NotificationDrawer
        open={open}
        header='Notifications'
        onClose={onClose}
        width={450}
        sticky
      >
        <Notifications />
      </NotificationDrawer>
    </>
  );
};

const UserDropdown: React.FC = () => {
  const userState = useSelector((state: FullStateI) => state.User);

  const { PersonCircle: PersonCircleIcon } = Icons;

  return (
    <ShellDropdown
      overlay={<UserOptions />}
      placement='bottomRight'
      trigger={['click']}
      arrow
    >
      <Button
        size='medium'
        variant='transparent'
        icon={<PersonCircleIcon width={24} height={24} />}
      >
        {userState.userName}
      </Button>
    </ShellDropdown>
  );
};

const MainMenu: React.FC<MainMenuType> = ({ togelSideNav }) => {
  const { Menu: MenuIcon } = Icons;
  const { isDark } = useSelector((state: FullStateI) => state.Theme);
  const dispatch = useDispatch();

  const handleThemeOnChange = (value: boolean) => {
    dispatch(
      themeActions.updateTheme({
        isDark: value,
      })
    );
  };

  return (
    <MainMenuContainer color={isDark ? 'rgba(30,36,43,1)' : '#fff'}>
      <NavBar
        pectenArea={
          <Area1Container color={isDark ? '#fff' : 'rgb(54, 61, 68)'}>
            <MenuIcon
              fill='red'
              width={30}
              height={30}
              className='pointer'
              onClick={togelSideNav}
            />
            <img
              alt='Shell Logo'
              height='36'
              src='https://www.shell.com/etc.clientlibs/settings/wcm/designs/shell-rio/clientlibs/themes/theme-shell/resources/logo/logo.svg'
              style={{ margin: '10px' }}
            />
            <Text>
              <strong>Commercial Operations Portal (COP)</strong>
            </Text>
          </Area1Container>
        }
        rightArea={
          <>
            <ThemeWrapper className={`${isDark ? 'icon-color' : ''}`}>
              <span>Light</span>
              <Toggle
                checked={isDark}
                label='Dark'
                labelPosition='right'
                onChange={handleThemeOnChange}
              />
            </ThemeWrapper>
            <NotificationMenuItem />
            <UserDropdown />
          </>
        }
        orientation='horizontal'
      >
        {process.env.REACT_APP_ENVIRONMENT !== 'Production' ? (
          <div className='environment-wrapper'>
            {`Attn: This is the ${process.env.REACT_APP_ENVIRONMENT} environment`}
          </div>
        ) : null}
      </NavBar>
    </MainMenuContainer>
  );
};

export default MainMenu;
