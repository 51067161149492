import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const PositionsCardComp = React.lazy(
  () => import('../../components/PositionHandover/PositionsCard')
);

const PositionsCard = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.positionHandoverTrader}
    >
      <PositionsCardComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default PositionsCard;
