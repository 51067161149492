/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import React from 'react';
import { Option, Select } from '@sede-x/shell-ds-react-framework';
import { ISelectProps } from '@sede-x/shell-ds-react-framework/build/esm/components/Select/Select.types';
import { Controller } from 'react-hook-form';
import ErrorMessage from '../../../../UI/ErrorMessage';
import { IMemoisedComponentProp } from '../../../../types/Common.type';

interface IFormControlSelect extends IMemoisedComponentProp {
  name: string;
  label: string;
  placeholder: string;
  required: boolean | string;
  isLoading: boolean;
  data: string[];
  selectProps?: ISelectProps;
  handleOnChange?: (zKey: string) => void;
}

const FormControlSelectUOM: React.FC<IFormControlSelect> = ({
  methods,
  name,
  required,
  label,
  placeholder,
  isLoading,
  data,
  selectProps,
  handleOnChange,
}) => {
  const {
    formState: { errors },
    control,
  } = methods;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value } }) => (
          <div>
            <span>{label}</span>
            <Select
              placeholder={placeholder}
              clearIcon={false}
              onChange={(zkey) => {
                if (handleOnChange) handleOnChange(zkey);
                onChange(zkey);
              }}
              value={value}
              invalid={!!errors[name]}
              loading={isLoading}
              {...selectProps}
            >
              {data.map((item) => (
                <Option key={`${item}_${item}`} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        )}
      />
      {errors[name] && errors[name]?.message && (
        <ErrorMessage>{errors[name]?.message}</ErrorMessage>
      )}
    </>
  );
};
export default FormControlSelectUOM;
