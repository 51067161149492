/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITheme {
  isDark: boolean;
}
const themeName = localStorage.getItem('theme_name');
const isDark = themeName === 'dark';
const initialState: ITheme = {
  isDark,
};

const Theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateTheme: (state: ITheme, { payload }: PayloadAction<ITheme>) => {
      localStorage.setItem('theme_name', payload.isDark ? 'dark' : 'light');
      state.isDark = payload.isDark;
    },
  },
});

export const themeActions = Theme.actions;
export default Theme.reducer;
