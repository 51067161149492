/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Divider,
  Heading,
  Pagination,
  Table,
  Icons,
  Select,
  Badge,
  Button,
  Option,
  FormField,
  Alert,
} from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import RecordsPerPage from '../components/OpsChecklist/AddTask/ShowTasks/TaskFilter/RecordsPerPage';
import { get as GetApi, post as PostAPI } from '../services/COPApi';
import { CardSection } from '../UI/Card';
import CommentModal from '../components/OpsChecklist/AddTask/Opportunities/CommentModal';
import { FullStateI } from '../types/All.type';
import { applicationName } from '../constants/Auth';
import Unauthorized from '../components/OtherComponents/Unauthorized';
import DateControl from '../components/OpsChecklist/Tasks/CalendarControl/DateControl';
import Loader from '../components/GTO/Loader';
import IsReadOnly from '../utils/getPermission';

const { ArrowLeft: ArrowLeftIcon, CommentSolid: CommentSolidIcon } = Icons;
export interface OpResponse {
  commercialOpportunityId: number;
  name: string;
  description: string;
  comments: string;
  hasComments: boolean;
  status: number;
  day1Status: string;
  day2Status: string;
  day3Status: string;
  day4Status: string;
  day5Status: string;
  day6Status: string;
  day7Status: string;
  modifiedByUserId: number;
  modifiedByUserName: string;
  modifiedDate: string;
  rowno: number;
  modifiedDateInGMT: string;
  day1ActiveStatus: boolean;
}

const FilterContainer = styled.div``;
const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const DateContainer = styled.div`
  display: flex;
  width: 100%;
`;

const StyledCardContainer = styled(Card)`
  width: 100%;
`;

const TrimmedData = styled.div`
  text-wrap: wrap;
  min-height: 22px;
  padding: 2%;
`;

export const ColumnContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 22px;

  &.column__max-width {
    max-width: 150px;
  }

  &.secondary-column__max-width {
    max-width: 200px;
  }
`;

export const RequestContainer = styled.div`
  .shell-table-cell {
    font-size: 13px !important;
    padding: 2px !important;
    height: unset !important;
    min-width: 30px;
    width: 7%;
    text-align: left;
    button {
      font-size: 12px;
      line-height: unset;
      min-height: 25px;
    }

    .info-icon svg {
      height: 20px;
      width: 20px;
      font-size: 13px;
    }
    shell-table-cell:nth-child(10) {
      width: 5%;
      text-align: center;
      background-color: red;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    right: 50px;
    button {
      margin-left: 5px;
      height: 30px;
      font-size: 12px;
      min-height: 30px;
    }
  }
  .input-style {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    input {
      width: 100%;
    }
  }

  .active-request__table {
    .shell-table-container {
      max-height: calc(100vh - 350px) !important;
      overflow: auto;
    }
  }

  .shell-table-thead {
    min-width: 8%;
    max-width: 8%;
    text-align: left;
    th.shell-table-cell:nth-child(1) {
      width: 20%;
    }
    th.shell-table-cell:nth-child(9) {
      width: 8%;
    }
    th.shell-table-cell:nth-child(10) {
      width: 5%;
      text-align: center;
    }
  }
`;

const statusLookup = {
  Unrealized: 2,
  Realized: 1,
};

const statusColor = {
  Unrealized: 'negative',
  Realized: 'information',
};

const StyledCommBtn = styled.div`
  margin-left: 14%;
  display: flex;
  justify-content: center;
`;

const StyledStatusDropdown = styled(Select)``;
let today = moment.tz('Europe/London');
const addedDate = today.clone().startOf('day');
const verifyDate = addedDate.add(7, 'hour').add(30, 'minute');
if (today < verifyDate) today = today.subtract(1, 'day');

const OpsOperatorView = () => {
  const navigate = useNavigate();
  const form = useForm();
  const { control } = form;
  const userState = useSelector((state: FullStateI) => state.User);
  const [operatorData, setOperatorData] = useState<OpResponse[]>([]);
  const [pageSize, setPageSize] = useState(25);
  const [pageNo, setPageNo] = useState(1);
  const [commentModal, setCommentModal] = useState({
    open: false,
    comments: '',
    id: -1,
    statusValue: 0,
    commDate: moment(),
    historical: false,
    name: '',
    description: '',
    modifiedByUserName: '',
    modifiedDateInGMT: '',
    commentDisable: false,
  });
  const [taskDate, setTaskDate] = useState<Moment>(today);

  const isReadOnly = IsReadOnly({ appName: applicationName.commercialOpsList });

  const [loader, setLoader] = useState<boolean>(false);
  const [operatorTable, setOperatorTable] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);

  const fetchOppsForOperator = useCallback(async () => {
    setLoader(true);
    setOperatorData([]);
    const formattedDate = taskDate.format('YYYY-MM-DD');
    try {
      const response = await GetApi(
        `/api/cop/CommercialOpportunity/GetComments?date=${formattedDate}`
      );
      if (response.status === 200) {
        setOperatorData(response.data);
        setLoader(false);
        setOperatorTable(true);
        setPageNo(1);
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } catch (err) {
      setLoader(false);
      setErrorMsg(true);
    }
  }, [taskDate]);

  const updateStaus = debounce((opId, statusSelected) => {
    const reqBody = {
      commercialOpportunityId: opId,
      status: statusSelected === '' ? 0 : statusSelected,
      comments: null,
    };
    setLoader(true);
    try {
      PostAPI(`/api/cop/CommercialOpportunity/AddComments`, reqBody).then(
        (response) => {
          if (response.status === 200) {
            fetchOppsForOperator();
            setLoader(false);
          }
        }
      );
    } catch (err) {
      setLoader(false);
    }
  }, 300);

  const handleBackBtn = () => {
    navigate(-1);
  };

  const onPageSIzeChange = (size) => {
    if (size) {
      setPageSize(size);
    } else {
      setPageSize(10);
    }
    setPageNo(1);
  };

  const formatTableData = () => {
    return (
      operatorData &&
      operatorData
        ?.slice((pageNo - 1) * pageSize, pageNo * pageSize)
        .map((data: OpResponse) => {
          return {
            ...data,
            name: <TrimmedData title={data.name}>{data.name}</TrimmedData>,
            comments: (
              <StyledCommBtn>
                <Button
                  key={data.commercialOpportunityId}
                  size='small'
                  iconOnly
                  icon={<CommentSolidIcon height={32} width={32} />}
                  style={{
                    backgroundColor: data?.hasComments ? 'red' : '#fbce07',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    setCommentModal({
                      open: true,
                      comments: data.comments,
                      id: data.commercialOpportunityId,
                      statusValue: statusLookup[data.day1Status] || 0,
                      commDate: taskDate,
                      historical:
                        taskDate.format('YYYY-MM-DD') !==
                        today.format('YYYY-MM-DD'),
                      name: data.name,
                      //
                      description: data.description,
                      modifiedByUserName: data.modifiedByUserName,
                      modifiedDateInGMT: data.modifiedDateInGMT,
                      commentDisable: data?.day1ActiveStatus,
                    });
                  }}
                >
                  Info
                </Button>
              </StyledCommBtn>
            ),

            status: (
              <Controller
                key={data.commercialOpportunityId}
                control={control}
                name='status'
                rules={{ required: 'Status is required' }}
                render={({ field: { value } }) => (
                  <StyledStatusDropdown
                    placeholder='Status'
                    showSearch={false}
                    value={value}
                    onChange={(val) => {
                      updateStaus(
                        data.commercialOpportunityId,
                        val ?? 0,
                        data.comments
                      );
                    }}
                    disabled={
                      isReadOnly ||
                      taskDate.format('YYYY-MM-DD') !==
                        today.format('YYYY-MM-DD') ||
                      !data?.day1ActiveStatus
                    }
                    defaultValue={data?.day1Status ?? ''}
                  >
                    <Option value='' aria-label='Select a status'>
                      <span>Select</span>
                    </Option>
                    <Option value='1' aria-label='Realized'>
                      Realized
                    </Option>
                    <Option value='2' aria-label='Unrealized'>
                      Unrealized
                    </Option>
                  </StyledStatusDropdown>
                )}
              />
            ),
            description: (
              <TrimmedData title={data.description}>
                {data.description}
              </TrimmedData>
            ),
            day1Status: data.day1Status !== '' && (
              <>
                <Badge
                  color={statusColor[data.day1Status] || ''}
                  size='medium'
                  style={{ width: '95px', marginLeft: '1%', marginRight: '2%' }}
                >
                  <span style={{ display: 'flex', textAlign: 'center' }}>
                    <span style={{ fontSize: '12px' }}>
                      {data.day1Status ?? ''}
                    </span>
                  </span>
                </Badge>
              </>
            ),
            day2Status: data.day2Status !== '' && (
              <Badge
                color={statusColor[data.day2Status] || 'neutral'}
                size='medium'
                style={{ width: '95px' }}
              >
                <span style={{ display: 'flex', textAlign: 'center' }}>
                  <span style={{ fontSize: '12px' }}>{data.day2Status}</span>
                </span>
              </Badge>
            ),
            day3Status: data.day3Status !== '' && (
              <Badge
                color={statusColor[data.day3Status] || 'neutral'}
                size='medium'
                style={{ width: '95px' }}
              >
                <span style={{ display: 'flex', textAlign: 'center' }}>
                  <span style={{ fontSize: '12px' }}>{data.day3Status}</span>
                </span>
              </Badge>
            ),
            day4Status: data.day4Status !== '' && (
              <Badge
                color={statusColor[data.day4Status] || 'neutral'}
                size='medium'
                style={{ width: '95px' }}
              >
                <span style={{ display: 'flex', textAlign: 'center' }}>
                  <span style={{ fontSize: '12px' }}>{data.day4Status}</span>
                </span>
              </Badge>
            ),
            day5Status: data.day5Status !== '' && (
              <Badge
                color={statusColor[data.day5Status] || 'neutral'}
                size='medium'
                style={{ width: '95px' }}
              >
                <span style={{ display: 'flex', textAlign: 'center' }}>
                  <span style={{ fontSize: '12px' }}>{data.day5Status}</span>
                </span>
              </Badge>
            ),
            day6Status: data.day6Status !== '' && (
              <Badge
                color={statusColor[data.day6Status] || 'neutral'}
                size='medium'
                style={{ width: '95px' }}
              >
                <span style={{ display: 'flex', textAlign: 'center' }}>
                  <span style={{ fontSize: '12px' }}>{data.day6Status}</span>
                </span>
              </Badge>
            ),
            day7Status: data.day7Status !== '' && (
              <Badge
                color={statusColor[data.day7Status] || 'neutral'}
                size='medium'
                style={{ width: '95px' }}
              >
                <span style={{ display: 'flex', textAlign: 'center' }}>
                  <span style={{ fontSize: '12px' }}>{data.day7Status}</span>
                </span>
              </Badge>
            ),
          };
        })
    );
  };

  // Step1
  useEffect(() => {
    // Based on date selection call the fetch api
    fetchOppsForOperator();
    setOperatorTable(true);

    return () => {
      setLoader(false);
      setErrorMsg(false);
      setOperatorData([]);
    };
  }, [taskDate, fetchOppsForOperator]);

  // Create an array to store the previous 6 dates
  const previousDates: string[] = [];
  // Subtract 1 day at a time and format in "DD-MMM-YY" format
  for (let i = 0; i <= 6; i += 1) {
    const dateSelected = taskDate;
    const previousDate = dateSelected.clone().subtract(i, 'days');
    previousDates.push(previousDate.format('DD-MMM-YY'));
  }
  return (
    <>
      {userState.userRole[applicationName.commercialOpsList] === 'NoAccess' ? (
        <Unauthorized
          message={`You don't have permission to access this page.`}
        />
      ) : (
        <RequestContainer>
          <CardSection>
            <Heading type='h3'>
              <ArrowLeftIcon
                width={40}
                height={20}
                onClick={() => handleBackBtn()}
              />
              GTO Opportunity List
            </Heading>
            <Divider />
            <StyledCardContainer size='medium'>
              <FilterContainer>
                <DateContainer>
                  <FormField label='Filter by Gas Day' size='small'>
                    <FlexContainer>
                      <DateControl
                        taskDate={taskDate}
                        setTaskDate={setTaskDate}
                        max={today.format('YYYY-MM-DD')}
                      />
                    </FlexContainer>
                  </FormField>
                  <div style={{ display: 'flex', width: '25%' }}>
                    <RecordsPerPage
                      pageSize={pageSize}
                      onPageSIzeChange={onPageSIzeChange}
                    />
                  </div>
                </DateContainer>
              </FilterContainer>
              {loader && <Loader />}

              {operatorTable && !errorMsg && (
                <>
                  <Table
                    columns={[
                      {
                        dataIndex: 'name',
                        key: 'ds_name',
                        title: 'Flow/Asset',
                      },
                      {
                        dataIndex: 'day7Status',
                        key: 'date_7',
                        title: previousDates[6],
                      },
                      {
                        dataIndex: 'day6Status',
                        key: 'date_6',
                        title: previousDates[5],
                      },
                      {
                        dataIndex: 'day5Status',
                        key: 'date_5',
                        title: previousDates[4],
                      },
                      {
                        dataIndex: 'day4Status',
                        key: 'date_4',
                        title: previousDates[3],
                      },
                      {
                        dataIndex: 'day3Status',
                        key: 'date_3',
                        title: previousDates[2],
                      },
                      {
                        dataIndex: 'day2Status',
                        key: 'date_2',
                        title: previousDates[1],
                      },
                      {
                        dataIndex: 'day1Status',
                        key: 'date_1',
                        title: previousDates[0],
                      },
                      {
                        dataIndex: 'status',
                        key: 'sd_status',
                        title: 'Status',
                      },
                      {
                        dataIndex: 'comments',
                        key: 'ds_comments',
                        title: 'More Info',
                      },
                    ]}
                    data={formatTableData()}
                    prefixCls='shell-table'
                    size='medium'
                  />
                  <Pagination
                    current={pageNo}
                    pageSize={pageSize}
                    total={operatorData.length ?? 0}
                    onChange={setPageNo}
                  />
                </>
              )}

              {errorMsg && (
                <Alert state='information'>
                  There is some problem in fetching the opportunities!
                </Alert>
              )}
            </StyledCardContainer>
          </CardSection>
          {commentModal.open && (
            <CommentModal
              commentModal={commentModal}
              onClose={() => {
                setCommentModal({
                  open: false,
                  comments: '',
                  id: -1,
                  statusValue: 0,
                  commDate: moment(),
                  historical: false,
                  name: '',
                  description: '',
                  modifiedByUserName: '',
                  modifiedDateInGMT: '',
                  commentDisable: false,
                });
              }}
              canListOpps={!isReadOnly}
              callBackfetchOppsForOperator={fetchOppsForOperator}
            />
          )}
        </RequestContainer>
      )}
    </>
  );
};

export default OpsOperatorView;
