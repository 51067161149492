import styled from 'styled-components';

const NotificationWrapper = styled.div`
  text-align: left;
  .notification_container {
    padding-top: 10px;
    .title {
      font-size: 15px;
      font-weight: 500;
    }
    .shell-divider {
      margin-top: 5px;
    }
  }
  .deadline-time {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    font-size: 13px;
    font-weight: 500;
  }
`;

export default NotificationWrapper;
