// ToastProvider.tsx

import React, { createContext, useState, useMemo, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import Toast from './Toast';

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastItem {
  id: string;
  content: string;
  type: 'success' | 'error' | 'info';
}

interface ToastContextProps {
  open: (content: string, type: 'success' | 'error' | 'info') => void;
}

export const ToastContext = createContext<ToastContextProps | undefined>(
  undefined
);

// Create a random ID
function generateUEID(): string {
  const first: number = Math.floor(Math.random() * 46656);
  const second: number = Math.floor(Math.random() * 46656);

  const firstString: string = first.toString(36).padStart(3, '0');
  const secondString: string = second.toString(36).padStart(3, '0');

  return firstString + secondString;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const open = (content: string, type: 'success' | 'error' | 'info') =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateUEID(), content, type },
    ]);

  const close = (id: string) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}

      {createPortal(
        <div
          style={{
            position: 'fixed',
            top: '16px',
            right: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            zIndex: 999999,
          }}
        >
          {toasts.map((toast, index) => (
            <Toast
              key={toast.id}
              content={toast.content}
              close={() => close(toast.id)}
              type={toast.type}
              index={index}
            />
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};
