import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { applicationName } from '../constants/Auth';
import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';

const SettingsCom = React.lazy(() => import('../components/SENA/Settings'));

const SenaContainer = styled.div`
  padding: 10px 25px;
`;

function SenaSettings(): ReactElement {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.senaPipelineAllocation}
    >
      <SenaContainer>
        <SettingsCom />
      </SenaContainer>
    </CheckAuthorizationWithSuspense>
  );
}

export default SenaSettings;
