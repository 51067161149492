import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const OclCloneTaskComp = React.lazy(
  () => import('../components/OpsChecklist/CloneTask')
);

const OclCloneTask: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.checkListAddTask}>
      <OclCloneTaskComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default OclCloneTask;
