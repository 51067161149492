import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const UserAdminRoleConfigComp = React.lazy(
  () => import('../../components/UserAdministration/UserAdminRoleConfig')
);

const UserAdminRoleConfig = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.userAdministration}
      // role={userRole.readWrite}
    >
      <UserAdminRoleConfigComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default UserAdminRoleConfig;
