import React from 'react';

import CheckAuthorization from './CheckAuthorization';
import SuspenseLoadPage from './SuspenseLoadPage';

interface ICheckAuthorizationWithSuspenseProps {
  pageName: string;
  errorMsg?: string;
  role?: string;
}

const CheckAuthorizationWithSuspense: React.FC<
  ICheckAuthorizationWithSuspenseProps
> = ({ pageName, role, errorMsg, children }) => {
  return (
    <SuspenseLoadPage>
      <CheckAuthorization pageName={pageName} role={role} errorMsg={errorMsg}>
        {children}
      </CheckAuthorization>
    </SuspenseLoadPage>
  );
};
CheckAuthorizationWithSuspense.defaultProps = {
  errorMsg: 'Sorry you are not authorized to access this page',
  role: '*',
};

export default CheckAuthorizationWithSuspense;
