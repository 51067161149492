import styled from 'styled-components';

interface ColType {
  size: number;
}

interface RowProps {
  heightPercentage?: string; // Height is an optional string property
}

const getHeightStyles = (props: RowProps) => {
  if (props.heightPercentage) {
    return `height: ${props.heightPercentage}%`;
  }
  return '';
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${(props) => getHeightStyles(props)}
`;

export const FormRow = styled(Row)`
  align-items: center;
  margin-bottom: 1rem;
`;
export const Col = styled.div<ColType>`
  flex: 0 0 ${(props) => (props.size * 100) / 12}%;
  max-width: ${(props) => (props.size * 100) / 12}%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
`;
