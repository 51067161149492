import React from 'react';
import styled from 'styled-components';
import { formattDate } from '../../../../utils/general';

const NoteSectionContainer = styled.div`
  margin: 2px auto;
  height: 320px;
  overflow: auto;
`;
const NoteItem = styled.div`
  padding: 2px 8px 2px 8px;
  transition: 0.3s;
  position: relative;
`;

const NoteItemContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 10px;
`;

const NoteItemOtherInfo = styled.div`
  font-size: 10px;
  font-style: italic;
  margin-left: 15px;
`;

const CommentData = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-left: 15px;
`;

interface Props {
  commentsList: any;
}

const OpsCommentsList = (props: Props) => {
  const { commentsList } = props;

  return (
    <>
      <NoteSectionContainer>
        {commentsList.map((note) => (
          <>
            {note.comments !== null && (
              <NoteItem key={note.id}>
                <NoteItemContainer>
                  <CommentData>{note.comments}</CommentData>
                  {!!note.comments && (
                    <NoteItemOtherInfo>
                      Added By{' '}
                      {` ${note.modifiedByUserName} at ${formattDate(
                        note.modifiedDateTimeInGMT,
                        'lll'
                      )}`}
                    </NoteItemOtherInfo>
                  )}
                </NoteItemContainer>
              </NoteItem>
            )}
          </>
        ))}
      </NoteSectionContainer>
    </>
  );
};

export default OpsCommentsList;
