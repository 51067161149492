/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MiscellaneousStateI,
  UpdateClRefreshCountI,
} from '../../types/MiscellaneousStore.type';

const initialState: MiscellaneousStateI = {
  clRefreshCount: 0,
};

const miscellaneousDataSlice = createSlice({
  name: 'miscellaneousCount',
  initialState,
  reducers: {
    updateClRefreshCount: (
      state: MiscellaneousStateI,
      { payload }: PayloadAction<UpdateClRefreshCountI>
    ) => {
      const inc = payload.count || 1;
      state.clRefreshCount += inc;
    },
  },
});

export const miscellaneousDataActions = miscellaneousDataSlice.actions;
export default miscellaneousDataSlice.reducer;
