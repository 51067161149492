import React from 'react';
import { useSelector } from 'react-redux';
import Unauthorized from '../Unauthorized';
import { FullStateI } from '../../../types/All.type';
import { userRole } from '../../../constants/Auth';

interface CheckAuthorizationPropsI {
  pageName: string;
  errorMsg?: string;
  role?: string;
}

const CheckAuthorization: React.FC<CheckAuthorizationPropsI> = ({
  pageName,
  errorMsg,
  role,
  children,
}) => {
  const userState = useSelector((state: FullStateI) => state.User);

  if (
    (pageName in userState.userRole &&
      userState.userRole[pageName] === userRole.noAccess) ||
    userState.userRole[pageName] === undefined
  ) {
    return <Unauthorized message={errorMsg} />;
  }

  return (
    <>
      {pageName in userState.userRole &&
        (role === '*' || userState.userRole[pageName] === role) && (
          <>{children}</>
        )}
    </>
  );
};
CheckAuthorization.defaultProps = {
  errorMsg: 'Sorry you are not authorized to access this page',
  role: '*',
};

export default CheckAuthorization;
