/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ShiftNameList, ShiftNameStateI } from '../../types/OpsChecklist.type';
import { get as GetApi } from '../../services/COPApi';

const initialState: ShiftNameStateI = {
  isFetching: false,
  error: null,
  list: [],
};

export const fetchShiftName = createAsyncThunk(
  'shiftName/fetchShiftName',
  async () => {
    return GetApi(`/api/cop/Shift/GetShifts`).then((resp) => {
      return resp.data;
    });
  }
);

const shiftNameSlice = createSlice({
  name: 'shiftName',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftName.pending, (state: ShiftNameStateI) => {
        state.isFetching = true;
        state.error = null;
        state.list = [];
      })
      .addCase(
        fetchShiftName.fulfilled,
        (state: ShiftNameStateI, action: PayloadAction<ShiftNameList>) => {
          state.isFetching = false;
          state.error = null;
          state.list = action.payload;
        }
      )
      .addCase(fetchShiftName.rejected, (state: ShiftNameStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.list = [];
      });
  },
});

export const CLShiftNameActions = shiftNameSlice.actions;
export default shiftNameSlice.reducer;
