import React from 'react';
import UnauthorizedComp from '../components/OtherComponents/Unauthorized';
import SuspenseLoadPage from '../components/OtherComponents/SuspenseLoadPage';

const ForbiddenAccess = () => {
  return (
    <SuspenseLoadPage>
      <UnauthorizedComp
        message={`You don't have permission to access this page.`}
      />
    </SuspenseLoadPage>
  );
};

export default ForbiddenAccess;
