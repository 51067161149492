import React from 'react';
import styled from 'styled-components';
import { Loading } from '@sede-x/shell-ds-react-framework';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Loader = () => {
  return (
    <LoaderContainer>
      <Loading size='large' />
    </LoaderContainer>
  );
};

export default Loader;
