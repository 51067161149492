import React from 'react';
import styled from 'styled-components';

interface InfoPropsI {
  label: string;
  value: string;
  isOptional?: boolean;
}
const InfoRow = styled.div`
  margin: 7px 0;
`;

const Info: React.FC<InfoPropsI> = ({ label, value, isOptional = false }) => {
  if (value || isOptional) {
    return (
      <InfoRow>
        <b>{label}: </b> {value}
      </InfoRow>
    );
  }
  return null;
};
Info.defaultProps = {
  isOptional: false,
};

export default Info;
