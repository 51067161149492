import React, { useState } from 'react';
import { Modal } from '../../../../UI/Modal';
import { ITerminalMapping } from '../../../../types/UKNomination/FieldNomination.type';
import { ModalFooter } from '../style';
import Button from '../../../../UI/Button';
import { deleteApi } from '../../../../services/COPApi';
import {
  DELETE_TERMINAL_MAPPINGS,
  MESSAGES,
} from '../../../../constants/UKNomination/FieldNomination';
import { useToast } from '../../../../UI/Toast';

interface IDeleteMapping {
  open: boolean;
  onClose: () => void;
  selectedItem: ITerminalMapping | null;
  refreshData: () => void;
}

const ConfirmDeleteMapping: React.FC<IDeleteMapping> = ({
  selectedItem,
  open,
  onClose = () => {},
  refreshData = () => {},
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteMapping = () => {
    setLoading(true);
    deleteApi(`${DELETE_TERMINAL_MAPPINGS}?id=${selectedItem?.id}`, {})
      .then(() => {
        toast?.open(`Mapping Deleted Successfully!`, 'success');
        onClose();
        refreshData();
      })
      .catch(() => {
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderFooter = () => (
    <ModalFooter>
      <Button onClick={deleteMapping} loading={loading} disabled={loading}>
        Yes
      </Button>
      <Button variant='outlined' onClick={onClose}>
        Cancel
      </Button>
    </ModalFooter>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Are you sure you want to delete this mapping?'
      isDraggable
      maskClosable={false}
      footer={renderFooter()}
    >
      {selectedItem && (
        <>
          <div>
            <b>zKey:</b> {selectedItem?.zKey}
          </div>
          <div>
            <b>Production Field:</b> {selectedItem?.productionField}
          </div>
          <div>
            <b>Terminal:</b> {selectedItem?.terminal}
          </div>
          <div>
            <b>Sub Terminal:</b> {selectedItem?.subTerminal}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ConfirmDeleteMapping;
