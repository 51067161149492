import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const EditCounterparty = React.lazy(
  () => import('../../components/ContactList/Counterparty/EditCounterparty')
);

const ContactListEdit = () => {
  const { market = '' } = useParams();

  let content: ReactElement | null = null;

  if (market === 'counterparty') {
    content = <EditCounterparty />;
  }

  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.contactList}
      // role={userRole.readWrite}
    >
      {content}
    </CheckAuthorizationWithSuspense>
  );
};

export default ContactListEdit;
