import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const IBTGasComp = React.lazy(() => import('../../components/IBT/Gas'));

type BSMainProps = 'gas' | 'power';

interface BeachSwapMainCompProps {
  type: BSMainProps;
}

const IBTMainComponent: React.FC<BeachSwapMainCompProps> = ({ type }) => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.ibt}>
      {type === 'gas' && <IBTGasComp />}
    </CheckAuthorizationWithSuspense>
  );
};

export default IBTMainComponent;
