/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icons } from '@sede-x/shell-ds-react-framework';
import { ColumnTitleContainer } from '../style';
import { FullStateI } from '../../../types/All.type';

const { ChevronUpDown, ArrowDownSquare, ArrowUpSquare } = Icons;

const ColumnTitle = (props: any) => {
  const { column, sortedColumn, handleSort = () => {} } = props;

  const [sort, setSort] = useState<string | null>(null);
  const { isDark } = useSelector((state: FullStateI) => state.Theme);

  const getOrder = (currentOrder) => {
    switch (currentOrder) {
      case 'asc':
        return 'desc';
      case 'desc':
        return null;
      default:
        return 'asc';
    }
  };

  const handleSortOnClick = () => {
    const order = getOrder(sort);
    setSort(order);
    handleSort(column.key, order, column?.nestedObject);
  };

  useEffect(() => {
    const order =
      column.key === sortedColumn?.field ? sortedColumn?.order : null;
    setSort(order);
  }, [sortedColumn]);

  return (
    <ColumnTitleContainer color={isDark ? '#fff' : 'rgba(30,36,43,1)'}>
      <div
        className={`sorting-wrapper ${column?.sorting ? 'pointer' : ''}`}
        data-testid='sorting'
        onClick={column?.sorting ? handleSortOnClick : () => {}}
      >
        <div className='shell-table-column-title'>{column?.title}</div>
        {column?.sorting && (
          <div className='shell-table-column-sorter'>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!sort ? (
              <ChevronUpDown width={15} height={15} />
            ) : sort === 'asc' ? (
              <ArrowUpSquare width={15} height={15} />
            ) : (
              <ArrowDownSquare width={15} height={15} />
            )}
          </div>
        )}
      </div>
    </ColumnTitleContainer>
  );
};

export default ColumnTitle;
