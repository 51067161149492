import React, { ReactElement } from 'react';
import styled from 'styled-components';
import SuspenseLoadPage from '../components/OtherComponents/SuspenseLoadPage';

const DashboardComp = React.lazy(() => import('../components/Dashboard'));

const DashboardContainer = styled.div`
  box-sizing: border-box;
  padding: 10px 25px;
  clear: both;
`;

function Dashboard(): ReactElement {
  return (
    <SuspenseLoadPage>
      <DashboardContainer>
        <DashboardComp />
      </DashboardContainer>
    </SuspenseLoadPage>
  );
}

export default Dashboard;
