import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const FieldNominationComp = React.lazy(
  () => import('../../components/UKOps/FieldNomination')
);

const FieldNomination: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.genesis}>
      <FieldNominationComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default FieldNomination;
