// import { Market } from './../../components/PowerShift/interfaces';
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Moment } from 'moment';
import { get as GetApi, put as PutApi } from '../../services/COPApi';
import { PowerShiftData, PtoLog } from '../../components/PowerShift/interfaces';
import {
  GetAllUser,
  SearchOrAddNewPtoLog,
  UpdateOperatorDetail,
} from '../../pages/powershift/common/constants';

export type IptoLogsData = PtoLog[][];

export type OperatorOptionsType = { id: number; userName: string };

interface PtoLogsState {
  loading: boolean;
  loadingOperators: boolean;
  loadingOperatorsState: boolean;
  tableStateData: PowerShiftData | null;
  ptoLogsData: IptoLogsData; // Replace with your actual data type
  referenceArr: IptoLogsData;
  error: string | null;
  updateOperatorError: string | null;
  markets: [];
  blocks: [];
  operatorOptions: OperatorOptionsType[];
}

interface OperatorPayload {
  logId: number;
  dayShiftTraderId?: number | null;
  tShiftTraderId?: number | null;
  powerAnalystUserId?: number | null;
  nightShiftUserId?: number | null;
  utcTime: Moment;
}

// Define the initial state
const initialState: PtoLogsState = {
  loading: true,
  loadingOperators: true,
  loadingOperatorsState: true,
  tableStateData: null,
  ptoLogsData: [],
  referenceArr: [],
  markets: [],
  blocks: [],
  error: null,
  operatorOptions: [],
  updateOperatorError: null,
};

// const transformData = (ptoLogs: PtoLog[]): IptoLogsData => {
//   const result: IptoLogsData = [];
//   let currentRow: PtoLog[] = [];
//   ptoLogs.forEach((ptoLog) => {
//     const { blockId, marketId, ...rest } = ptoLog;
//     currentRow.push({ blockId, marketId, ...rest });

//     if (blockId === 6) {
//       result.push([...currentRow]);
//       currentRow = []; // Reset current row for the next set of blocks
//     }
//   });
//   return result;
// };

const transformData = (ptoLogs: PtoLog[]): IptoLogsData => {
  const groupedByMarketId = _.groupBy(ptoLogs, 'marketId');

  const result: IptoLogsData = _.chain(groupedByMarketId)
    .flatMap((logs) => _.chunk(logs, _.findIndex(logs, { blockId: 6 }) + 1))
    .value();

  return result;
};

function makeDataUnique(inputData) {
  const uniqueSet = new Set();
  return inputData.filter((item) => {
    if (!uniqueSet.has(item.id)) {
      uniqueSet.add(item.id);
      return true;
    }
    return false;
  });
}

export const fetchPtoLogsData = createAsyncThunk<
  {
    blocks: any;
    markets: any;
    tableStateData: PowerShiftData | null;
    ptoLogsData: IptoLogsData;
  },
  Moment,
  { rejectValue: string }
>('ptoLogs/fetchPtoLogsData', async (dateSelected, { rejectWithValue }) => {
  try {
    const tableResponse = await GetApi(
      SearchOrAddNewPtoLog(dateSelected.format('YYYY-MM-DD'))
    );

    if (tableResponse?.status === 200 && tableResponse?.data) {
      const transformedData = transformData(tableResponse?.data.ptoLogs);
      const filteredMarkets = makeDataUnique(tableResponse?.data?.markets);
      const filteredBlocks = makeDataUnique(tableResponse?.data?.blocks);
      return {
        tableStateData: tableResponse?.data,
        ptoLogsData: transformedData,
        markets: filteredMarkets,
        blocks: filteredBlocks,
      };
    }
    return rejectWithValue('Invalid API response');
  } catch (err) {
    if (err instanceof Error) {
      // err is now of type 'Error'
      return rejectWithValue(err.message || 'An error occurred');
    }
    // Handle other cases where err is not an instance of Error
    return rejectWithValue('An unknown error occurred');
  }
});

export const fetchAllOperators = createAsyncThunk(
  'ptoLogs/fetchAllOperators',
  async () =>
    GetApi(GetAllUser).then((resp) => {
      return resp.data;
    })
);

export const updateOperatorAction = createAsyncThunk(
  'ptoLogs/updateOperatorDetail',
  async (data: OperatorPayload) =>
    PutApi(UpdateOperatorDetail(), data).then((resp) => {
      return resp.data;
    })
);

const ptoLogsSlice = createSlice({
  name: 'ptoLogs',
  initialState,
  reducers: {
    setNewDateLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setTableStateData: (
      state,
      action: PayloadAction<PowerShiftData | null>
    ) => {
      state.tableStateData = action.payload;
    },
    setPtoLogsData: (state, action: PayloadAction<IptoLogsData>) => {
      state.ptoLogsData = action.payload;
    },
    updateRefrenceArr: (state, action: PayloadAction<IptoLogsData>) => {
      state.referenceArr = JSON.parse(JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPtoLogsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPtoLogsData.fulfilled, (state, action) => {
        state.tableStateData = action.payload.tableStateData;
        state.ptoLogsData = action.payload.ptoLogsData;
        state.markets = action.payload.markets;
        state.blocks = action.payload.blocks;
        state.referenceArr = JSON.parse(
          JSON.stringify(action.payload.ptoLogsData)
        );
        state.loading = false;
      })
      .addCase(fetchPtoLogsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      });

    builder
      .addCase(fetchAllOperators.pending, (state: PtoLogsState) => {
        state.loadingOperators = true;
        state.error = null;
      })
      .addCase(fetchAllOperators.fulfilled, (state: PtoLogsState, action) => {
        state.operatorOptions = action.payload;
        state.loadingOperators = false;
      })
      .addCase(fetchAllOperators.rejected, (state: PtoLogsState) => {
        state.loadingOperators = false;
        state.operatorOptions = [];
        state.error = 'Error';
      });

    builder
      .addCase(updateOperatorAction.pending, (state) => {
        state.loadingOperatorsState = true;
        state.updateOperatorError = null;
      })
      .addCase(updateOperatorAction.fulfilled, (state) => {
        state.loadingOperatorsState = false;
        // Optionally update state with the response data if needed
      })
      .addCase(updateOperatorAction.rejected, (state, action) => {
        state.loadingOperatorsState = false;
        state.updateOperatorError =
          action.error.message || 'Error while updating Data!';
      });
  },
});

export const PTOLogsActions = ptoLogsSlice.actions;
export default ptoLogsSlice.reducer;
