import React from 'react';
import { useParams } from 'react-router-dom';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';
import { CHECKLIST_TYPE_ID } from '../../constants/WstChecklist';

const WstTasksComp = React.lazy(
  () => import('../../components/OpsChecklist/WstChecklist/WstTasks')
);

interface IWstTasksProps {
  checklistTypeId: number;
}

const WstTasks: React.FC<IWstTasksProps> = ({ checklistTypeId }) => {
  const { teamId = '' } = useParams();
  let updatedChecklistTypeId = checklistTypeId;
  let pageName = applicationName.checklistShiftWholesalePower;

  if (teamId === '1') {
    updatedChecklistTypeId = 4;
    pageName = applicationName.checkListShift;
  } else if (teamId === '2') {
    pageName = applicationName.checklistShiftWholesaleGas;
  } else if (teamId === '3') {
    pageName = applicationName.checklistShiftWholesalePower;
  } else if (teamId === '5') {
    pageName = applicationName.checklistRenewableShift;
    updatedChecklistTypeId = 5;
  } else if (teamId === '6') {
    pageName = applicationName.checklistPowerPortfolio;
    if (checklistTypeId === CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK) {
      updatedChecklistTypeId = 6;
    }
  } else if (teamId === '7') {
    pageName = applicationName.checklistDealAcualisation;
    updatedChecklistTypeId = 8;
  } else if (teamId === '8') {
    pageName = applicationName.checklistDealAcualisation;
    updatedChecklistTypeId = 9;
  }else if (teamId === '10') {
    pageName = applicationName.checklistConfirmations;
    updatedChecklistTypeId = 10;
  }

  return (
    <CheckAuthorizationWithSuspense pageName={pageName}>
      <WstTasksComp
        checklistTypeId={updatedChecklistTypeId}
        pageName={pageName}
      />
    </CheckAuthorizationWithSuspense>
  );
};

export default WstTasks;
