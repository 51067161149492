import moment from 'moment';
import styled, { keyframes } from 'styled-components';

export const DAY_ICON = '#FFD700';
export const NIGHT_ICON = '#606bb4';

export const DAY = '#f0dd70';
export const NIGHT = '#606bb4';

export const TIME_ZONE = 'Europe/London';

// export const TIME_ZONE = 'Australia/Adelaide';
// export const TIME_ZONE = 'Europe/London';
// export const TIME_ZONE = 'america/juneau';
// export const TIME_ZONE = 'Asia/Kuwait';
// export const TIME_ZONE = 'Asia/Hong_Kong';
// export const TIME_ZONE = 'America/New_York';
// export const TIME_ZONE = 'Asia/Calcutta';
// export const TIME_ZONE = 'Asia/Tokyo';

interface AnimatedIconProps {
  isVisible: boolean;
}

export const Header = styled.div`
  display: flex;
  justify-content: start;
  background-color: #eee;
  padding: 8px;
  font-weight: bold;
  gap: 50px;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const DateRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 0 0;
  gap: 30px;
`;

export const DateColumn = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const AddButton = styled.div`
  padding: 8px;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 40%;

  &:hover {
    background-color: #fbcf0a61;
  }
`;

export const RemoveIcon = styled.span`
  cursor: pointer;
  display: flex;
  path {
    fill: #d88e8e;
  }
`;

export const ErrorDiv = styled.div`
  color: orange;
`;

export const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const AnimatedSunIcon = styled.span<AnimatedIconProps>`
  display: flex;
  margin-left: 10px;
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.7s
    ease-in-out;
  path {
    fill: ${DAY_ICON};
  }
`;

export const AnimatedMoonIcon = styled.span<AnimatedIconProps>`
  display: flex;
  margin-left: 10px;
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.7s
    ease-in-out;
  path {
    fill: ${NIGHT_ICON};
  }
`;

export const ToggleContainer = styled.div`
  .shell-toggle span {
    width: 30px;
  }
`;

const MINUTE_FIXED = 45;

export function getCurrentShift() {
  const currentHour = moment.tz(TIME_ZONE).hour();
  const currentMinute = moment.tz(TIME_ZONE).minutes();
  let currentShift;
  if (currentHour > 7 && currentHour < 19) {
    currentShift = 1;
  } else if (currentHour === 7 || currentHour === 19) {
    if (currentHour === 7) {
      currentShift = currentMinute >= MINUTE_FIXED ? 1 : 2;
    }
    if (currentHour === 19) {
      currentShift = currentMinute >= MINUTE_FIXED ? 2 : 1;
    }
  } else {
    currentShift = 2;
  }
  return currentShift;
}

export function getTodayDate() {
  let today = moment.tz(TIME_ZONE);
  const addedDate = today.clone().startOf('day');
  const verifyDate = addedDate.add(7, 'hour').add(MINUTE_FIXED, 'minute');
  const obj = { isPrevious: false, date: '' };
  if (today < verifyDate) {
    today = today.subtract(1, 'day');
    return { ...obj, isPrevious: true, date: today.format('YYYY-MM-DD') };
  }
  return { ...obj, isPrevious: false, date: today.format('YYYY-MM-DD') };
}

export const calculateDayCount = (from, to) => {
  if (!from || !to) return 0;
  const start = new Date(from);
  const end = new Date(to);
  const differenceInTime = end.getTime() - start.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};

export const validateDate = (from, to) => {
  if (from && to) {
    return new Date(to) >= new Date(from);
  }
  return true;
};

export const isDateAlreadySelected = (
  enteredStartDate,
  enteredEndDate,
  id,
  dates,
  shift
) => {
  if (dates.length <= 1) return false;

  return dates.some((entry) => {
    if (id !== entry.id) {
      const entryStartDate = new Date(entry.startDate);
      const entryEndDate = new Date(entry.endDate);
      const startDate = new Date(enteredStartDate);
      const endDate = new Date(enteredEndDate);

      if (
        startDate.toDateString() === endDate.toDateString() &&
        shift !== entry.shift
      ) {
        return false;
      }

      if (
        entry.shift !== shift &&
        (endDate.toDateString() === entryStartDate.toDateString() ||
          endDate.toDateString() === entryEndDate.toDateString() ||
          startDate.toDateString() === entryStartDate.toDateString() ||
          startDate.toDateString() === entryEndDate.toDateString())
      ) {
        return false;
      }

      if (
        entry.shift === shift &&
        (endDate.toDateString() === entryStartDate.toDateString() ||
          endDate.toDateString() === entryEndDate.toDateString() ||
          startDate.toDateString() === entryStartDate.toDateString() ||
          startDate.toDateString() === entryEndDate.toDateString())
      ) {
        return true;
      }

      // If the selected date range overlaps with an existing entry's date range, show error.
      if (
        (startDate >= entryStartDate && startDate <= entryEndDate) ||
        (endDate >= entryStartDate && endDate <= entryEndDate) ||
        (startDate <= entryStartDate && endDate >= entryEndDate)
      ) {
        return true;
      }
    }

    return false;
  });
};
