import React from 'react';
import routepaths from './routepaths';

import CnCalcConvert from '../pages/cncalc/CnCalcConvert';
import CnCalcFormulaBuilder from '../pages/cncalc/CnCalcFormulaBuilder';
import CnCalcEditFormula from '../pages/cncalc/CnCalcEditFormula';
import WeightedAverageCalculator from '../pages/cncalc/WeightedAverage';
import IAppRoute from './AppRoute.type';

const ConversionCalculatorRoutes: IAppRoute[] = [
  { path: routepaths.cnCalcConvert, element: <CnCalcConvert /> },
  {
    path: routepaths.cnCalcFormulaBuilder,
    element: <CnCalcFormulaBuilder />,
  },
  {
    path: routepaths.cnCalcEditFormula,
    element: <CnCalcEditFormula />,
  },
  {
    path: routepaths.weightedAverageCalculator,
    element: <WeightedAverageCalculator />,
  },
];

export default ConversionCalculatorRoutes;
