import styled from 'styled-components';

export const TableContainer = styled.div`
  position: relative;
  .shell-table-thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .pagination-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
  }
  .shell-table-container {
    max-height: calc(100vh - 410px);
    overflow: auto;
  }
  .select-page-size {
    width: 100px;
    margin-left: 10px;
  }
  .total-rows {
    margin-right: 10px;
  }
`;

export const ColumnTitleContainer = styled.div`
  display: flex;
  .sorting-wrapper {
    display: flex;
    .shell-table-column-sorter {
      margin: unset;
      svg:first-child path {
        fill: ${(prop) => prop.color};
      }
    }
    .shell-table-column-title {
      display: flex;
      align-items: center;
    }
  }
  .pointer {
    cursor: pointer;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.05);
  z-index: 1000;

  .loader-wrapper {
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
  }
`;
