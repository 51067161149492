import React from 'react';
import routepaths from './routepaths';

import ContactListViewComp from '../pages/contactlist/ContactListView';
import ContactListAddComp from '../pages/contactlist/ContactListAdd';
import ContactListEditComp from '../pages/contactlist/ContactListEdit';

import IAppRoute from './AppRoute.type';

const ContactListRoutes: IAppRoute[] = [
  {
    path: routepaths.contactListView,
    element: <ContactListViewComp />,
  },
  {
    path: routepaths.contactListAdd,
    element: <ContactListAddComp />,
  },
  {
    path: routepaths.contactListEdit,
    element: <ContactListEditComp />,
  },
];

export default ContactListRoutes;
