/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../../services/COPApi';
import {
  GET_IBT_BOOKS,
  GET_IBT_COMMON_INFO,
  GET_IBT_MARKETS,
  GET_IBT_TRADERS,
  GET_MARKET1_DETAILS,
} from '../../constants/IBT';
import { IBTSelectProps, MarketInfoProps } from '../../types/IBT/IBT.type';

interface ISelectField {
  loading: boolean;
  data: IBTSelectProps[];
}

interface IMarketFields {
  loading: boolean;
  data: MarketInfoProps[];
}

const selectInitialData = {
  loading: false,
  data: [],
};

export interface IBTState {
  commonDetails: ISelectField;
  marketDetails: IMarketFields;
}

const initialState: IBTState = {
  commonDetails: selectInitialData,
  marketDetails: selectInitialData,
};

export const getMergedInfo = createAsyncThunk(
  'ibt/getMergedInfo',
  async (_, { rejectWithValue }) => {
    try {
      const [
        ibtCommonInfo,
        transformedMarket1Info,
        transformedIbtBooksInfo,
        transformedIbtTradersInfo,
        defaultTraderInfo,
      ] = await Promise.all([
        get(GET_IBT_COMMON_INFO).then((resp) => resp.data),
        get(`${GET_MARKET1_DETAILS}?opsTeamId=1`).then((resp) =>
          resp.data.map((item: MarketInfoProps) => ({
            id: item.id,
            enumValue: item.marketName,
            enumType: 'Market1',
          }))
        ),
        get(`${GET_IBT_BOOKS}?id=0`).then((resp) =>
          resp.data.map((item: MarketInfoProps) => ({
            id: item.id,
            enumValue: item.bookName,
            enumType: 'Books',
          }))
        ),
        get(`${GET_IBT_TRADERS}?id=1`).then((resp) =>
          resp.data.map((item: MarketInfoProps) => ({
            id: item.id,
            enumValue: item.traderName,
            enumType: 'Traders',
          }))
        ),
        get(`${GET_IBT_TRADERS}?id=0`).then((resp) =>
          resp.data.map((item: MarketInfoProps) => ({
            id: item.id,
            enumValue: item.traderName,
            enumType: 'defaultTrader',
          }))
        ),
      ]);

      return [
        ...ibtCommonInfo,
        ...transformedMarket1Info,
        ...transformedIbtBooksInfo,
        ...transformedIbtTradersInfo,
        ...defaultTraderInfo,
      ];
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }

      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const getMarketsInfo = createAsyncThunk('ibt/getMarketsInfo', async () =>
  get(GET_IBT_MARKETS).then((resp) => {
    return resp.data;
  })
);

const ibtSlice = createSlice({
  name: 'ibt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMergedInfo.pending, (state: IBTState) => {
        state.commonDetails.loading = true;
        state.commonDetails.data = [];
      })
      .addCase(
        getMergedInfo.fulfilled,
        (state: IBTState, { payload }: PayloadAction<IBTSelectProps[]>) => {
          state.commonDetails.loading = false;
          state.commonDetails.data = payload;
        }
      )
      .addCase(getMergedInfo.rejected, (state: IBTState) => {
        state.commonDetails.loading = false;
        state.commonDetails.data = [];
      });

    builder
      .addCase(getMarketsInfo.pending, (state: IBTState) => {
        state.marketDetails.loading = true;
        state.marketDetails.data = [];
      })
      .addCase(
        getMarketsInfo.fulfilled,
        (state: IBTState, { payload }: PayloadAction<MarketInfoProps[]>) => {
          state.marketDetails.loading = false;
          state.marketDetails.data = payload;
        }
      )
      .addCase(getMarketsInfo.rejected, (state: IBTState) => {
        state.marketDetails.loading = false;
        state.marketDetails.data = [];
      });
  },
});

export const IBTActions = ibtSlice.actions;
export default ibtSlice.reducer;
