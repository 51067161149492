// import React from 'react';
// import routepaths from './routepaths';

// import CapacityTariff from '../pages/CapacityTariff';
// import CapacityTariffAdd from '../pages/CapacityTariffAdd';

import IAppRoute from './AppRoute.type';

const CapacityTariffRoutes: IAppRoute[] = [
  // { path: routepaths.capacityTariff, element: <CapacityTariff /> },
  // {
  //   path: routepaths.capacityTariffAdd,
  //   element: <CapacityTariffAdd />,
  // },
];

export default CapacityTariffRoutes;
