import styled from 'styled-components';
import { Modal } from '@sede-x/shell-ds-react-framework';

// eslint-disable-next-line import/prefer-default-export
export const ShellModal = styled(Modal)`
  .shell-modal-container-title {
    div:has(h1) {
      width: 100%;
    }
    .draggable-title {
      cursor: move;

      ::first-letter {
        text-transform: capitalize;
      }
    }
  }
`;
