import React from 'react';
import SuspenseLoadPage from '../../components/OtherComponents/SuspenseLoadPage';

const CMTApplicationStatusHistoryComp = React.lazy(
  () => import('../../components/ChartsComp/CMTApplicationStatusHistory')
);

const CMTApplicationStatusHistory = () => {
  return (
    <SuspenseLoadPage>
      <CMTApplicationStatusHistoryComp />
    </SuspenseLoadPage>
  );
};

export default CMTApplicationStatusHistory;
