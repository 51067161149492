import React from 'react';
import routepaths from './routepaths';

import PnLDashboardViewComp from '../pages/PnLDashboard/ViewPnLDashboard';
import PnLDashboardInputComp from '../pages/PnLDashboard/InputPnLDashboard';

import IAppRoute from './AppRoute.type';

const PNLRoutes: IAppRoute[] = [
  {
    path: routepaths.pnlDashboardView,
    element: <PnLDashboardViewComp />,
  },
  {
    path: routepaths.pnlDashboardInput,
    element: <PnLDashboardInputComp />,
  },
];

export default PNLRoutes;
