import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Icons } from '@sede-x/shell-ds-react-framework';
import { useTimeout } from './useTimeout';

const { CrossCircleSolid, CheckCircleSolid, InfoCircle, Cross } = Icons;

interface ToastProps {
  close: () => void;
  type: 'success' | 'error' | 'info'; // Add this line
  index: number;
  content: string;
}

interface ToastContainerProps {
  type: string;
  index: number;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const successStyles = css`
  background-color: #74b784;
`;

const errorStyles = css`
  background-color: #d26f79;
`;

const infoStyles = css`
  background-color: #ced784;
`;

const ToastContainer = styled.div<ToastContainerProps>`
  position: relative;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-end;
  align-items: center;
  box-sizing: 'border-box';
  animation: ${slideIn} 0.5s ease;
  ${(props) => props.type === 'success' && successStyles}
  ${(props) => props.type === 'error' && errorStyles}
  ${(props) => props.type === 'info' && infoStyles}
`;

const Icon = styled.span`
  margin-right: 10px;
  cursor: pointer;
  svg path {
    fill: #fff;
  }
`;

const Toast: React.FC<ToastProps> = ({ close, type, index, content }) => {
  const timeoutDuration = type === 'error' ? 10000 : 5000;
  useTimeout(close, timeoutDuration);

  const [icon, setIcon] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    switch (type) {
      case 'success':
        setIcon(<CheckCircleSolid width={25} height={25} fill='#fff' />);
        break;
      case 'error':
        setIcon(<CrossCircleSolid width={25} height={25} />);
        break;
      case 'info':
        setIcon(<InfoCircle width={25} height={25} />);
        break;
      default:
        setIcon(null);
    }
  }, [type]);

  return (
    <ToastContainer type={type} index={index} onClick={close}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          lineHeight: '1.2',
          color: '#fff',
          boxSizing: 'border-box',
          width: 'auto',
        }}
      >
        <Icon>{icon}</Icon>
        <Icon>{content}</Icon>
        <Icon style={{ paddingTop: 5 }}>
          <Cross width={25} height={25} />
        </Icon>
      </div>
    </ToastContainer>
  );
};

export default Toast;
