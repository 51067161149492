import React from 'react';
import { Select, Option } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

interface IRecordsPerPageProps {
  pageSize: number;
  onPageSIzeChange: (pno: number) => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

const RecordsPerPage: React.FC<IRecordsPerPageProps> = ({
  pageSize,
  onPageSIzeChange,
}) => {
  return (
    <Container>
      <div>Records per page</div>
      <div style={{ maxWidth: '80px' }}>
        <Select value={pageSize} onChange={onPageSIzeChange} showSearch={false}>
          <Option value={10}>10</Option>
          <Option value={25}>25</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
        </Select>
      </div>
    </Container>
  );
};

export default RecordsPerPage;
