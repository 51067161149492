/* eslint-disable no-param-reassign */
import axios, { AxiosResponse } from 'axios';

export const axiosConfig = {
  baseURL: process.env.REACT_APP_COP_API_BASE_URL,
  timeout: 50000,
  headers: {
    common: {
      Authorization: 'YWRtaW46c2hlbGwxMjM0',
    },
  },
};

export const COPApi = axios.create(axiosConfig);

COPApi.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('access_token') || '';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const getRequestAbortionPieces = () => {
  const abort = axios.CancelToken.source();
  const connectionTimeout = setTimeout(() => {
    abort.cancel(`Connection timeout of ${axiosConfig.timeout}ms.`);
  }, axiosConfig.timeout);

  return { abort, connectionTimeout };
};

export const get = async (
  path: string,
  config = {}
): Promise<AxiosResponse> => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();
  return COPApi.get(path, {
    cancelToken: abort.token,
    ...config,
  }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};

export const post = async (
  path: string,
  body,
  config = {}
): Promise<AxiosResponse> => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return COPApi.post(path, body, {
    cancelToken: abort.token,
    ...config,
  }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};
export const put = async (path: string, body): Promise<AxiosResponse> => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return COPApi.put(path, body, {
    cancelToken: abort.token,
  }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};

export const deleteApi = async (path: string, body): Promise<AxiosResponse> => {
  const { connectionTimeout } = getRequestAbortionPieces();
  return COPApi.delete(path, { data: body }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};
