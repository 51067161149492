import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const NetPositionComp = React.lazy(
  () => import('../components/FirstUtility/NetPosition')
);

const NetPosition: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.firstUtility}>
      <NetPositionComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default NetPosition;
