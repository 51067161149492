import React from 'react';
import { useSelector } from 'react-redux';
import FieldNomsNotifications from './FieldNomsDeadline';
import { FullStateI } from '../../../types/All.type';

const Notifications = () => {
  const { count: notificationCount, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.notifications
  );

  return (
    <>
      <FieldNomsNotifications />
      {notificationCount === 0 && !loading && <p>No new notifications found</p>}
    </>
  );
};

export default Notifications;
