/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import {
  PendingTaskListI,
  PendingTaskStateI,
  PendingTaskI,
  IdForCalendarObjI,
} from '../../types/OpsChecklist.type';
import { get as GetApi } from '../../services/COPApi';
import { notificationCountActions } from '../NotificationCount';

const initialState: PendingTaskStateI = {
  isFetching: false,
  error: null,
  list: [],
  idForCalendar: {},
};
interface GetFetchPendingTaskStateRespI {
  list: PendingTaskListI[];
  idForCalendar: IdForCalendarObjI;
}

const getFetchPendingTaskState = (
  taskList: PendingTaskI[]
): GetFetchPendingTaskStateRespI => {
  const list: PendingTaskListI[] = [];
  const idForCalendar: IdForCalendarObjI = {};
  const now = moment().tz('Europe/London');
  taskList.forEach((task, taskIndex) => {
    if (!(task.id in idForCalendar) && task.taskStatus === 1) {
      const isDelayed = now.isAfter(task.taskStartTime);
      if (isDelayed || task.priorityId === 1) {
        list.push({
          task,
          isHighPriority: task.priorityId === 1,
          isDelayed,
        });
        idForCalendar[task.taskGroupKey + task.taskStartTime] = {
          notUpdated: true,
          taskIndex,
        };
      }
    }
  });

  return { list, idForCalendar };
};

export const fetchPendingTask = createAsyncThunk(
  'pendingTask/fetchPendingTask',
  async (_, { dispatch }) => {
    const cid = localStorage.getItem('country_id') || '1';
    const date = moment().tz('Europe/London').toISOString();
    return GetApi(
      `/api/cop/Checklist/GetAllTaskNotification/${date}/${cid}`
    ).then((resp) => {
      const { list, idForCalendar } = getFetchPendingTaskState(resp.data);
      const count = Object.keys(idForCalendar).length;
      dispatch(
        notificationCountActions.updateNotificationCount({
          itemName: 'clTask',
          count,
        })
      );
      return { list, idForCalendar };
    });
  }
);

const pendingTaskSlice = createSlice({
  name: 'pendingTask',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingTask.pending, (state: PendingTaskStateI) => {
        state.isFetching = true;
        state.error = null;
        state.list = [];
        state.idForCalendar = {};
      })
      .addCase(
        fetchPendingTask.fulfilled,
        (
          state: PendingTaskStateI,
          { payload }: PayloadAction<GetFetchPendingTaskStateRespI>
        ) => {
          const { list, idForCalendar } = payload;
          state.isFetching = false;
          state.error = null;
          state.list = list;
          state.idForCalendar = idForCalendar;
        }
      )
      .addCase(fetchPendingTask.rejected, (state: PendingTaskStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.list = [];
        state.idForCalendar = {};
      });
  },
});

export const CLPendingTaskActions = pendingTaskSlice.actions;
export default pendingTaskSlice.reducer;
