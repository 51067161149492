import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormField,
  RadioButton,
  SingleDatePicker,
  SinglePickerValueType,
  TextInput,
} from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { get as GetApi } from '../../../../services/COPApi';

interface OpportunitiesRes {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  createdByUserId: number;
  createdByUserName: string;
  createdDate: string;
  modifiedByUserId: number;
  modifiedByUserName: string;
  modifiedDate: string;
}
export interface FilterOpsProps {
  searchKeyWord: any;
  setSearchKeyWord: (search: string) => void;
  callFilterOpps: (
    dateSelected: string | SinglePickerValueType<dayjs.Dayjs>,
    optionSelected: string
  ) => void;
  callFetchOpportunities: () => void;
  updatedData: (val: OpportunitiesRes[]) => void;
  resetCall: boolean;
  resetStatus: boolean;
  setErrorMsg: (val: boolean) => void;
}

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2%;
`;
const DateContainer = styled.div`
  width: 15%;
`;
const SearchContainer = styled.div`
  display: flex;
  width: 16%;
  margin-left: 2%;
  margin-right: 1%;
`;
const StatusContainer = styled.div`
  display: flex;
  align-self: start;
  width: 15%;
  margin-left: 2%;
`;
const ActionContainer = styled.div`
  align-self: center;
  margin-left: 33%;
  display: flex;
  justify-content: space-around;
  width: 11%;
`;
const StyledRadioBtn = styled(RadioButton)`
  margin-left: 8%;
  margin-right: 16%;
`;
const ActionBtn = styled.div`
  display: flex;
  margin-top: 2%;
  justify-content: space-between;
`;

const FilterOpportunities: React.FC<FilterOpsProps> = ({
  searchKeyWord,
  setSearchKeyWord,
  callFilterOpps,
  callFetchOpportunities,
  updatedData,
  resetCall,
  resetStatus,
  setErrorMsg,
}) => {
  const [optionSelected, setOptionSelected] = useState('active');
  const [dateSelected, setSelectedDate] =
    useState<SinglePickerValueType<Dayjs> | null>(null);
  const [applyClicked, setApplyClicked] = useState<boolean>(false);
  const [newResponse, setNewResponse] = useState({ data: [], call: false });

  const filterByStatus = useCallback(async () => {
    const statusVal = optionSelected === 'active' ? 'true' : 'false';
    try {
      const response = await GetApi(
        `/api/cop/CommercialOpportunity/GetOpportunities?isActive=${statusVal}`
      );
      if (response.status === 200) {
        if (response.data.length > 0) {
          setNewResponse({ data: response.data, call: true });
          setSelectedDate(null);
        } else {
          setNewResponse({ data: response.data, call: true });
          setSelectedDate(null);
        }
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } catch (err) {
      setSelectedDate(null);
      setNewResponse({ data: [], call: false });
      setErrorMsg(true);
    }
  }, [optionSelected, setErrorMsg]);

  useEffect(() => {
    setOptionSelected('active');
  }, [resetStatus]);

  useEffect(() => {
    if (resetCall) {
      setOptionSelected('active');
      setSelectedDate(null);
    }
  }, [resetCall]);

  // If Date selected, callback FilterByDate api to get opportunities
  useEffect(() => {
    if (applyClicked && dateSelected !== null) {
      callFilterOpps(
        dayjs(dateSelected).format('YYYY-MM-DD'),
        optionSelected === 'active' ? 'true' : 'false'
      );
      setApplyClicked(false);
    }
  }, [applyClicked, callFilterOpps, dateSelected, optionSelected]);

  // when status option is changed
  useEffect(() => {
    if (optionSelected) {
      filterByStatus();
    }
    return () => {
      setNewResponse({ data: [], call: false });
      setSelectedDate(null);
      setErrorMsg(false);
    };
  }, [optionSelected, filterByStatus, setErrorMsg]);

  // callback updatedData to render the table in Opportunities after filtering by Status
  useEffect(() => {
    if (newResponse.data && newResponse.call) {
      updatedData(newResponse.data);
      setNewResponse({ ...newResponse, call: false });
    }
  }, [newResponse, updatedData]);

  return (
    <>
      <FilterContainer>
        <DateContainer>
          <FormField label='Filter by Date' size='small'>
            <SingleDatePicker
              size='medium'
              aria-label='StartDate'
              placeholder='YYYY-MM-DD'
              onChange={(value) => {
                setSelectedDate(value);
                setApplyClicked(true);
              }}
              value={dateSelected}
              data-testid='date-filter'
            />
          </FormField>
        </DateContainer>
        <SearchContainer>
          <FormField label='Search by Keyword' size='small' id='search-field'>
            <TextInput
              data-testid='search-input'
              value={searchKeyWord}
              placeholder='Search'
              onChange={(e) => {
                setSearchKeyWord(e.target.value);
              }}
              aria-labelledby='search-field'
            />
          </FormField>
        </SearchContainer>
        <StatusContainer>
          <FormField
            label='Filter by Status'
            size='small'
            style={{ display: 'block' }}
          >
            <ActionBtn>
              <RadioButton
                data-testid='radio-active'
                label='Active'
                name='active'
                onChange={() => {
                  setOptionSelected('active');
                }}
                value='active'
                checked={optionSelected === 'active'}
                size='medium'
              />
              <StyledRadioBtn
                data-testid='radio-inactive'
                checked={optionSelected === 'inactive'}
                label='Inactive'
                name='inactive'
                onChange={() => {
                  setOptionSelected('inactive');
                }}
                value='inactive'
                size='medium'
              />
            </ActionBtn>
          </FormField>
        </StatusContainer>
        <ActionContainer>
          <Button
            size='small'
            onClick={() => {
              callFetchOpportunities();
              setSelectedDate(null);
              setOptionSelected('active');
              setSearchKeyWord('');
            }}
            data-testid='reset-btn'
          >
            Reset
          </Button>
        </ActionContainer>
      </FilterContainer>
    </>
  );
};

export default FilterOpportunities;
