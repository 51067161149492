export const GetKpiDailyReportAPI = (numberOfDays: number) =>
  `/api/cop/KpiReportData/GetKpiDailyReport?numberOfDays=${numberOfDays}`;

export const GetKpiDailyRefreshDataAPI =
  '/api/cop/KpiReportData/GetKpiDailyRefreshData';

export const GetKpiTradedMonthlyVolumeAPI = (numberOfMonths: number) =>
  `/api/cop/KpiReportData/GetMonthlyTradedGasVolume?numberOfMonths=${numberOfMonths}`;

export const GetKpiTradedMonthlyVolumeByComOpsAPI = (numberOfMonths: number) =>
  `/api/cop/KpiReportData/GetMonthlyTradedGasVolumeByComOps?numberOfMonths=${numberOfMonths}`;

export const GetDailyCmtMetricsCountAPI = (limitDays: number) =>
  `api/cop/CmtMetricsData/GetDailyCmtMetricsCount?limitDays=${limitDays}`;

export const GetMonthlyCmtMetricsCountAPI = (limitMonths: number) =>
  `api/cop/CmtMetricsData/GetMonthlyCmtMetricsCount?limitMonths=${limitMonths}`;

export const CHART_LEGEND_COLOR = {
  LIGHT: '#9e9e9e',
  DARK: '#e0e0e0',
};

export const GetCapacityBookedDailyVolumeAPI = (numberOfDays: number) =>
  `/api/cop/KpiReportData/GetDailyVolumeBookedReport?numberOfDays=${numberOfDays}&api-version=v1`;

export const GetCapacityBookedMonthlyVolumeAPI = (numberOfMonths: number) =>
  `/api/cop/KpiReportData/GetMonthlyVolumeBookedReport?numberOfMonths=${numberOfMonths}&api-version=v1`;

export const GetVolumeImbalanceDailyAPI = (numberOfDays: number) =>
  `/api/cop/KpiReportData/GetDailyVolumeImbalanceReport?numberOfDays=${numberOfDays}&api-version=v1`;

export const GetVolumeImbalanceMonthlyAPI = (numberOfMonths: number) =>
  `/api/cop/KpiReportData/GetMonthlyVolumeImbalanceReport?numberOfMonths=${numberOfMonths}&api-version=v1`;
