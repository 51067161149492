import React from 'react';
import styled from 'styled-components';
import { Heading, Icons } from '@sede-x/shell-ds-react-framework';

const UnauthorizedContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;
interface UnauthorizedPropsI {
  message?: string;
}
const Unauthorized: React.FC<UnauthorizedPropsI> = ({ message }) => {
  const { AlertSolid: AlertSolidIcon } = Icons;
  return (
    <UnauthorizedContainer>
      <div>
        <AlertSolidIcon width={100} height={100} />
      </div>
      <Heading type='h1'>{message}</Heading>
    </UnauthorizedContainer>
  );
};

Unauthorized.defaultProps = {
  message: 'Sorry you are not authorized to access COP',
};

export default Unauthorized;
