/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Loading, IButtonProps } from '@sede-x/shell-ds-react-framework';
import { ShellButton } from './style';

const Button: FC<IButtonProps> = ({
  variant,
  children,
  loading = false,
  ...rest
}) => {
  return (
    <ShellButton variant={variant} {...rest}>
      {loading && (
        <Loading type={variant === 'filled' ? 'primary' : 'default'} />
      )}{' '}
      {children}
    </ShellButton>
  );
};

export default Button;
