import React, { Suspense } from 'react';
import styled from 'styled-components';

const LoadingDiv = styled.div`
  padding: 15px;
`;

const SuspenseLoadPage: React.FC = ({ children }) => {
  return (
    <Suspense fallback={<LoadingDiv>Loading...</LoadingDiv>}>
      {children}
    </Suspense>
  );
};

export default SuspenseLoadPage;
