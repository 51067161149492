import React from 'react';
import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const PSLogs = React.lazy(() => import('./PowerShift'));
const PTOAttachements = React.lazy(() => import('./PTOAttachments'));

type PSMainProps = 'ptoLogs' | 'ptoAttachments';

interface PSMainCompProps {
  type: PSMainProps;
}

const PowerShiftMainComp: React.FC<PSMainCompProps> = ({ type }) => {
  const pageName = applicationName.PowerOpsLog; // to do

  return (
    <CheckAuthorizationWithSuspense pageName={pageName}>
      {type === 'ptoLogs' && <PSLogs />}
      {type === 'ptoAttachments' && <PTOAttachements />}
    </CheckAuthorizationWithSuspense>
  );
};

export default PowerShiftMainComp;
