import moment from 'moment';
import { TIME_ZONE } from '../../../components/GTO/CustomDatePicker/components';

export const GetAllPTOMarkets = () => `/api/cop/PtoLog/GetAllPtoMarkets`;
export const GetAllPTOBlocks = () => `/api/cop/PtoLog/GetAllPtoBlocks`;
export const SearchOrAddNewPtoLog = (date: string) =>
  `/api/cop/PtoLog/SearchOrAddNewLog?date=${date}`;
export const AddOrUpdatePtoLog = () => `/api/cop/PtoLog/AddOrUpdateLog`;
export const GetAllUser = `/api/User/GetAllUser?team=2`;
export const UpdateOperatorDetail = () =>
  `/api/cop/PtoLog/UpdateOperatorDetail`;
export const AddAttachmentsPTO = `/api/cop/PtoLog/AddAttachments`;
export const GetAllAttachmentsPTO = (ptoLogId: number) =>
  `/api/cop/PtoLog/GetAllAttachments?ptoLogId=${ptoLogId}`;
export const PreviewAttachmentPTO = (id: number) =>
  `/api/cop/PtoLog/PreviewAttachment?id=${id}`;
export const AddOrUpdateMarket = () => `/api/cop/PtoLog/AddOrUpdatePtoMarket`;
export const OnDeleteMarket = (id: number) =>
  `/api/cop/PtoLog/DeletePtoMarket?id=${id}`;

export const operatorNameMap = {
  dayShiftTrader: 'Day Shift Trader',
  tShiftTrader: 'T-shift Trader',
  powerAnalyst: 'Power Analyst',
  nightShift: 'Night Shift Trader',
};

export function disableOnPastDate(datePassed) {
  const CURRENT_DATE = moment().tz(TIME_ZONE);

  if (CURRENT_DATE.hour() >= 23) {
    CURRENT_DATE.add(1, 'day');
  }

  return CURRENT_DATE.format('YYYY-MM-DD HH:mm:ss') > datePassed;
}
