/* eslint-disable
jsx-a11y/mouse-events-have-key-events,
@typescript-eslint/ban-ts-comment,
@typescript-eslint/ban-ts-comment,
react/require-default-props,
react/jsx-props-no-spreading,
import/prefer-default-export */
import React, { FC, useRef, useState } from 'react';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { IModalProps } from '@sede-x/shell-ds-react-framework/build/esm';
import { ShellModal } from './style';

interface IProps extends IModalProps {
  isDraggable?: boolean;
}

export const Modal: FC<IProps> = ({
  isDraggable = false,
  onClose = () => {},
  title = '',
  children,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleOnClose = () => {
    setPosition({ x: 0, y: 0 });
    onClose();
  };

  const handleDrag = (e, { x, y }) => {
    setPosition({ x, y });
  };

  const renderTitle = () => (
    <div
      className='draggable-title'
      onMouseOver={() => {
        if (disabled) {
          setDisabled(false);
        }
      }}
      onMouseOut={() => {
        setDisabled(true);
      }}
    >
      {title}
    </div>
  );

  const renderModal = (modal) => (
    <>
      {/* @ts-ignore */}
      <Draggable
        disabled={disabled}
        bounds={bounds}
        nodeRef={draggleRef}
        position={position}
        onDrag={handleDrag}
        onStart={(event, uiData) => onStart(event, uiData)}
      >
        <div ref={draggleRef}>{modal}</div>
      </Draggable>
    </>
  );

  return (
    <>
      <ShellModal
        onClose={handleOnClose}
        title={isDraggable ? renderTitle() : title}
        modalRender={isDraggable ? renderModal : undefined}
        {...rest}
      >
        {children}
      </ShellModal>
    </>
  );
};
