import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const CnCalcFormulaBuilderComp = React.lazy(
  () => import('../../components/ConversionCalculator/CnCalcFormulaBuilder')
);

const CnCalcFormulaBuilder = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.conversionCalcFormulaBuilder}
      // role={userRole.readWrite}
    >
      <CnCalcFormulaBuilderComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default CnCalcFormulaBuilder;
