import React from 'react';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';
import PowerShiftMainComp from '../pages/powershift';

// import GtoSettings from '../pages/gto/GtoSettings';
// import GTOAttachments from '../pages/gto/GTOAttachments';

// {
//   path: routePaths.gtoSettings,
//   element: <GtoMainComp type='gtoSettings' />,
// },

const PowerShiftRoutes: IAppRoute[] = [
  {
    path: routePaths.powerShiftLogs,
    element: <PowerShiftMainComp type='ptoLogs' />,
  },
  {
    path: routePaths.ptoAttachments,
    element: <PowerShiftMainComp type='ptoAttachments' />,
  },
];

export default PowerShiftRoutes;
