/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get as GetApi } from '../../services/COPApi';
import { gtoLosStateI } from '../../types/GtoLogsStore.type';
import {
  GetAllCommercialValueType,
  GetAllCurrency,
  GetAllIncidentType,
  GetAllMarkets,
  GetAllUnitsForVolume,
} from '../../components/GTO/gtoAPIconstants';

export interface GtoPayloadType {
  sectionNumber: number[];
}
export interface GtoLogIdType {
  logId: number;
}

export interface APISourceStateI {
  isSubmitting: boolean;
  isFetching: boolean;
  error: string | null;
  unitsOfVol: any[];
  unitOfPnlOptions: any[];
  unitOfSpreadOptions: any[];
  marketOptions: any[];
  incidentTypes: any[];
}

const initialState: gtoLosStateI = {
  openPanels: [],
  gtoLogId: 0,
  isSubmitting: false,
  isFetching: false,
  error: null,
  unitsOfVol: [],
  unitOfPnlOptions: [],
  unitOfSpreadOptions: [],
  marketOptions: [],
  incidentTypes: [],
};

export const fetchUnitsforVol = createAsyncThunk(
  'gtoLogs/fetchUnitsforVol',
  async () =>
    GetApi(GetAllUnitsForVolume).then((resp) => {
      return resp.data;
    })
);

export const fetchAllIncidentTypes = createAsyncThunk(
  'gtoLogs/fetchAllIncidentTypes',
  async () =>
    GetApi(GetAllIncidentType).then((resp) => {
      return resp.data;
    })
);
export const fetchAllMarkets = createAsyncThunk(
  'gtoLogs/fetchAllMarkets',
  async () =>
    GetApi(GetAllMarkets).then((resp) => {
      return resp.data;
    })
);
export const fetchCurrency = createAsyncThunk(
  'gtoLogs/fetchCurrency',
  async () =>
    GetApi(GetAllCurrency).then((resp) => {
      return resp.data;
    })
);
export const fetchCommercialValueType = createAsyncThunk(
  'gtoLogs/fetchCommercialValueType',
  async () =>
    GetApi(GetAllCommercialValueType).then((resp) => {
      return resp.data;
    })
);

const gtoLogsSlice = createSlice({
  name: 'gtoLogs',
  initialState,
  reducers: {
    updateOpenPanels: (
      state: gtoLosStateI,
      { payload }: PayloadAction<GtoPayloadType>
    ) => {
      const sectionNo = payload.sectionNumber;
      const updatedItems = [...state.openPanels, ...sectionNo];
      state.openPanels = updatedItems;
    },
    updateGtoLogId: (
      state: gtoLosStateI,
      { payload }: PayloadAction<GtoLogIdType>
    ) => {
      state.gtoLogId = payload.logId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitsforVol.pending, (state: APISourceStateI) => {
        state.isFetching = true;
        state.error = null;
        state.unitsOfVol = [];
      })
      .addCase(
        fetchUnitsforVol.fulfilled,
        (state: APISourceStateI, { payload }: PayloadAction<any[]>) => {
          state.isFetching = false;
          state.error = null;
          state.unitsOfVol = payload;
        }
      )
      .addCase(fetchUnitsforVol.rejected, (state: APISourceStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.unitsOfVol = [];
      });
    // unitOfSpreadOptions
    builder
      .addCase(fetchCommercialValueType.pending, (state: APISourceStateI) => {
        state.isFetching = true;
        state.error = null;
        state.unitOfSpreadOptions = [];
      })
      .addCase(
        fetchCommercialValueType.fulfilled,
        (state: APISourceStateI, { payload }: PayloadAction<any[]>) => {
          state.isFetching = false;
          state.error = null;
          state.unitOfSpreadOptions = payload;
        }
      )
      .addCase(fetchCommercialValueType.rejected, (state: APISourceStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.unitOfSpreadOptions = [];
      });
    // marketOptions
    builder
      .addCase(fetchAllMarkets.pending, (state: APISourceStateI) => {
        state.isFetching = true;
        state.error = null;
        state.marketOptions = [];
      })
      .addCase(
        fetchAllMarkets.fulfilled,
        (state: APISourceStateI, { payload }: PayloadAction<any[]>) => {
          state.isFetching = false;
          state.error = null;
          state.marketOptions = payload;
        }
      )
      .addCase(fetchAllMarkets.rejected, (state: APISourceStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.marketOptions = [];
      });
    // incidentTypes
    builder
      .addCase(fetchAllIncidentTypes.pending, (state: APISourceStateI) => {
        state.isFetching = true;
        state.error = null;
        state.incidentTypes = [];
      })
      .addCase(
        fetchAllIncidentTypes.fulfilled,
        (state: APISourceStateI, { payload }: PayloadAction<any[]>) => {
          state.isFetching = false;
          state.error = null;
          state.incidentTypes = payload;
        }
      )
      .addCase(fetchAllIncidentTypes.rejected, (state: APISourceStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.incidentTypes = [];
      });
    //
    builder
      .addCase(fetchCurrency.pending, (state: APISourceStateI) => {
        state.isFetching = true;
        state.error = null;
        state.unitOfPnlOptions = [];
      })
      .addCase(
        fetchCurrency.fulfilled,
        (state: APISourceStateI, { payload }: PayloadAction<any[]>) => {
          state.isFetching = false;
          state.error = null;
          state.unitOfPnlOptions = payload;
        }
      )
      .addCase(fetchCurrency.rejected, (state: APISourceStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.unitOfPnlOptions = [];
      });
  },
});

export const GTOLogsActions = gtoLogsSlice.actions;
export default gtoLogsSlice.reducer;
