import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const Audit = React.lazy(
  () => import('../components/OpsChecklist/OclReport/Audit')
);

const OclAuditReport = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.checklistAuditTaskReport}
    >
      <Audit />
    </CheckAuthorizationWithSuspense>
  );
};

export default OclAuditReport;
