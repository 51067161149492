import React from 'react';
import routepaths from './routepaths';

import TradePriceList from '../pages/tradeprice/PriceList';
import IAppRoute from './AppRoute.type';

const TradePriceRoutes: IAppRoute[] = [
  { path: routepaths.tradePriceList, element: <TradePriceList /> },
];

export default TradePriceRoutes;
