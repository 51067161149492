import React, { useEffect } from 'react';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Router from './router/Router';
import './App.css';
import getAccessTokenFromCodeRefreshToken from './utils/auth/getAccessTokenFromCodeRefreshToken';
import routepaths from './router/routepaths';
import { userRoleActions } from './store/User';
import { FullStateI } from './types/All.type';
import { AxiosInterceptor } from './services/CMTApi';
import { ToastProvider } from './UI/Toast/ToastProvider';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { isDark } = useSelector((state: FullStateI) => state.Theme);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const refreshToken = sessionStorage.getItem('refresh_token');
      if (!refreshToken) return;
      const { user, accessToken, error } =
        await getAccessTokenFromCodeRefreshToken(refreshToken);
      if (error) {
        sessionStorage.setItem('redirectPath', location.pathname);
        navigate(routepaths.login);
      } else if (user) {
        const userObj = {
          sn: user.sn,
          givenName: user.givenName,
          uid: user.uid,
          accessToken,
          mail: user.mail,
        };
        dispatch(userRoleActions.setNameAccessTocken(userObj));
      }
    };

    const interval = setInterval(() => {
      const expiryTime = sessionStorage.getItem('expiry_time');
      if (expiryTime) {
        const exp = moment.unix(parseInt(expiryTime, 10));
        const dif = exp.diff(moment(), 'seconds');
        if (dif < 150) {
          refreshAccessToken();
        }
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [navigate, dispatch, location.pathname]);
  return (
    <ShellThemeProvider theme={isDark ? 'dark' : 'light'}>
      <AxiosInterceptor>
        <ToastProvider>
          <Router />
        </ToastProvider>
      </AxiosInterceptor>
    </ShellThemeProvider>
  );
};

export default App;

// CICD TEST
