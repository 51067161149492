import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const MySteersComp = React.lazy(
  () => import('../../components/PositionHandover/MySteers')
);

const MySteers = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.positionHandoverTrader}
    >
      <MySteersComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default MySteers;
