import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const OclAddTaskComp = React.lazy(
  () => import('../components/OpsChecklist/AddTask')
);

const OclAddTask: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.checkListAddTask}
      // role={userRole.readWrite}
    >
      <OclAddTaskComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default OclAddTask;
