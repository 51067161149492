/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  Card,
  FormField,
  Grid,
  RadioButton,
  TextInput,
} from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import { put as PutApi } from '../services/COPApi';
import Loader from '../UI/Loader';

const StyledCard = styled(Card)`
  width: 50%;
  height: 60%;
  margin: auto;
`;
const TitleContainer = styled.div`
  display: flex;
  font-style: bold;
`;
const ArrowDiv = styled.div`
  display: flex;
`;
const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ActiveRadioBtn = styled.label`
  display: flex;
  align-items: center;
`;
const ActionBtns = styled.div`
  display: flex;
  justify-content: center;
`;

type FormValues = {
  name: string;
  description: string;
  isActive: boolean;
};

const OclEditOpportunity = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state;
  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const form = useForm<FormValues>({
    defaultValues: {
      name: data.name ?? '',
      description: data.description ?? '',
      isActive: data.isActive,
    },
    mode: 'onSubmit',
  });
  // To register form control with react hook form
  const { control, register, handleSubmit, formState, watch, reset } = form;
  // when user has filled in data, enable btn, use - isDirty
  const { errors, isDirty, isValid, isSubmitting } = formState;

  const onSubmit = async (formData: FormValues) => {
    const statusVal = formData.isActive.toString() === 'true';

    const reqBody = JSON.parse(
      JSON.stringify({
        ...formData,
        id: data.id,
        isActive: statusVal,
      })
    );
    setLoader(true);

    try {
      const response = await PutApi(
        `/api/cop/CommercialOpportunity/Update`,
        reqBody
      );
      if (response.status === 200) {
        setSuccessAlert(true);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  // to perform any sideeffect after value changes
  useEffect(() => {
    const subscription = watch(() => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {loader && <Loader />}

      <StyledCard size='large'>
        <TitleContainer>
          <ArrowDiv>
            <span>
              <ArrowLeft width={20} height={20} onClick={() => navigate(-1)} />
            </span>
          </ArrowDiv>
          <TitleContent>
            <span>
              <h3> Edit Opportunity </h3>
            </span>
          </TitleContent>
        </TitleContainer>
        {successAlert && (
          <Alert
            state='success'
            dismissible
            onDismissClick={() => {
              setSuccessAlert(false);
            }}
            style={{ marginBottom: '2%' }}
          >
            Opportunity updated successfully!
          </Alert>
        )}
        {/* noValidate - to stop browser validation and react hook forms to take control of validation */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormField label='Flow/Asset'>
            <TextInput
              type='text'
              id='name'
              {...register('name', {
                required: 'Name is required',
              })}
            />
          </FormField>
          <p>{errors.name?.message}</p>
          <FormField label='Description'>
            <TextInput
              id='description'
              {...register('description', {
                required: 'Description is required',
              })}
            />
          </FormField>
          <p>{errors.description?.message}</p>
          <FormField label='Status'>
            <Controller
              control={control}
              name='isActive'
              render={({ field }) => (
                <>
                  <Grid columns={3}>
                    <div>
                      <RadioButton
                        label='Active'
                        value={data.isActive}
                        checked={form.getValues('isActive') === true}
                        onChange={() => field.onChange(true)}
                      />
                    </div>
                    <div>
                      <ActiveRadioBtn>
                        <RadioButton
                          label='Inactive'
                          value={data.isActive}
                          checked={form.getValues('isActive') === false}
                          onChange={() => field.onChange(false)}
                        />
                      </ActiveRadioBtn>
                    </div>
                  </Grid>
                </>
              )}
            />
          </FormField>
          <p>{errors.isActive?.message}</p>
          <ActionBtns>
            <Button
              disabled={(isDirty || !isValid || isSubmitting) && successAlert}
              size='large'
            >
              Save Changes
            </Button>
            <Button
              size='large'
              type='button'
              onClick={() => reset()}
              style={{ marginLeft: '2%' }}
            >
              Cancel Changes
            </Button>
          </ActionBtns>
        </form>
      </StyledCard>
    </>
  );
};
export default OclEditOpportunity;
