export const GetStoreDataAPI = (locationId: number) =>
  `/api/cop/CredentialsStore/GetStoreData?locationId=${locationId}`;

export const AddStoreDataAPI = '/api/cop/CredentialsStore/AddStoreData';

export const DownloadExcelAPI = (locationId: number) =>
  `/api/cop/CredentialsStore/DownloadExcel?locationId=${locationId}`;

export const UpdateStoreDataAPI = '/api/cop/CredentialsStore/UpdateStoreData';

export const GetStoreDataByIdAPI = (id: string, locationId: number) =>
  id
    ? `/api/cop/CredentialsStore/GetStoreDataById?id=${id}&locationId=${locationId}`
    : '';

export const DeleteStoreDataAPI = '/api/cop/CredentialsStore/DeleteStoreData';

export const GetCredentialCountryAPI = (locationId: number) =>
  `/api/cop/CredentialsStore/GetCredentialCountry?locationId=${locationId}`;

export const AddCredentialCountryAPI =
  '/api/cop/CredentialsStore/AddCredentialCountry';

export const DeleteCredentialCountryAPI = (id: number) =>
  `/api/cop/CredentialsStore/DeleteCredentialCountry?Id=${id}`;

export const UpdateCredentialCountryAPI =
  '/api/cop/CredentialsStore/UpdateCredentialCountry';

export const ValidateHubPasswordAPI =
  '/api/cop/CredentialsStore/ValidateHubPassword';

export const LOCATION_ID = {
  SEEL: 1,
  SENA: 2,
};
export const LABELS = {
  [LOCATION_ID.SEEL]: {
    TITLE: 'Web portal links',
    ADD_CRED_SUBTITLE: 'Add Credentials',
    VALIDATION_ERROR:
      'One of URL, username, password and notes is required. All can not be empty',
    ADD_SUCCESS_MSG: 'Credential added successfully',
    ADD_ERROR_MSG: 'Credential could not be added',

    UPDATE_CRED_SUBTITLE: 'Update Credentials',
    UPDATE_SUCCESS_MSG: 'Credential updated successfully',
    UPDATE_ERROR_MSG: 'Credential could not be updated',
    DOWNLOAD_FILE_NAME: 'Web portal links(COP).xlsx',
  },
  [LOCATION_ID.SENA]: {
    TITLE: 'Password Hub',
    ADD_CRED_SUBTITLE: 'Add Credentials',
    VALIDATION_ERROR:
      'One of URL, username, password and notes is required. All can not be empty',
    ADD_SUCCESS_MSG: 'Credential added successfully',
    ADD_ERROR_MSG: 'Credential could not be added',

    UPDATE_CRED_SUBTITLE: 'Update Credentials',
    UPDATE_SUCCESS_MSG: 'Credential updated successfully',
    UPDATE_ERROR_MSG: 'Credential could not be updated',
    DOWNLOAD_FILE_NAME: 'SENA Password Hub(COP).xlsx',
  },
};
