import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  SinglePickerValueType,
} from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import FilterOpportunities from '../FilterOpportunities';
import { mapOpportunities } from '../ShowTasks/util';
import { get as GetApi } from '../../../../services/COPApi';
import OpportunitiesTable from './OpportunitiesTable';
import { FullStateI } from '../../../../types/All.type';
import { applicationName } from '../../../../constants/Auth';
import Unauthorized from '../../../OtherComponents/Unauthorized';
import Loader from '../../../../UI/Loader';
import IsReadOnly from '../../../../utils/getPermission';

export interface OpportunitiesRes {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  createdByUserId: number;
  createdByUserName: string;
  createdDate: string;
  modifiedByUserId: number;
  modifiedByUserName: string;
  modifiedDate: string;
  modifiedDateInGMT: string;
}

const AddBtnContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 5%;
  margin-bottom: 2%;
`;

const Opportunities = () => {
  const navigate = useNavigate();
  const userState = useSelector((state: FullStateI) => state.User);

  const [opportunitiesList, setOpportunitiesList] = useState<
    OpportunitiesRes[]
  >([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [searchKeyWord, setSearchKeyWord] = useState<string | number | any>('');
  const [filteredData, setFilteredData] = useState<boolean>(false);
  const [resetCall, setResetCall] = useState<boolean>(false);
  const [resetStatus, setResetStatus] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);

  const isReadOnly = IsReadOnly({ appName: applicationName.commercialOpsAdd });

  const fetchCreatedOpportunities = async () => {
    setLoader(true);
    try {
      const response = await GetApi(
        `/api/cop/CommercialOpportunity/GetOpportunities?isActive=true`
      );
      if (response.status === 200) {
        if (response.data.length > 0) {
          setOpportunitiesList(response.data);
        } else {
          setOpportunitiesList([]);
        }
        setLoader(false);
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } catch (err) {
      setLoader(false);
      setResetCall(true);
      setErrorMsg(true);
    }
  };

  // Call back after Date filter is selected from FilterOpportunities
  const callFilterOpps = async (
    dateVal: string | SinglePickerValueType<dayjs.Dayjs>,
    statusVal: string
  ) => {
    setLoader(true);

    try {
      const response =
        dateVal !== 'Invalid Date'
          ? await GetApi(
              `/api/cop/CommercialOpportunity/GetOpportunities?IsActive=${
                statusVal === 'true'
              }&dateTime=${dateVal}`
            )
          : await GetApi(
              `/api/cop/CommercialOpportunity/GetOpportunities?IsActive=${
                statusVal === 'true'
              }`
            );
      if (response.status === 200) {
        if (response.data.length > 0) {
          setOpportunitiesList(response.data);
          setFilteredData(true);
        } else {
          setOpportunitiesList([]);
          setErrorMsg(false);
        }
        setLoader(false);
      } else {
        setErrorMsg(true);
      }
    } catch (err) {
      setLoader(false);
      setErrorMsg(true);
    }
  };

  const callFetchOpportunities = () => {
    fetchCreatedOpportunities();
  };

  // Call when searchByText is changed or oppsList is set and render the table again
  const memoizedTask = useMemo(() => {
    if (opportunitiesList.length > 0) {
      const searchedData = mapOpportunities(opportunitiesList, searchKeyWord);
      return searchedData;
    }
    return [];
  }, [opportunitiesList, searchKeyWord]);

  // callback updated data when status is toggled from the table
  const callToggledStatus = () => {
    fetchCreatedOpportunities();
    if (resetStatus) {
      setResetStatus(false);
    } else {
      setResetStatus(true);
    }
  };

  // when Status radio btn is changed, update the table with new data
  const updatedData = useCallback((val) => setOpportunitiesList(val), []);

  // Step1 - Fetch all the created Opportunities for Admin View
  useEffect(() => {
    fetchCreatedOpportunities();
  }, []);

  return (
    <>
      {/* Role based access Alert */}
      {userState.userRole[applicationName.commercialOpsAdd] === 'NoAccess' ||
      !userState.userRole[applicationName.commercialOpsAdd] ? (
        <Unauthorized
          message={`You don't have permission to access this page.`}
        />
      ) : (
        <>
          {userState.userRole[applicationName.commercialOpsAdd] && (
            <AddBtnContainer>
              <Button
                onClick={() => {
                  navigate('/ocl/addOpportunities');
                }}
                disabled={IsReadOnly({
                  appName: applicationName.commercialOpsAdd,
                })}
              >
                Add Opportunity
              </Button>
            </AddBtnContainer>
          )}
          <FilterOpportunities
            callFilterOpps={callFilterOpps}
            searchKeyWord={searchKeyWord}
            setSearchKeyWord={setSearchKeyWord}
            callFetchOpportunities={callFetchOpportunities}
            updatedData={updatedData}
            resetCall={resetCall}
            resetStatus={resetStatus}
            setErrorMsg={setErrorMsg}
          />
          {loader && <Loader />}

          {!loader && opportunitiesList?.length > 0 && !errorMsg ? (
            <OpportunitiesTable
              oppsData={memoizedTask}
              filteredData={filteredData}
              canAddOpps={!isReadOnly}
              isReadOnly={isReadOnly}
              callToggledStatus={callToggledStatus}
              setErrorMsg={setErrorMsg}
            />
          ) : (
            <></>
          )}
          {errorMsg && (
            <Alert state='information'>
              There is some problem in fetching the opportunities!
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default Opportunities;
