/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NotificationCountStateI,
  UpdateNotificationCountI,
} from '../../types/NotificationCount.type';

const initialState: NotificationCountStateI = {
  count: 0,
  notificationItem: {},
};

const notificationCountSlice = createSlice({
  name: 'notificationCount',
  initialState,
  reducers: {
    updateNotificationCount: (
      state: NotificationCountStateI,
      { payload }: PayloadAction<UpdateNotificationCountI>
    ) => {
      const lastCount = state.notificationItem[payload.itemName] || 0;
      state.notificationItem[payload.itemName] = payload.count;
      state.count += payload.count - lastCount;
    },
  },
});

export const notificationCountActions = notificationCountSlice.actions;
export default notificationCountSlice.reducer;

// NotificationCount
