import React from 'react';
import UnauthorizedComp from '../components/OtherComponents/Unauthorized';
import SuspenseLoadPage from '../components/OtherComponents/SuspenseLoadPage';

const ForbiddenAccess = () => {
  return (
    <SuspenseLoadPage>
      <UnauthorizedComp message='Something went wrong please contact system administrator.' />
    </SuspenseLoadPage>
  );
};

export default ForbiddenAccess;
