/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Card,
  FormField,
  TextInput,
} from '@sede-x/shell-ds-react-framework';
import { ArrowLeft } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { post as PostApi } from '../services/COPApi';

const StyledCard = styled(Card)`
  width: 50%;
  height: 60%;
  margin: auto;
`;
const TitleContainer = styled.div`
  display: flex;
  font-style: bold;
`;
const ArrowDiv = styled.div`
  display: flex;
`;
const TitleContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ActionBtns = styled.div`
  display: flex;
  justify-content: center;
`;

type FormValues = {
  name: string;
  description: string;
  isActive: string;
};

const OclAddOpportunity = () => {
  const navigate = useNavigate();
  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: '',
      isActive: 'true',
    },
    mode: 'onSubmit',
  });
  // To register form control with react hook form
  const { control, handleSubmit, formState, watch, reset } = form;
  // when user has filled in data, enable btn, use - isDirty
  const { errors, isDirty, isValid, isSubmitting, isSubmitSuccessful } =
    formState;

  const onSubmit = async (data: FormValues) => {
    const reqBody = {
      ...data,
      isActive: data.isActive === 'true',
    };

    try {
      const response = await PostApi(
        `/api/cop/CommercialOpportunity/Add`,
        reqBody
      );
      if (response.status === 200) {
        setSuccessAlert(true);
      }
    } catch (err) {
      setSuccessAlert(false);
    }
  };

  // to perform any sideeffect after value changes
  useEffect(() => {
    const subscription = watch(() => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <>
      {/* {loader && <Loader />} */}

      <StyledCard size='large'>
        <TitleContainer>
          <ArrowDiv>
            <span>
              <ArrowLeft
                width={20}
                height={20}
                onClick={() => navigate('/ocl/add-task/2')}
              />
            </span>
          </ArrowDiv>
          <TitleContent>
            <span>
              <h3> Add Opportunity </h3>
            </span>
          </TitleContent>
        </TitleContainer>
        {successAlert && (
          <Alert
            state='success'
            dismissible
            onDismissClick={() => {
              setSuccessAlert(false);
            }}
            style={{ marginBottom: '2%' }}
          >
            Opportunity created successfully!
          </Alert>
        )}
        {/* noValidate - to stop browser validation and react hook forms to take control of validation */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormField label='Flow/Asset'>
            <Controller
              control={control}
              name='name'
              rules={{
                required: 'Name is required',
              }}
              render={({ field: { value, onChange } }) => (
                <>
                  <TextInput
                    type='text'
                    id='name'
                    data-testid='name'
                    value={value}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </FormField>
          <p>{errors.name?.message}</p>

          <FormField label='Description'>
            <Controller
              control={control}
              name='description'
              rules={{
                required: 'Description is required',
                validate: (fieldValue) => {
                  return fieldValue.length > 0 || 'Enter valid description';
                },
              }}
              render={({ field: { value, onChange } }) => (
                <>
                  <TextInput
                    type='text'
                    id='description'
                    data-testid='description'
                    value={value}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </FormField>
          <p>{errors.description?.message}</p>
          {/* <FormField label='Status' size='small' style={{ display: 'block' }}>
            <Controller
              control={control}
              name='isActive'
              render={({ field }) => (
                <>
                  <Grid columns={3}>
                    <RadioButton
                      label='Active'
                      name='active'
                      value='active'
                      checked={!!field.value}
                      size='medium'
                      onChange={() => field.onChange(true)}
                    />
                    <StyledRadioBtn
                      checked={!!field.value === false}
                      label='Inactive'
                      name='inactive'
                      value='inactive'
                      size='medium'
                      onChange={() => field.onChange(false)}
                    />
                  </Grid>
                </>
              )}
            />
          </FormField>
          <p>{errors.isActive?.message}</p> */}
          <ActionBtns>
            <Button
              disabled={!isDirty || !isValid || isSubmitting}
              size='large'
            >
              Create Opportunity
            </Button>
            <Button
              size='large'
              type='button'
              onClick={() => navigate('/ocl/add-task/2')}
              style={{ marginLeft: '2%' }}
            >
              Cancel
            </Button>
          </ActionBtns>
        </form>
        {/* <DevTool control={control} /> */}
      </StyledCard>
    </>
  );
};
export default OclAddOpportunity;
