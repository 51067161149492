/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FullStateI } from '../../types/All.type';
import { getSubTerminals } from '../../store/UKOps/FieldNoms';
import { ISelectOptions } from '../../types/Common.type';

type GetSubTerminalsType = (getCall: boolean) => {
  loadingSubTerminals: boolean;
  subTerminals: ISelectOptions[];
};

const useGetSubTerminals: GetSubTerminalsType = (getCall = false) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.subTerminal
  );

  useEffect(() => {
    if (data.length === 0 && !loading && getCall) {
      dispatch(getSubTerminals());
    }
  }, [data, getCall]);

  return { loadingSubTerminals: loading, subTerminals: data };
};

export default useGetSubTerminals;
