import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FullStateI } from '../../types/All.type';
import routepaths from '../../router/routepaths';
import { fetchUserRole } from '../../store/User';

const Authenticate = () => {
  const userState = useSelector((state: FullStateI) => state.User);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userState.isFetching) {
      return;
    }
    if (userState.isAuthorized) {
      const path =
        sessionStorage.getItem('redirectPath') ?? routepaths.dashboard;
      sessionStorage.removeItem('redirectPath');
      navigate(path);
    } else if (userState.isAuthorized === null) {
      dispatch(fetchUserRole(userState.accessToken));
    } else {
      navigate(routepaths.unauthorized);
    }
  }, [userState, dispatch, navigate]);

  return <div>Authenticating..</div>;
};

export default Authenticate;
