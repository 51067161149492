import jwtDecode from 'jwt-decode';
import {
  TokenRequest,
  BaseTokenRequestHandler,
  GRANT_TYPE_REFRESH_TOKEN,
  AuthorizationServiceConfiguration,
  FetchRequestor,
} from '@openid/appauth';

import { UserInfoI } from '../../types/UserState.type';

interface AccessTokenRespI {
  user: null | UserInfoI;
  accessToken: string;
  error: string | null;
}

const getAccessTokenFromCodeRefreshToken = async (
  refreshToken: string | undefined
): Promise<AccessTokenRespI> => {
  return new Promise<AccessTokenRespI>((resolve) => {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

    const tokenRequest = new TokenRequest({
      client_id: process.env.REACT_APP_PING_CLIENT_ID ?? '',
      redirect_uri: process.env.REACT_APP_REDIRECT_URL ?? '',
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: undefined,
      refresh_token: refreshToken,
      extras: undefined,
    });

    AuthorizationServiceConfiguration.fetchFromIssuer(
      process.env.REACT_APP_TOKEN_PING_URL ?? '',
      new FetchRequestor()
    )
      .then((oResponse) =>
        tokenHandler.performTokenRequest(oResponse, tokenRequest)
      )
      .then((response) => {
        const decodedToken = jwtDecode<UserInfoI>(response.accessToken);
        const lastRefreshTime = new Date().toISOString().toString();

        sessionStorage.setItem('authenication', JSON.stringify(response));
        sessionStorage.setItem('access_token', response.accessToken);
        sessionStorage.setItem('expiry_time', decodedToken.exp.toString());
        sessionStorage.setItem('last_refresh_time', lastRefreshTime);
        sessionStorage.setItem('refresh_token', response.refreshToken || '');

        resolve({
          user: decodedToken,
          accessToken: response.accessToken,
          error: null,
        });
      })
      .catch((err) => {
        resolve({
          user: null,
          accessToken: '',
          error: err.message,
        });
      });
  });
};

export default getAccessTokenFromCodeRefreshToken;
