import React, { useState, useEffect } from 'react';
import {
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  FetchRequestor,
  AuthorizationRequest,
} from '@openid/appauth';

const Login = () => {
  const [message, setMessage] = useState('Logging in...');
  useEffect(() => {
    const authorizationHandler = new RedirectRequestHandler();
    AuthorizationServiceConfiguration.fetchFromIssuer(
      process.env.REACT_APP_TOKEN_PING_URL ?? '',
      new FetchRequestor()
    )
      .then((response) => {
        sessionStorage.setItem('token_endPoint', JSON.stringify(response));
        const authRequest = new AuthorizationRequest({
          client_id: process.env.REACT_APP_PING_CLIENT_ID ?? '',
          redirect_uri: process.env.REACT_APP_REDIRECT_URL ?? '',
          scope: 'openid email profile',
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          state: undefined,
        });
        authorizationHandler.performAuthorizationRequest(response, authRequest);
      })
      .catch((error) => {
        setMessage(error.message);
      });
  }, []);

  return <div>{message}</div>;
};
export default Login;
