import { useState, useEffect } from 'react';
import { get as FUApiGet } from '../services/COPApi';

type UseCOPGetApiType = <T>(
  path: string,
  defaultValue: T,
  refreshCount?: number
) => {
  isLoading: boolean;
  error: string | null;
  data: T;
};

const useCOPGetApi: UseCOPGetApiType = (
  path,
  defaultValue,
  refreshCount = 1
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    if (!path) {
      return;
    }
    setIsLoading(true);
    FUApiGet(path)
      .then((resp) => {
        setIsLoading(false);
        setError(null);
        setData(resp.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.toString());
      });
  }, [path, refreshCount]);
  return { isLoading, error, data };
};

export default useCOPGetApi;
