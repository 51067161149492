/* eslint-disable @typescript-eslint/no-implied-eval */

import {
  IConversionFormulaObj,
  IUnitDropdownOption,
  IFormulaVariableInfo,
  IVariableValue,
  IGetConversionResp,
  IFormulaType,
  IConversionFormulaSubmitObj,
  LastInputType,
} from '../types/ConversionCalculator.type';

export enum EFormulaType {
  NOMINATION_CALCULATOR = 4,
}

interface IdLookupI {
  [key: number]: boolean;
}

interface IDropDownOption {
  label: string;
  value: number;
}

export const getFromUnits = (
  units: IConversionFormulaObj[]
): IUnitDropdownOption[] => {
  const fromUnits: IUnitDropdownOption[] = [];
  const idLookup: IdLookupI = {};
  units.forEach((unit) => {
    if (!(unit.unitFromId in idLookup)) {
      fromUnits.push({
        id: unit.unitFromId,
        name: unit.unitFrom,
      });
      idLookup[unit.unitFromId] = true;
    }
  });
  return fromUnits;
};

export const getToUnits = (
  fromId: number,
  units: IConversionFormulaObj[]
): IUnitDropdownOption[] => {
  const toUnits: IUnitDropdownOption[] = [];
  units.forEach((unit) => {
    if (unit.unitFromId === fromId) {
      toUnits.push({
        id: unit.unitToId,
        name: unit.unitTo,
      });
    }
  });
  return toUnits;
};

export const getVariableInfoList = (
  formula: IConversionFormulaSubmitObj
): IFormulaVariableInfo[] => {
  const variableInfo: IFormulaVariableInfo[] = [];

  variableInfo.push({ name: formula.labelV1, value: formula.v1, varKey: 'v1' });

  if (formula.labelV2) {
    variableInfo.push({
      name: formula.labelV2,
      value: formula.v2,
      varKey: 'v2',
    });
  }
  if (formula.labelV3) {
    variableInfo.push({
      name: formula.labelV3,
      value: formula.v3,
      varKey: 'v3',
    });
  }
  if (formula.labelV4) {
    variableInfo.push({
      name: formula.labelV4,
      value: formula.v4,
      varKey: 'v4',
    });
  }
  if (formula.labelV5) {
    variableInfo.push({
      name: formula.labelV5,
      value: formula.v5,
      varKey: 'v5',
    });
  }
  if (formula.labelV6) {
    variableInfo.push({
      name: formula.labelV6,
      value: formula.v6,
      varKey: 'v6',
    });
  }
  return variableInfo;
};

const replacePercentages = (formula) => {
  // Regular expression to match percentages
  const percentageRegex = /(\d+(\.\d+)?)%/g;

  // Replace each percentage with its (value / 100)
  const updatedFormula = formula.replace(percentageRegex, (match, p1) => {
    return `(${p1} / 100)`;
  });

  return updatedFormula;
};

export const getConversion = (
  variableValue: IVariableValue,
  formula: IConversionFormulaSubmitObj
): IGetConversionResp => {
  const formulaVariableInfo = getVariableInfoList(formula);
  let error = false;
  let msg = '';
  formulaVariableInfo.forEach((varInfo) => {
    if (
      !error &&
      (!(varInfo.varKey in variableValue) ||
        variableValue[varInfo.varKey] === null)
    ) {
      error = true;
      msg = `${varInfo.name} cannot be empty.`;
    }
  });
  if (error) {
    return {
      success: false,
      error: msg,
      output: 0,
    };
  }
  const V1 = variableValue.v1 || '';
  const V2 = variableValue.v2 || '';
  const V3 = variableValue.v3 || '';
  const V4 = variableValue.v4 || '';
  const V5 = variableValue.v5 || '';
  const V6 = variableValue.v6 || '';

  let newFormula = replacePercentages(formula.formula);
  newFormula = newFormula.replaceAll('V1', V1.toString());
  newFormula = newFormula.replaceAll('V2', V2.toString());
  newFormula = newFormula.replaceAll('V3', V3.toString());
  newFormula = newFormula.replaceAll('V4', V4.toString());
  newFormula = newFormula.replaceAll('V5', V5.toString());
  newFormula = newFormula.replaceAll('V6', V6.toString());

  return {
    success: true,
    error: '',
    output: Function(`return ${newFormula}`)(),
  };
};

export const getFormulaTypeToOption = (
  data: IFormulaType[]
): IDropDownOption[] => {
  return data.map((formulaType) => ({
    label: formulaType.type,
    value: formulaType.id,
  }));
};

export const getVariableCount = (data: IConversionFormulaSubmitObj) => {
  const keyList = ['labelV2', 'labelV3', 'labelV4', 'labelV5', 'labelV6'];
  let varCount = 1;
  keyList.forEach((key, index) => {
    if (data[key]) {
      varCount = index + 2;
    }
  });
  return varCount;
};

export const getFormulaForDisplay = (data: IConversionFormulaSubmitObj) => {
  let newFormula = data.formula;
  newFormula = newFormula.replaceAll('V1', `{${data.labelV1}}`);
  newFormula = newFormula.replaceAll('V2', `{${data.labelV2}}`);
  newFormula = newFormula.replaceAll('V3', `{${data.labelV3}}`);
  newFormula = newFormula.replaceAll('V4', `{${data.labelV4}}`);
  newFormula = newFormula.replaceAll('V5', `{${data.labelV5}}`);
  newFormula = newFormula.replaceAll('V6', `{${data.labelV6}}`);
  return newFormula;
};

export const isValidFormula = (data: IConversionFormulaSubmitObj) => {
  const variableValue: IVariableValue = {
    v1: data.v1,
    v2: data.v2,
    v3: data.v3,
    v4: data.v4,
    v5: data.v5,
    v6: data.v6,
  };

  const resp = getConversion(variableValue, data);
  return (
    resp.success && !Number.isNaN(resp.output) && Number.isFinite(resp.output)
  );
};

export const getLastInput = (formula: string): LastInputType => {
  if (!formula) {
    return '';
  }
  const last2 = formula.substring(formula.length - 2);
  const varList = ['V1', 'V2', 'V3', 'V4', 'V5', 'V6'];

  if (varList.includes(last2)) {
    return 'VARIABLE';
  }
  const last = formula.substring(formula.length - 1);

  switch (last) {
    case '(':
      return 'OPEN_BRACKET';
    case ')':
      return 'CLOSE_BRACKET';
    case '/':
    case '*':
    case '+':
    case '-':
      return 'OPERATOR';
    case '.':
      return 'POINT';
    default:
      return 'NUMBER';
  }
};
export const canAddPoint = (formula: string) => {
  // True: '', 'OPEN_BRACKET', 'OPERATOR'
  // False: 'CLOSE_BRACKET', 'POINT', 'VARIABLE'
  // Check: 'NUMBER'
  const lastInput = getLastInput(formula);

  if (
    formula === 'CLOSE_BRACKET' ||
    formula === 'POINT' ||
    formula === 'VARIABLE'
  ) {
    return false;
  }
  const trueList = ['(', '/', '*', '+', '-'];
  if (lastInput === 'NUMBER') {
    let res = true;
    let continueCheck = true;
    Array.from(formula)
      .reverse()
      .forEach((char) => {
        if (continueCheck) {
          if (char === '.') {
            res = false;
            continueCheck = false;
          } else if (trueList.includes(char)) {
            res = true;
            continueCheck = false;
          }
        }
      });
    return res;
  }
  return true;
};

export const getRoundedNum = (num: number, decimals: number) => {
  let multiplayer = 100;
  for (let i = 2; i < decimals; i += 1) {
    multiplayer *= 10;
  }

  return Math.round((num + Number.EPSILON) * multiplayer) / multiplayer;
};
