import React from 'react';
import SuspenseLoadPage from '../../components/OtherComponents/SuspenseLoadPage';

const TradedGasVolumeHistoryComp = React.lazy(
  () => import('../../components/ChartsComp/TradedGasVolumeHistory')
);

const TradedGasVolumeHistory = () => {
  return (
    <SuspenseLoadPage>
      <TradedGasVolumeHistoryComp />
    </SuspenseLoadPage>
  );
};

export default TradedGasVolumeHistory;
