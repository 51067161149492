import React from 'react';
import { useParams } from 'react-router-dom';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const OclTasksComp = React.lazy(
  () => import('../components/OpsChecklist/Tasks')
);

const OclTasks: React.FC = () => {
  const { teamId = '' } = useParams();
  let pageName = applicationName.checkListShift;
  if (teamId === '2') {
    pageName = applicationName.checklistShiftWholesaleGas;
  } else if (teamId === '3') {
    pageName = applicationName.checklistShiftWholesalePower;
  } else if (teamId === '4') {
    pageName = applicationName.checklistPowerShift;
  } else if (teamId === '5') {
    pageName = applicationName.checklistRenewableShift;
  } else if (teamId === '9') {
    pageName = applicationName.checklistTradeControl;
  } else if (teamId === '10') {
    pageName = applicationName.checklistConfirmations;
  }

  return (
    <CheckAuthorizationWithSuspense pageName={pageName}>
      <OclTasksComp roleName={pageName} />
    </CheckAuthorizationWithSuspense>
  );
};

export default OclTasks;
