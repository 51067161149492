import React, { useCallback } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import theme from 'styled-theming';

interface DatePickerCompI {
  placeholder?: string;
  value: Moment | null;
  onChange: (date: Moment) => void;
  onInput?: (e: React.FocusEvent<HTMLInputElement>) => void; // Make onBlur optional
  min?: string;
  max?: string;
}

const invert = theme('name', {
  light: 0,
  dark: 1,
});

const DatePickerInput = styled.input`
  width: 130px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  font-style: normal;
  color: #000;
  background-color: transparent;
  filter: invert(${invert});
`;

const DatePicker: React.FC<DatePickerCompI> = ({
  value,
  onChange,
  placeholder,
  min,
  max,
  onInput,
}) => {
  const updateDate = useCallback(
    (e) => {
      onChange(moment(e.target.value));
    },
    [onChange]
  );
  const updateDateBlur = useCallback(
    (e) => {
      onInput?.(e);
    },
    [onInput]
  );

  return (
    <DatePickerInput
      type='date'
      value={value?.format('YYYY-MM-DD')}
      placeholder={placeholder}
      onChange={updateDate}
      min={min}
      max={max}
      data-testid='datePicker'
      onInput={updateDateBlur}
    />
  );
};
DatePicker.defaultProps = {
  placeholder: 'YYYY-MM-DD',
  min: '2000-01-01',
  max: '3000-01-01',
  onInput: () => {}, // Provide a default empty function for onBlur
};
export default DatePicker;
