import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FullStateI } from '../../types/All.type';

const RequireAuth = () => {
  const userState = useSelector((state: FullStateI) => state.User);
  const location = useLocation();

  if (userState.isAuthorized) {
    return <Outlet />;
  }
  sessionStorage.setItem('redirectPath', location.pathname);

  return <Navigate to='/' replace />;
};

export default RequireAuth;
