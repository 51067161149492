/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  KeyDataSourceList,
  DataSourceStateI,
} from '../../types/OpsChecklist.type';
import { get as GetApi } from '../../services/COPApi';
import { FullStateI } from '../../types/All.type';

const initialState: DataSourceStateI = {
  isFetching: false,
  error: null,
  list: [],
};

export const fetchDataSource = createAsyncThunk(
  'dataSource/fetchDataSource',
  async () =>
    GetApi('/api/cop/KeyDataSource/GetAllKeyDataSource').then((resp) => {
      return resp.data;
    })
);

export const fetchDataSourceOnce = createAsyncThunk(
  'dataSource/fetchDataSourceOnce',
  async (_, { getState, dispatch }) =>
    Promise.resolve().then(() => {
      const state = getState() as FullStateI;
      const dataSource = state.CLDataSource;

      if (!dataSource.isFetching && dataSource.list.length === 0) {
        dispatch(fetchDataSource());
      }
      return true;
    })
);

const dataSourceSlice = createSlice({
  name: 'dataSource',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataSource.pending, (state: DataSourceStateI) => {
        state.isFetching = true;
        state.error = null;
        state.list = [];
      })
      .addCase(
        fetchDataSource.fulfilled,
        (
          state: DataSourceStateI,
          { payload }: PayloadAction<KeyDataSourceList>
        ) => {
          state.isFetching = false;
          state.error = null;
          state.list = payload;
        }
      )
      .addCase(fetchDataSource.rejected, (state: DataSourceStateI) => {
        state.isFetching = false;
        state.error = 'Error';
        state.list = [];
      });
  },
});

export const CLDataSourceActions = dataSourceSlice.actions;
export default dataSourceSlice.reducer;
