import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';

import SideNavMenu from './SideNavMenu';

interface ISideNavContainer {
  show: string;
}

interface ISideNavProps {
  showSideNav: boolean;
}

export const backgroundColor = theme('name', {
  light: '#fff',
  dark: 'rgba(30,36,43,1)',
});

// width: ${(props) => (props.show ? '215px' : '0px')};
const SideNavContainer = styled.div<ISideNavContainer>`
  position: absolute;
  width: 215px;
  height: 100%;
  left: 0;
  background-color: ${backgroundColor};
  overflow-x: hidden;
  padding-top: 15px;
  box-sizing: border-box;
  transform: ${(prors) => prors.show};
  transition: all 0.3s;
`;

const SideNav: React.FC<ISideNavProps> = ({ showSideNav }) => {
  return (
    <SideNavContainer
      show={
        showSideNav
          ? 'translate3d(0px, 0px, 0px)'
          : 'translate3d(-235px, 0px, 0px)'
      }
    >
      <SideNavMenu />
    </SideNavContainer>
  );
};

export default SideNav;
