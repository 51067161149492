import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const UserAdminUserRoleComp = React.lazy(
  () => import('../../components/UserAdministration/UserAdminUserRole')
);

const UserAdminUser = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.userAdministration}
    >
      <UserAdminUserRoleComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default UserAdminUser;
