import jwtDecode from 'jwt-decode';
import {
  TokenRequest,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  AuthorizationNotifier,
  FetchRequestor,
  LocalStorageBackend,
  DefaultCrypto,
} from '@openid/appauth';

import NoHashQueryStringUtils from '../../constants/pingConfiguration/NoHashQueryStringUtils';
import { UserInfoI } from '../../types/UserState.type';

type ExtrasType =
  | undefined
  | {
      code_verifier: string;
    };

interface AccessTokenRespI {
  user: null | UserInfoI;
  accessToken: string;
  error: string | null;
}

const getAccessTokenFromCode = async (
  code: string | undefined
): Promise<AccessTokenRespI> => {
  return new Promise<AccessTokenRespI>((resolve) => {
    // Or reject() if something wrong happened

    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );
    const notifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request, resp) => {
      if (resp) {
        let extras: ExtrasType;
        if (request && request.internal) {
          extras = { code_verifier: request.internal.code_verifier };
        }
        const tokenRequest = new TokenRequest({
          client_id: process.env.REACT_APP_PING_CLIENT_ID ?? '',
          redirect_uri: process.env.REACT_APP_REDIRECT_URL ?? '',
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code,
          refresh_token: undefined,
          extras,
        });
        AuthorizationServiceConfiguration.fetchFromIssuer(
          process.env.REACT_APP_TOKEN_PING_URL ?? '',
          new FetchRequestor()
        )
          .then((oResponse) =>
            tokenHandler.performTokenRequest(oResponse, tokenRequest)
          )
          .then((response) => {
            const decodedToken = jwtDecode<UserInfoI>(response.accessToken);
            const lastLoggedInTime = new Date().toISOString().toString();

            sessionStorage.setItem('authenication', JSON.stringify(response));
            sessionStorage.setItem('access_token', response.accessToken);
            sessionStorage.setItem('expiry_time', decodedToken.exp.toString());
            sessionStorage.setItem('last_loggedin_time', lastLoggedInTime);
            sessionStorage.setItem(
              'refresh_token',
              response.refreshToken || ''
            );

            resolve({
              user: decodedToken,
              accessToken: response.accessToken,
              error: null,
            });
          })
          .catch((err) => {
            resolve({
              user: null,
              accessToken: '',
              error: err.message,
            });
          });
      } else {
        resolve({
          user: null,
          accessToken: '',
          error: 'Error',
        });
      }
    });
    authorizationHandler.completeAuthorizationRequestIfPossible();
  });
};

export default getAccessTokenFromCode;
