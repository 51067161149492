/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IChecklistStore } from '../../types/OpsChecklist.type';
import { get } from '../../services/COPApi';

const initialState: IChecklistStore = {
  countriesForTeam: {
    isLoading: false,
    data: [],
  },
};

export const getAllCountries = createAsyncThunk(
  'opsChecklist/getAllCountries',
  async () => {
    return get(`/api/cop/Country/getAllCountries`).then((resp) => {
      return resp.data;
    });
  }
);

const opsChecklistSlice = createSlice({
  name: 'opsChecklist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state: IChecklistStore) => {
        state.countriesForTeam.isLoading = true;
        state.countriesForTeam.data = [];
      })
      .addCase(
        getAllCountries.fulfilled,
        (state: IChecklistStore, action: PayloadAction<any>) => {
          state.countriesForTeam.isLoading = false;
          state.countriesForTeam.data = action.payload;
        }
      )
      .addCase(getAllCountries.rejected, (state: IChecklistStore) => {
        state.countriesForTeam.isLoading = false;
        state.countriesForTeam.data = [];
      });
  },
});

export const opsChecklistActions = opsChecklistSlice.actions;
export default opsChecklistSlice.reducer;
