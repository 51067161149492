import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FullStateI } from '../types/All.type';
import { userRole } from '../constants/Auth';

export default function IsReadOnly({ appName }) {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const userState = useSelector((state: FullStateI) => state.User);

  useEffect(() => {
    setIsReadOnly(userState.userRole[appName] === userRole.readOnly);
  }, [appName, userState.userRole]);

  return isReadOnly;
}
