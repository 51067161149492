import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const DashboardComp = React.lazy(
  () => import('../../components/StoragePortfolio/dashboard')
);

const DealParametersComp = React.lazy(
  () => import('../../components/StoragePortfolio/DealParameters')
);

const FacilityOverviewComp = React.lazy(
  () => import('../../components/StoragePortfolio/FacilityOverview')
);

const FacilityAttachmentComp = React.lazy(
  () =>
    import('../../components/StoragePortfolio/FacilityOverview/AddAttachment')
);

const DynamicTableComp = React.lazy(
  () => import('../../components/StoragePortfolio/dashboard/DynamicTable')
);

const FacilityCompareComp = React.lazy(
  () =>
    import('../../components/StoragePortfolio/FacilityOverview/facilityCompare')
);

const DealFacilityAttachmentComp = React.lazy(
  () => import('../../components/StoragePortfolio/DealParameters/AddAttachment')
);

type PSMainProps =
  | 'dashboard'
  | 'deal-parameters'
  | 'facilityOverview'
  | 'facilityAttachment'
  | 'dynamicTable'
  | 'facilityCompare'
  | 'dealFacilityAttachment';

interface StoragePortfolioMainCompProps {
  type: PSMainProps;
}

const StoragePortfolioMainComp: React.FC<StoragePortfolioMainCompProps> = ({
  type,
}) => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.storagePortfolio}>
      {type === 'dashboard' && <DashboardComp />}
      {type === 'deal-parameters' && <DealParametersComp />}
      {type === 'facilityOverview' && <FacilityOverviewComp />}
      {type === 'facilityAttachment' && <FacilityAttachmentComp />}
      {type === 'dynamicTable' && <DynamicTableComp />}
      {type === 'facilityCompare' && <FacilityCompareComp />}
      {type === 'dealFacilityAttachment' && <DealFacilityAttachmentComp />}
    </CheckAuthorizationWithSuspense>
  );
};

export default StoragePortfolioMainComp;
