/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICategory {
  id: number;
  name: string;
  category: string;
}

interface IMandate {
  id: number;
  maxwc: number;
  roace: number;
  utcTime: string;
  volumeMandate: number;
  year: number;
}

interface IFacilityInfo {
  id: number;
  name: string;
}

export interface ICountryInfo {
  countryName: string;
  countryId: number;
  facilities: IFacilityInfo[];
}

interface ICurrentMandateInfo {
  year: number | undefined;
  mandate: number | undefined;
  total: number | undefined;
  roace: number | undefined;
  maxwc: number | undefined;
  avgRoace: number | undefined;
  totalMaxWc: number | undefined;
}

export interface ICommonData {
  country: ICategory[];
  dealTypes: ICategory[];
  Year: ICategory[];
  unitOfMeasures: ICategory[];
  mandateList: IMandate[];
  currentMandateYearInfo: ICurrentMandateInfo;
  facilityInfo: ICountryInfo[];
  facilities: ICategory[];
}

export interface ICommonPayload {
  countries: ICategory[];
  years: ICategory[];
  dealTypes: ICategory[];
  unitOfMeasures: ICategory[];
  facilities: ICategory[];
}

export interface ICountryPayload {
  country: ICategory[];
}

export interface ICommonFacilitiesPayload {
  facilities: ICategory[];
}

const initialState: ICommonData = {
  country: [],
  dealTypes: [],
  Year: [],
  unitOfMeasures: [],
  mandateList: [],
  facilities: [],
  currentMandateYearInfo: {
    year: undefined,
    mandate: undefined,
    total: undefined,
    roace: undefined,
    maxwc: undefined,
    avgRoace: undefined,
    totalMaxWc: undefined,
  },
  facilityInfo: [],
};

const StoragePortFolio = createSlice({
  name: 'storagePortfolio',
  initialState,
  reducers: {
    updateCommonData: (state, { payload }: PayloadAction<ICommonPayload>) => {
      state.country = payload.countries;
      state.dealTypes = payload.dealTypes;
      state.Year = payload.years;
      state.unitOfMeasures = payload.unitOfMeasures;
      // state.facilities = payload.facilities;
    },
    updateCountryData: (state, { payload }: PayloadAction<ICountryPayload>) => {
      state.country = payload.country;
    },
    updateFacilitiesData: (
      state,
      { payload }: PayloadAction<ICommonFacilitiesPayload>
    ) => {
      state.facilities = payload.facilities;
    },
    updateMandateList: (state, { payload }: PayloadAction<IMandate[]>) => {
      state.mandateList = payload;
    },
    updateCurrentMandateYearInfo: (
      state,
      { payload }: PayloadAction<ICurrentMandateInfo>
    ) => {
      state.currentMandateYearInfo = payload;
    },
    addUpdateCurrentMandateYearInfo: (state, { payload }) => {
      const { year, ...updatedFields } = payload;
      state.currentMandateYearInfo = {
        ...state.currentMandateYearInfo,
        ...updatedFields,
      };

      if (year !== undefined) {
        state.mandateList = state.mandateList.map((mandate) => {
          if (mandate.year === year) {
            return {
              ...mandate,
              ...updatedFields,
            };
          }
          return mandate;
        });
      }
    },
    updateFacilityInfo: (state, { payload }: PayloadAction<ICountryInfo[]>) => {
      state.facilityInfo = payload;
    },
  },
});

export const storagePortfolioActions = StoragePortFolio.actions;
export default StoragePortFolio.reducer;
