import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FullStateI } from '../../types/All.type';
import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName, userRole } from '../../constants/Auth';

const CounterpartyViewContact = React.lazy(
  () =>
    import('../../components/ContactList/Counterparty/CounterpartyViewContact')
);

const ContactListView = () => {
  const { market = '' } = useParams();
  const userState = useSelector((state: FullStateI) => state.User);
  const canEdit =
    userState.userRole[applicationName.contactList] === userRole.readWrite;

  let content: ReactElement | null = null;

  if (market === 'counterparty') {
    content = <CounterpartyViewContact canEdit={canEdit} />;
  }

  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.contactList}>
      {content}
    </CheckAuthorizationWithSuspense>
  );
};

export default ContactListView;
