import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const CnCalcConvertCalc = React.lazy(
  () => import('../../components/ConversionCalculator/CnCalcConvert')
);

const CnCalcConvert = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.conversionCalc}>
      <CnCalcConvertCalc />
    </CheckAuthorizationWithSuspense>
  );
};

export default CnCalcConvert;
