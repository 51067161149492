import React from 'react';
import { useParams } from 'react-router-dom';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';
import { CHECKLIST_TYPE_ID } from '../../constants/WstChecklist';

const WstAddTaskComp = React.lazy(
  () => import('../../components/OpsChecklist/WstChecklist/WstAddTask')
);

interface IWstAddTaskProps {
  checklistTypeId: number;
}

const WstAddTask: React.FC<IWstAddTaskProps> = ({ checklistTypeId }) => {
  const { teamId = '' } = useParams();
  let updatedChecklistTypeId = checklistTypeId;

  let pageName = applicationName.checklistShiftWholesalePower;
  if (teamId === '1') {
    updatedChecklistTypeId = 4;
    pageName = applicationName.checkListShift;
  } else if (teamId === '2') {
    pageName = applicationName.checklistShiftWholesaleGas;
  } else if (teamId === '5') {
    pageName = applicationName.checklistRenewableShift;
    updatedChecklistTypeId = 5;
  } else if (teamId === '6') {
    pageName = applicationName.checklistPowerPortfolio;
    if (checklistTypeId === CHECKLIST_TYPE_ID.MONTHLY_SETUP_TASK) {
      updatedChecklistTypeId = 6;
    }
    // else updatedChecklistTypeId = checklistTypeId;
  } else if (teamId === '7') {
    updatedChecklistTypeId = 8;
    pageName = applicationName.checklistDealAcualisation;
  }
  else if (teamId === '10') {
    updatedChecklistTypeId = 10;
    pageName = applicationName.checklistConfirmations;
  }
  return (
    <CheckAuthorizationWithSuspense
      pageName={pageName}
      // role={userRole.readWrite}
    >
      <WstAddTaskComp checklistTypeId={updatedChecklistTypeId} />
    </CheckAuthorizationWithSuspense>
  );
};

export default WstAddTask;
