/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ManualSendRecieveProcessList,
  ManualSendRecieveProcessStateI,
} from '../../types/OpsChecklist.type';
import {
  get as GetApi,
  post as PostApi,
  put as PutApi,
  deleteApi as DeleteApi,
} from '../../services/COPApi';
import { FullStateI } from '../../types/All.type';

interface AddManualProcess {
  name: string;
  colorHexCode: string;
}
interface EditManualProcess extends AddManualProcess {
  id: number;
}

const initialState: ManualSendRecieveProcessStateI = {
  isSubmitting: false,
  isFetching: false,
  error: null,
  list: [],
};

export const fetchManualProcess = createAsyncThunk(
  'manualProcess/fetchManualProcess',
  async () =>
    GetApi(
      '/api/cop/ManualSendReceiveProcess/GetAllManualSendReceiveProcess'
    ).then((resp) => {
      return resp.data;
    })
);
export const fetchManualProcessOnce = createAsyncThunk(
  'manualProcess/fetchManualProcessOnce',
  async (_, { getState, dispatch }) =>
    Promise.resolve().then(() => {
      const state = getState() as FullStateI;
      const manualProcess = state.CLManualProcess;

      if (!manualProcess.isFetching && manualProcess.list.length === 0) {
        dispatch(fetchManualProcess());
      }
      return true;
    })
);

export const addManualProcess = createAsyncThunk(
  'manualProcess/addManualProcess',
  async (data: AddManualProcess, { dispatch }) =>
    PostApi(
      '/api/cop/ManualSendReceiveProcess/NewManualSendReceiveProcess',
      data
    ).then((resp) => {
      dispatch(fetchManualProcess());
      return resp.data;
    })
);

export const editManualProcess = createAsyncThunk(
  'manualProcess/editManualProcess',
  async (data: EditManualProcess, { dispatch }) =>
    PutApi(
      '/api/cop/ManualSendReceiveProcess/UpdateManualSendReceiveProcess',
      data
    ).then((resp) => {
      dispatch(fetchManualProcess());
      return resp.data;
    })
);

export const deleteManualProcess = createAsyncThunk(
  'manualProcess/deleteManualProcess',
  async (data: EditManualProcess, { dispatch }) => {
    DeleteApi(
      '/api/cop/ManualSendReceiveProcess/DeleteManualSendReceiveProcess',
      data
    ).then((resp) => {
      dispatch(fetchManualProcess());
      return resp.data;
    });
  }
);

const manualProcessSlice = createSlice({
  name: 'manualProcess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchManualProcess.pending,
        (state: ManualSendRecieveProcessStateI) => {
          state.isFetching = true;
          state.error = null;
          state.list = [];
        }
      )
      .addCase(
        fetchManualProcess.fulfilled,
        (
          state: ManualSendRecieveProcessStateI,
          action: PayloadAction<ManualSendRecieveProcessList>
        ) => {
          state.isFetching = false;
          state.error = null;
          state.list = action.payload;
        }
      )
      .addCase(
        fetchManualProcess.rejected,
        (state: ManualSendRecieveProcessStateI) => {
          state.isFetching = false;
          state.error = 'Error';
          state.list = [];
        }
      )
      .addCase(
        addManualProcess.pending,
        (state: ManualSendRecieveProcessStateI) => {
          state.isSubmitting = true;
        }
      )
      .addCase(
        addManualProcess.fulfilled,
        (state: ManualSendRecieveProcessStateI) => {
          state.isSubmitting = false;
        }
      )
      .addCase(
        addManualProcess.rejected,
        (state: ManualSendRecieveProcessStateI) => {
          state.isSubmitting = false;
        }
      )
      .addCase(
        editManualProcess.pending,
        (state: ManualSendRecieveProcessStateI) => {
          state.isSubmitting = true;
        }
      )
      .addCase(
        editManualProcess.fulfilled,
        (state: ManualSendRecieveProcessStateI) => {
          state.isSubmitting = false;
        }
      );
  },
});

export const CLManualProcessActions = manualProcessSlice.actions;
export default manualProcessSlice.reducer;
