import styled from 'styled-components';
import { Drawer } from '@sede-x/shell-ds-react-framework';

export const MainMenuContainer = styled.div`
  background-color: ${(prop) => prop.color};
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%), 0 0 2px rgb(0 0 0 / 10%);
  position: sticky;
  top: 0;
  z-index: 999;

  header {
    box-shadow: unset;
  }

  .environment-wrapper {
    color: rgba(54, 61, 68, 1);
    font-weight: 600;
    font-size: 17px;
    background-color: #fbce07;
    padding: 10px 15px;
    border-radius: 15px;
    align-self: center;
  }
`;

export const Area1Container = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  svg:first-child path {
    fill: ${(prop) => prop.color};
  }
`;

export const ThemeWrapper = styled.div`
  display: flex;
  margin: 0 10px;
  cursor: pointer;

  span {
    font-weight: 400;
    font-size: 16px;
    :first-child {
      margin-right: 10px;
    }
  }
`;

export const NotificationDrawer = styled(Drawer)`
  .shell-drawer-content > div:last-child {
    padding: 0 20px;
  }
`;
