/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { get, post } from '../../services/COPApi';
import { ISelectOptions } from '../../types/Common.type';
import {
  GET_ALL_COUNTER_PARTY,
  UPLOAD_TO_ALIGNE,
} from '../../constants/BeachSwaps';
import {
  IBeachSwap,
  IUploadToAlignePayload,
} from '../../types/BeachSwaps/index.type';

interface ISelectField {
  loading: boolean;
  data: ISelectOptions[];
}

const selectInitialData = {
  loading: false,
  data: [],
};

export interface IBeachSwapState {
  counterParties: ISelectField;
  uploadToAligne: {
    loading: boolean;
    uploadStatus: 'Success' | 'Failed' | null;
  };
}

const initialState: IBeachSwapState = {
  counterParties: selectInitialData,
  uploadToAligne: {
    loading: false,
    uploadStatus: null,
  },
};

export const getCounterParties = createAsyncThunk(
  'beachSwapNoms/getAllCounterParties',
  async () =>
    get(GET_ALL_COUNTER_PARTY).then((resp) => {
      return resp.data;
    })
);

export const postUploadToAligne = createAsyncThunk(
  'beachSwapNoms/postUploadToAligne',
  async (payload: IUploadToAlignePayload[]) => {
    return post(UPLOAD_TO_ALIGNE, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        throw error;
      });
  }
);

const beachSwapSlice = createSlice({
  name: 'beachSwapNoms',
  initialState,
  reducers: {
    clearUploadResponse: (state) => {
      state.uploadToAligne.loading = false;
      state.uploadToAligne.uploadStatus = null;
    },
  },
  extraReducers: (builder) => {
    // Counter Parties
    builder
      .addCase(getCounterParties.pending, (state: IBeachSwapState) => {
        state.counterParties.loading = true;
        state.counterParties.data = [];
      })
      .addCase(
        getCounterParties.fulfilled,
        (
          state: IBeachSwapState,
          { payload }: PayloadAction<ISelectOptions[]>
        ) => {
          state.counterParties.loading = false;
          state.counterParties.data = payload;
        }
      )
      .addCase(getCounterParties.rejected, (state: IBeachSwapState) => {
        state.counterParties.loading = false;
        state.counterParties.data = [];
      });

    builder
      .addCase(postUploadToAligne.pending, (state: IBeachSwapState) => {
        state.uploadToAligne.loading = true;
        state.uploadToAligne.uploadStatus = null;
      })
      .addCase(
        postUploadToAligne.fulfilled,
        (state: IBeachSwapState, { payload }: PayloadAction<IBeachSwap[]>) => {
          state.uploadToAligne.loading = false;
          state.uploadToAligne.uploadStatus = 'Success';
        }
      )
      .addCase(postUploadToAligne.rejected, (state: IBeachSwapState) => {
        state.uploadToAligne.loading = false;
        state.uploadToAligne.uploadStatus = 'Failed';
      });
  },
});

export const BeachSwapActions = beachSwapSlice.actions;
export default beachSwapSlice.reducer;
