import { configureStore } from '@reduxjs/toolkit';

import { FullStateI } from '../types/All.type';
import ApplicationDataReducer from './Application';
import BeachSwapReducer from './BeachSwap';
import OpsChecklistReducer from './Checklist';
import CLDataSourceReducer from './Checklist/CLDataSource';
import CLManualProcessReducer from './Checklist/CLManualProcesses';
import CLPendingTaskReducer from './Checklist/CLPendingTask';
import CLShiftNameReducer from './Checklist/CLShiftName';
import TradeControlReducer from './Checklist/TradeControlLogs';
import FNReconcileReducer from './FNReconcile';
import GTOLogsReducer from './GTOLogs';
import IBTReducer from './IBT';
import MiscellaneousDataSlice from './Miscellaneous';
import NotificationCountReducer from './NotificationCount';
import Theme from './Theme';
import Toggle from './Toggle';
import FieldNomsReducer from './UKOps/FieldNoms';
import UserReducer from './User';
import PTOLogsReducer from './ptoLogs';
import StoragePortfolioReducer from './storagePortfolio/common';

const store = configureStore<FullStateI>({
  reducer: {
    OpsChecklist: OpsChecklistReducer,
    CLDataSource: CLDataSourceReducer,
    CLManualProcess: CLManualProcessReducer,
    CLShiftName: CLShiftNameReducer,
    CLPendingTask: CLPendingTaskReducer,
    User: UserReducer,
    NotificationCount: NotificationCountReducer,
    Miscellaneous: MiscellaneousDataSlice,
    Theme,
    Toggle,
    GTOLogs: GTOLogsReducer,
    PTOLogs: PTOLogsReducer,
    ApplicationData: ApplicationDataReducer,
    TradeControl: TradeControlReducer,
    FieldNoms: FieldNomsReducer,
    StoragePortFolio: StoragePortfolioReducer,
    BeachSwap: BeachSwapReducer,
    IBT: IBTReducer,
    FNReconcile: FNReconcileReducer,
  },
});

export default store;
