import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';
import { LOCATION_ID } from '../../constants/CredentialsStore';

const EditCredentials = React.lazy(
  () => import('../../components/CredentialsStore/EditCredentials')
);

interface ICredStoreEditProps {
  locationId: number;
}

const CredStoreEdit: React.FC<ICredStoreEditProps> = ({ locationId }) => {
  const pageName =
    locationId === LOCATION_ID.SEEL
      ? applicationName.credentialStore
      : applicationName.senaPasswordHub;

  return (
    <CheckAuthorizationWithSuspense
      pageName={pageName}
      // role={userRole.readWrite}
    >
      <EditCredentials locationId={locationId} />
    </CheckAuthorizationWithSuspense>
  );
};

export default CredStoreEdit;
