import React from 'react';
import routePaths from './routepaths';

import PositionsCardComp from '../pages/positionshandover/PositionsCard';
import MySteersComp from '../pages/positionshandover/MySteers';
import OperatorTradesComp from '../pages/positionshandover/OperatorTrades';
import SummaryViewComp from '../pages/positionshandover/SummaryView';
import SettingsComp from '../pages/positionshandover/Settings';

import IAppRoute from './AppRoute.type';

const PositionHandoverRoutes: IAppRoute[] = [
  {
    path: routePaths.positionHandoverPositionsCard,
    element: <PositionsCardComp />,
  },
  {
    path: routePaths.positionHandoverMySteers,
    element: <MySteersComp />,
  },
  {
    path: routePaths.positionHandoverOperatorView,
    element: <OperatorTradesComp />,
  },
  {
    path: routePaths.positionHandoverSummary,
    element: <SummaryViewComp />,
  },
  {
    path: routePaths.positionHandoverSettings,
    element: <SettingsComp />,
  },
];

export default PositionHandoverRoutes;
