import React from 'react';
import routepaths from './routepaths';
import PrismaCalendar from '../pages/PrismaCalendar';
import IAppRoute from './AppRoute.type';

const CMTRoutes: IAppRoute[] = [
  { path: routepaths.prismaCalendar, element: <PrismaCalendar /> },
  { path: routepaths.auctionDetails, element: <PrismaCalendar /> },
];

export default CMTRoutes;
