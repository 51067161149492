/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import React from 'react';
import { Option, Select } from '@sede-x/shell-ds-react-framework';
import { ISelectProps } from '@sede-x/shell-ds-react-framework/build/esm/components/Select/Select.types';
import { Controller } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage';
import {
  IMemoisedComponentProp,
  ISelectOptions,
} from '../../types/Common.type';

interface IFormControlSelect extends IMemoisedComponentProp {
  name: string;
  label: string;
  required: boolean | string;
  isLoading?: boolean;
  data: ISelectOptions[];
  selectProps?: ISelectProps;
}

const FormControlSelect: React.FC<IFormControlSelect> = ({
  methods,
  name,
  required,
  label,
  isLoading,
  data,
  selectProps,
}) => {
  const {
    formState: { errors },
    control,
  } = methods;

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value } }) => (
          <div>
            <span>{label}</span>
            <Select
              placeholder={`Select ${label}`}
              allowClear={false}
              onChange={onChange}
              value={value}
              invalid={!!errors[name]}
              loading={isLoading}
              {...selectProps}
            >
              {data.map((item) => (
                <Option key={`${item.id}_${item.name}`} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            {errors[name] && errors[name]?.message && (
              <ErrorMessage>{errors[name]?.message}</ErrorMessage>
            )}
          </div>
        )}
      />
    </>
  );
};
export default FormControlSelect;
