import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const PriceListComp = React.lazy(
  () => import('../../components/TradePrice/PriceList')
);

const PriceList = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.tradePrice}>
      <PriceListComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default PriceList;
