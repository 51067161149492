import styled from 'styled-components';
import { Button } from '@sede-x/shell-ds-react-framework';
import theme from 'styled-theming';

export const buttonBackgroundColor = theme('name', {
  light: 'rgb(231 231 231)',
  dark: 'rgba(255, 255, 255, 0.1)',
});
export const buttonColor = theme('name', {
  light: 'rgb(175 164 164)',
  dark: 'rgba(255, 255, 255, 0.25)',
});
// eslint-disable-next-line import/prefer-default-export
export const ShellButton = styled(Button)`
  :disabled {
    pointer-events: unset;
    background-color: ${buttonBackgroundColor};
    color: ${buttonColor};
  }
  svg {
    circle: {
      stroke: rgb(0, 151, 187);
    }
  }
`;
