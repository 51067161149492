/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import { TIME_ZONE } from '../../components/GTO/CustomDatePicker/components';
import { get as GetApi } from '../../services/COPApi';
import { ApplicationStateI } from '../../types/Application.type';

const currentHour = moment.tz(TIME_ZONE).hour();

const currentMinute = moment.tz(TIME_ZONE).minutes();
let currentShift;
if (currentHour > 7 && currentHour < 19) {
  currentShift = 1;
} else if (currentHour === 7) {
  // check for hours
  if (currentMinute > 45) {
    currentShift = 1;
  } else {
    currentShift = 2;
  }
} else {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentShift = 2;
}

const API_REFRESH_TIMEOUT = 5 * 6 * 10000;

const initialState: ApplicationStateI = {
  id: 0,
  applicationName: '',
  parameterType: '',
  parameterValue: API_REFRESH_TIMEOUT,
  currentHour: moment.tz(TIME_ZONE).hour(),
  currentMinute: moment.tz(TIME_ZONE).minutes(),
  currentShift,
};

export const fetchAllStaticValue = createAsyncThunk(
  'application/fetchAllStaticValue',
  async () =>
    GetApi('/api/Application/GetAllStaticValue').then((resp) => {
      return resp.data;
    })
);

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateCurrHourMinutes: (state: ApplicationStateI) => {
      state.currentHour = initialState.currentHour;
      state.currentMinute = initialState.currentMinute;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStaticValue.pending, (state: ApplicationStateI) => {
        state.id = 0;
        state.applicationName = '';
        state.parameterType = '';
        state.parameterValue = 0;
      })
      .addCase(
        fetchAllStaticValue.fulfilled,
        (
          state: ApplicationStateI,
          { payload }: PayloadAction<ApplicationStateI>
        ) => {
          state.id = payload.id;
          state.applicationName = payload[0].applicationName;
          state.parameterType = payload[0].parameterType;
          state.parameterValue = payload[0].parameterValue * 6 * 10000;
        }
      )
      .addCase(fetchAllStaticValue.rejected, (state: ApplicationStateI) => {
        state.id = 0;
        state.applicationName = '';
        state.parameterType = '';
        state.parameterValue = 0;
      });
  },
});

export const ApplicationDataActions = applicationSlice.actions;
export default applicationSlice.reducer;
