/* eslint-disable no-param-reassign, @typescript-eslint/no-unused-vars */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { POST_UPLOAD_TO_ALIGNE } from '../../constants/UKNomination/FieldNomination';
import { post } from '../../services/COPApi';
import {
  IFieldNomination,
  IUploadToAlignePayload,
} from '../../types/UKNomination/FieldNomination.type';

export interface IFNReconcileState {
  uploadToAligne: {
    loading: boolean;
    uploadStatus: 'Success' | 'Failed' | null;
  };
}

const initialState: IFNReconcileState = {
  uploadToAligne: {
    loading: false,
    uploadStatus: null,
  },
};

export const postUploadToAligne = createAsyncThunk(
  'fieldNoms/postUploadToAligne',
  async (payload: IUploadToAlignePayload[]) => {
    return post(POST_UPLOAD_TO_ALIGNE, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        throw error;
      });
  }
);

const fnReconcileSlice = createSlice({
  name: 'fnReconcile',
  initialState,
  reducers: {
    clearUploadResponse: (state) => {
      state.uploadToAligne.loading = false;
      state.uploadToAligne.uploadStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUploadToAligne.pending, (state: IFNReconcileState) => {
        state.uploadToAligne.loading = true;
        state.uploadToAligne.uploadStatus = null;
      })
      .addCase(
        postUploadToAligne.fulfilled,
        (
          state: IFNReconcileState,
          { payload }: PayloadAction<IFieldNomination[]>
        ) => {
          state.uploadToAligne.loading = false;
          state.uploadToAligne.uploadStatus = 'Success';
        }
      )
      .addCase(postUploadToAligne.rejected, (state: IFNReconcileState) => {
        state.uploadToAligne.loading = false;
        state.uploadToAligne.uploadStatus = 'Failed';
      });
  },
});

export const FNReconcileActions = fnReconcileSlice.actions;
export default fnReconcileSlice.reducer;
