import React, { ReactElement } from 'react';
import styled from 'styled-components';

import SuspenseLoadPage from '../components/OtherComponents/SuspenseLoadPage';
import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const PrismaCalendarCom = React.lazy(
  () => import('../components/PrismaCalendar')
);

const PrismaCalendarContainer = styled.div`
  padding: 10px 25px;
`;

function PrismaCalendar(): ReactElement {
  return (
    <SuspenseLoadPage>
      <CheckAuthorizationWithSuspense
        pageName={applicationName.capacityManagementTool}
      >
        <PrismaCalendarContainer>
          <PrismaCalendarCom />
        </PrismaCalendarContainer>
      </CheckAuthorizationWithSuspense>
    </SuspenseLoadPage>
  );
}

export default PrismaCalendar;
