import React from 'react';
import UnauthorizedComp from '../components/OtherComponents/Unauthorized';

const UnauthorizedCMT = () => {
  return (
    <UnauthorizedComp message='Sorry you are not authorized to access this application' />
  );
};

export default UnauthorizedCMT;
