/* eslint-disable no-param-reassign, no-underscore-dangle, consistent-return */
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

export const axiosConfig = {
  baseURL: process.env.REACT_APP_CMT_API_BASE_URL,
  timeout: 50000,
  headers: {
    common: {
      Authorization: 'YWRtaW46c2hlbGwxMjM0',
    },
  },
};

export const CMTApi = axios.create(axiosConfig);

CMTApi.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('access_token') || '';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const getRequestAbortionPieces = () => {
  const abort = axios.CancelToken.source();
  const connectionTimeout = setTimeout(() => {
    abort.cancel(`Connection timeout of ${axiosConfig.timeout}ms.`);
  }, axiosConfig.timeout);

  return { abort, connectionTimeout };
};

export const get = async (path: string): Promise<AxiosResponse> => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();
  return CMTApi.get(path, {
    cancelToken: abort.token,
  }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};

export const post = async (path: string, body): Promise<AxiosResponse> => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return CMTApi.post(path, body, {
    cancelToken: abort.token,
  }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};

export const put = async (path: string, body): Promise<AxiosResponse> => {
  const { abort, connectionTimeout } = getRequestAbortionPieces();

  return CMTApi.put(path, body, {
    cancelToken: abort.token,
  }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};

export const deleteApi = async (path: string, body): Promise<AxiosResponse> => {
  const { connectionTimeout } = getRequestAbortionPieces();
  return CMTApi.delete(path, { data: body }).then((resp) => {
    clearTimeout(connectionTimeout);
    return resp;
  });
};

export const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (err) => {
      const error = err?.response || err;
      switch (error?.status) {
        case 401:
          if (error.config && !error.config.__isRetryRequest) {
            navigate('/unauthorized-application');
          }
          break;
        case 403:
          navigate('/forbidden');
          break;
        case 500:
        case 404:
          navigate('/error');
          break;
        default:
          return Promise.reject(err);
      }
    };
    CMTApi.interceptors.response.use(resInterceptor, errInterceptor);
    setIsSet(true);
  }, [navigate]);

  return isSet && children;
};
