import React from 'react';

import { applicationName } from '../../constants/Auth';
import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';

const WeightedAverage = React.lazy(
  () => import('../../components/ConversionCalculator/WeightedAverage')
);

const WeightedAverageCalculator = () => {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.weightedAverageCalculator}
    >
      <WeightedAverage />
    </CheckAuthorizationWithSuspense>
  );
};

export default WeightedAverageCalculator;
