/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Icons,
  Pagination,
  Table,
  Toggle,
} from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';
import RecordsPerPage from '../ShowTasks/TaskFilter/RecordsPerPage';
import { put as PutApi } from '../../../../services/COPApi';
import Loader from '../../../../UI/Loader';

export interface OppsTableProps {
  oppsData: any;
  filteredData: boolean;
  canAddOpps: boolean;
  callToggledStatus: () => void;
  setErrorMsg: (val: boolean) => void;
  isReadOnly?: boolean;
}

const PaginationContainer = styled.div`
  display: flex;
  margin-right: 5%;
`;

const { Edit: EditIcon } = Icons;

const OpportunitiesTable = (props: OppsTableProps) => {
  const {
    oppsData,
    filteredData,
    canAddOpps,
    callToggledStatus,
    setErrorMsg,
    isReadOnly,
  } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const navigate = useNavigate();
  const [alertMsg, setAlertMsg] = useState<boolean>(false);

  // post call ontoggle click of status in the table
  const handleStatusChange = async (item) => {
    const reqBody = {
      id: item.id,
      name: item.name,
      description: item.description,
      isActive: !item.isActive,
      createdDate: new Date(),
    };
    setLoader(true);
    try {
      const response = await PutApi(
        '/api/cop/CommercialOpportunity/Update',
        reqBody
      );
      if (response.status === 200) {
        callToggledStatus();
        setLoader(false);
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } catch (err) {
      setAlertMsg(true);
      setLoader(false);
      setErrorMsg(true);
    }
  };

  const onPageSIzeChange = (size) => {
    if (size) {
      setPageSize(size);
    } else {
      setPageSize(10);
    }
    setPageNo(1);
  };

  const formatTableData = () => {
    if (oppsData !== undefined) {
      if (oppsData?.length > 0) {
        return (
          oppsData.length > 0 &&
          oppsData
            .slice((pageNo - 1) * pageSize, pageNo * pageSize)
            .map((item) => {
              return {
                ...item,
                key: item.id,
                status: (
                  <Toggle
                    checked={item.isActive}
                    size='medium'
                    data-testid='is-active'
                    onClick={() =>
                      canAddOpps ? handleStatusChange(item) : <></>
                    }
                    disabled={!canAddOpps || isReadOnly}
                  />
                ),
                edit: (
                  <Button
                    size='medium'
                    variant='transparent'
                    onClick={() =>
                      navigate('/ocl/editOpportunities', {
                        state: { data: item },
                      })
                    }
                    key={`ds-edit-btn-${item.id}`}
                    disabled={!canAddOpps || isReadOnly}
                    data-testid='edit-button'
                  >
                    <EditIcon height={20} width={20} />
                  </Button>
                ),
                createdDate: dayjs(item.createdDate).format('YYYY-MM-DD'),
              };
            })
        );
      }
    }
    return [];
  };

  useEffect(() => {
    setPageNo(1);
  }, [oppsData, filteredData]);

  return (
    <>
      {loader && <Loader />}

      {alertMsg && (
        <Alert
          state='information'
          dismissible
          onDismissClick={() => {
            setAlertMsg(false);
          }}
        >
          There is some error in fetching data!
        </Alert>
      )}
      <PaginationContainer>
        <RecordsPerPage
          pageSize={pageSize}
          onPageSIzeChange={onPageSIzeChange}
        />
      </PaginationContainer>
      <Table
        rowKey={() => Math.random()}
        data-testid='opportunity-table'
        columns={[
          {
            dataIndex: 'name',
            key: 'ds_name',
            title: 'Flow/Asset',
          },
          {
            dataIndex: 'description',
            key: 'sd_description',
            title: 'Description',
          },
          {
            dataIndex: 'createdDate',
            key: 'sd_date',
            title: 'Created Date',
          },
          {
            dataIndex: 'status',
            key: 'sd_status',
            title: 'Status',
          },
          {
            dataIndex: 'edit',
            key: 'ds_Edit',
            title: 'Edit',
          },
        ]}
        data={formatTableData()}
        prefixCls='shell-table'
        size='medium'
      />
      {oppsData.length > 0 && (
        <Pagination
          current={pageNo}
          pageSize={pageSize}
          total={oppsData.length ?? 0}
          onChange={setPageNo}
        />
      )}
    </>
  );
};

export default OpportunitiesTable;
