import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const VariableComp = React.lazy(
  () => import('../components/FirstUtility/Variable')
);

const Variable: React.FC = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.variable}>
      <VariableComp />
    </CheckAuthorizationWithSuspense>
  );
};
export default Variable;
