import _ from 'lodash';

const searchByText = (collection: any, text: string, include: string[]) => {
  return _.filter(collection, (object) => {
    if (_(object).toLower().includes(_.toLower(text))) {
      return true;
    }
    return _(object)
      .pick(include)
      .some((value) => {
        if (Array.isArray(value) || typeof value === 'object') {
          return searchByText(value, text, include).length > 0;
        }
        return _(value).toLower().includes(_.toLower(text));
      });
  });
};

export default searchByText;
