/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FullStateI } from '../../types/All.type';
import { getSourceProductionFields } from '../../store/UKOps/FieldNoms';
import { ISelectOptions } from '../../types/Common.type';

type GetSourceProductionFieldsType = () => {
  loadingSourceProductionFields: boolean;
  sourceProductionFields: ISelectOptions[];
};

const useGetSourceProductionFields: GetSourceProductionFieldsType = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.sourceProductionField
  );

  useEffect(() => {
    if (data.length === 0 && !loading) {
      dispatch(getSourceProductionFields());
    }
  }, [data]);

  return {
    loadingSourceProductionFields: loading,
    sourceProductionFields: data,
  };
};

export default useGetSourceProductionFields;
