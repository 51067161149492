export const GetUnitsAPI = '/api/cop/ConversionCalculator/GetUnits';

export const GetAllFormulaTypesAPI =
  '/api/cop/ConversionCalculator/GetAllFormulaTypes';

export const GetAllUnitsByFormulaTypeAPI = (type: number) =>
  `/api/cop/ConversionCalculator/GetAllUnitsByFormulaType?type=${type}`;

export const AddUpdateUnitAPI = '/api/cop/ConversionCalculator/AddUpdateUnit';

export const AddUnitFormulaAPI = '/api/cop/ConversionCalculator/AddUnitFormula';

export const GetUnitFormulaWithIdAPI = (id: string) =>
  `/api/cop/ConversionCalculator/GetUnitFormulaWithId?id=${id}`;

export const UpdateUnitFormulaAPI =
  '/api/cop/ConversionCalculator/UpdateUnitFormula';

export const DeleteUnitAPI = (id: number) =>
  `/api/cop/ConversionCalculator/DeleteUnit?id=${id}`;

export const DeleteUnitFormulaAPI = (id: number) =>
  `/api/cop/ConversionCalculator/DeleteUnitFormula?id=${id}`;

export const GetAllFormulaFromUnitIdAPI = (id: number) =>
  `/api/cop/ConversionCalculator/GetAllFormulaFromUnitId?id=${id}`;
