import React, { ReactElement } from 'react';
import styled from 'styled-components';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const SenaCom = React.lazy(() => import('../components/SENA'));

const SenaContainer = styled.div`
  padding: 10px 25px;
`;

function Sena(): ReactElement {
  return (
    <CheckAuthorizationWithSuspense
      pageName={applicationName.senaPipelineAllocation}
    >
      <SenaContainer>
        <SenaCom />
      </SenaContainer>
    </CheckAuthorizationWithSuspense>
  );
}

export default Sena;
