/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { TextInput, Toggle, Loading } from '@sede-x/shell-ds-react-framework';
import { Row, Col, FormRow } from '../../../../UI/Grid';
import {
  FieldNominationWrapper,
  ModalFooter,
  TableLoaderContainer,
} from '../style';
import Card from '../../../../UI/Card';
import Table from '../../../../UI/Table';
import { ITerminalMapping } from '../../../../types/UKNomination/FieldNomination.type';
import searchByText from '../../../../utils/searchFunction';
import { get, put } from '../../../../services/COPApi';
import {
  GET_UNMAPPED_SOURCE_PRODUCTION_FIELDS,
  MESSAGES,
  UPDATE_NOMINATION_VISIBILITY,
} from '../../../../constants/UKNomination/FieldNomination';
import { useToast } from '../../../../UI/Toast';
import Button from '../../../../UI/Button';
import Loader from '../../../../UI/Loader';
import { FullStateI } from '../../../../types/All.type';
import { Modal } from '../../../../UI/Modal';
import { applicationName, userRole } from '../../../../constants/Auth';

interface IUnMappedMapping {
  open: boolean;
  onClose: () => void;
}

const UnMappedMapping: React.FC<IUnMappedMapping> = ({ open, onClose }) => {
  const toast = useToast();

  const { userRole: userAccess } = useSelector(
    (state: FullStateI) => state.User
  );

  const [loadingIsVisible, setLoadingIsVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [data, setData] = useState<ITerminalMapping[]>([]);

  const getTerminalMappings = () => {
    setLoading(true);
    get(GET_UNMAPPED_SOURCE_PRODUCTION_FIELDS)
      .then((response) => {
        setData(response?.data || []);
      })
      .catch(() => {
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerminalMappings();
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const renderData = (text: string, mappings: ITerminalMapping[]) => {
    return searchByText(mappings, text, ['name']);
  };

  const handleIsVisible = (sourceProdFieldId: number, checked: boolean) => {
    setLoadingIsVisible((prevState) => ({
      ...prevState,
      [sourceProdFieldId]: true,
    }));
    put(
      `${UPDATE_NOMINATION_VISIBILITY}?sourceProductionFieldId=${sourceProdFieldId}&isVisible=${checked}`,
      {}
    )
      .then((response) => {
        toast?.open(response?.data, 'success');
        setLoadingIsVisible((prevState) => ({
          ...prevState,
          [sourceProdFieldId]: false,
        }));

        setData((prevState) => {
          return prevState.map((item) => {
            if (item.id === sourceProdFieldId) {
              return {
                ...item,
                isVisible: checked,
              };
            }
            return item;
          });
        });
      })
      .catch(() => {
        setLoadingIsVisible((prevState) => ({
          ...prevState,
          [sourceProdFieldId]: false,
        }));
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      });
  };

  const columns = [
    {
      dataIndex: 'name',
      key: 'sourceProductionField',
      title: 'Source Production Field',
      sorting: true,
    },

    {
      dataIndex: '',
      title: 'Is Visible',
      render: (row) => {
        if (loadingIsVisible[row.id]) {
          return <Loading />;
        }
        return (
          <Toggle
            disabled={
              !(userAccess[applicationName.genesisAdmin] === userRole.readWrite)
            }
            checked={row?.isVisible}
            onChange={() => handleIsVisible(row?.id, !row?.isVisible)}
          />
        );
      },
    },
  ];

  const renderFooter = () => (
    <ModalFooter>
      {/* <Button onClick={onClose} loading={loading} disabled={loading}>
        Yes
      </Button> */}
      <Button variant='outlined' onClick={onClose}>
        Cancel
      </Button>
    </ModalFooter>
  );

  return (
    <Modal
      open={open}
      size='small'
      onClose={onClose}
      title='Unmapped Source Production Fields Mapping'
      isDraggable
      maskClosable={false}
      footer={renderFooter()}
      width='60%'
    >
      <div>
        <FieldNominationWrapper>
          <Row>
            <Col size={12}>
              <Card>
                <FormRow>
                  <Col size={3} className='date-range'>
                    <TextInput placeholder='Search' onChange={handleSearch} />
                  </Col>
                  <Col size={9} className='button-wrapper'>
                    {/* <Button
                      size='small'
                      icon={<DownloadSolid />}
                      onClick={handleDownloadOnClick}
                      loading={downloading}
                      disabled={downloading}
                    >
                      Download
                    </Button> */}
                  </Col>
                </FormRow>
                <FormRow className='table-container'>
                  {loading && (
                    <TableLoaderContainer>
                      <div data-testid='loader' className='loader-wrapper'>
                        <Loader />
                      </div>
                    </TableLoaderContainer>
                  )}
                  <Col size={12} className='unmapped_table_container'>
                    <Table
                      columns={columns}
                      data={renderData(searchText, data)}
                    />
                  </Col>
                </FormRow>
              </Card>
            </Col>
          </Row>
        </FieldNominationWrapper>
      </div>
    </Modal>
  );
};

export default UnMappedMapping;
