/* eslint-disable import/prefer-default-export */
export const GET_BEACH_SWAP = `/api/cop/BeachSwap/GetBeachSwaps`;
export const DOWNLOAD_BEACH_SWAP = `/api/cop/BeachSwap/DownloadBeachSwaps`;
export const GET_ZKEY_MAPPING = `/api/cop/BeachSwap/GetAllZKeyMappings`;
export const DOWNLOAD_ZKEY_MAPPINGS = `api/cop/BeachSwap/DownloadZKeyMappingExcel`;
export const ADD_UPDATE_ZKEY_MAPPING = `/api/cop/BeachSwap/AddorUpdateZKeyMapping`;
export const GET_ALL_COUNTER_PARTY = `/api/cop/BeachSwap/GetAllCounterParty`;
export const GET_ZKEY_MAPPING_BY_ID = `/api/cop/BeachSwap/GetZKeyMappingById`;
export const DELETE_TERMINAL_MAPPINGS = `/api/cop/BeachSwap/DeleteZKeyMapping`;
export const GET_ZKEY_MAPPING_AUDIT_LOGS = `api/cop/BeachSwap/GetZkeyMappingAuditLog`;
export const GET_BEACH_SWAPS = `/api/cop/BeachSwap/GetBeachSwaps`;
export const ADD_UPDATE_BEACH_SWAP = `/api/cop/BeachSwap/AddorUpdateBeachSwap`;
export const GET_BEACH_SWAP_BY_ID = `/api/cop/BeachSwap/GetBeachSwapById`;
export const UPDATE_BEACH_SWAP_STATUS = `/api/cop/BeachSwap/UpdateBeachSwapStatus`;
export const UPLOAD_TO_ALIGNE = `/api/cop/BeachSwap/UploadtoAligne`;
export const GET_BEACH_SWAP_LOGS = `/api/cop/BeachSwap/GetBeachSwapLogs`;
export const GET_PAYLOAD_INFO = `/api/cop/BeachSwap/GetPayloadInfo`;
export const DOWNLOAD_BEACHSWAP_NOMINATIONS = `/api/cop/BeachSwap/DownloadBeachSwapsExcel`;
export const GET_CURRENT_ALIGNED = `/api/cop/BeachSwap/LoadAlignVolumeDataInDB`;
export const GET_GAS_AVAILABILITY = `/api/cop/BeachSwap/GetGasAvailabilty`;

export const radioButtonOptions = ['Therms', 'KWh'];
export const ALIGNE_ACTIONS = ['Submitted'];

export const MESSAGES = {
  SERVER_ERROR: 'Something went wrong please contact system administrator.',
  REQUIRED_QUANTITY: 'Quantity is required',
  REQUIRED_LOCATION: 'Location is required',
  REQUIRED_Z_KEY: 'zKey is required',
  REQUIRED_UNIT: 'Unit is required',
  REQUIRED_COUNTER_PARTY: 'Counter Party is required',
  REQUIRED_DATE_TYPE: 'Date Type is required',
  REQUIRED_TERMINAL: 'Terminal is required',
  REQUIRED_SUB_TERMINAL: 'SubTerminal is required',
  REQUIRED_GAS_DAY: 'Gas Day is required',
  REQUIRED_BUY_SELL: 'Buy/Sell is required',
  REQUIRED_PRIMARY_SECONDARY: 'Primary/Secondary is required',
};
