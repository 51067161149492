import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';
import { LOCATION_ID } from '../../constants/CredentialsStore';

const ViewCredentials = React.lazy(
  () => import('../../components/CredentialsStore/ViewCredentials')
);

interface ICredStoreViewProps {
  locationId: number;
}

const CredStoreView: React.FC<ICredStoreViewProps> = ({ locationId }) => {
  const pageName =
    locationId === LOCATION_ID.SEEL
      ? applicationName.credentialStore
      : applicationName.senaPasswordHub;

  return (
    <CheckAuthorizationWithSuspense pageName={pageName}>
      <ViewCredentials locationId={locationId} pageName={pageName} />
    </CheckAuthorizationWithSuspense>
  );
};

export default CredStoreView;
