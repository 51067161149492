/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FullStateI } from '../../types/All.type';
import { getProductionFields } from '../../store/UKOps/FieldNoms';
import { ISelectOptions } from '../../types/Common.type';

type GetProductionFieldsType = (getCall: boolean) => {
  loadingProductionFields: boolean;
  productionFields: ISelectOptions[];
};

const useGetProductionFields: GetProductionFieldsType = (getCall = false) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state: FullStateI) => state.FieldNoms.productionField
  );

  useEffect(() => {
    if (data.length === 0 && !loading && getCall) {
      dispatch(getProductionFields());
    }
  }, [data, getCall]);

  return { loadingProductionFields: loading, productionFields: data };
};

export default useGetProductionFields;
