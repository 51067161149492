import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const InputPnLDashboardComp = React.lazy(
  () => import('../../components/PnLDashboard/InputPnLDashboard')
);

const ViewPnLDashboard = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.pnlDashboard}>
      <InputPnLDashboardComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default ViewPnLDashboard;
