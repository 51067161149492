import React from 'react';

import CheckAuthorizationWithSuspense from '../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../constants/Auth';

const EPSQCalculatorComp = React.lazy(
  () => import('../components/EPSQCalculator')
);

const EPSQCalculator = () => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.epsqCalculator}>
      <EPSQCalculatorComp />
    </CheckAuthorizationWithSuspense>
  );
};

export default EPSQCalculator;
