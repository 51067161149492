import { Chart } from 'chart.js';
import { CHART_LEGEND_COLOR } from '../../constants/Dashboard';

const getOptions = (isDark: boolean) => {
  const color = isDark ? CHART_LEGEND_COLOR.DARK : CHART_LEGEND_COLOR.LIGHT;

  Chart.register({
    id: 'NoData',
    afterDatasetsDraw: (chart) => {
      if (
        chart.data.datasets
          .map((d) => d.data.length)
          .reduce((p, a) => p + a, 0) === 0
      ) {
        const { ctx } = chart;
        const { width } = chart;
        const { height } = chart;
        chart.clear();

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = `2.5rem ${
          window.getComputedStyle(document.body).fontFamily
        }`;
        ctx.fillStyle = color;
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore();
      }
    },
  });

  return {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color,
        },
      },
      x: {
        ticks: {
          color,
        },
      },
    },
    plugins: {
      title: {
        display: false,
        text: '',
      },
      legend: {
        display: true,
        labels: { color },
      },
    },
  };
};

function sortDatesUtil({ labels, order = 'asc' }) {
  labels.sort((a: string, b: string) => {
    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();
    if (Number.isNaN(dateA) || Number.isNaN(dateB)) {
      // Handle invalid date strings by placing them at the end
      if (Number.isNaN(dateA)) return 1;
      if (Number.isNaN(dateB)) return -1;
      return 0;
    }
    return order === 'asc' ? dateA - dateB : dateB - dateA;
  });
  return labels;
}

export { getOptions, sortDatesUtil };
