/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  Icons,
  Heading,
  TextInput,
  Toggle,
  Loading,
} from '@sede-x/shell-ds-react-framework';
import { Row, Col, FormRow } from '../../../../UI/Grid';
import { FieldNominationWrapper, TableLoaderContainer } from '../style';
import Card from '../../../../UI/Card';
import Table from '../../../../UI/Table';
import AddEditTerminalMapping from '../AddEditTerminalMapping';
import { ITerminalMapping } from '../../../../types/UKNomination/FieldNomination.type';
import searchByText from '../../../../utils/searchFunction';
import { COPApi, get, put } from '../../../../services/COPApi';
import {
  DOWNLOAD_TERMINAL_MAPPINGS,
  GET_TERMINAL_MAPPINGS,
  MESSAGES,
  UPDATE_NOMINATION_VISIBILITY,
} from '../../../../constants/UKNomination/FieldNomination';
import { useToast } from '../../../../UI/Toast';
import Button from '../../../../UI/Button';
import ConfirmDeleteMapping from './ConfirmDeleteMapping';
import Loader from '../../../../UI/Loader';
import { applicationName, userRole } from '../../../../constants/Auth';
import { FullStateI } from '../../../../types/All.type';
import UnMappedMapping from '../UnmappedTerminals';

const { DownloadSolid, Edit, Cross, TrashAlt: DeleteIcon } = Icons;

const TerminalMapping: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { userRole: userAccess } = useSelector(
    (state: FullStateI) => state.User
  );

  const [open, setOpen] = useState<boolean>(false);
  const [loadingIsVisible, setLoadingIsVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openUnMapped, setOpenUnMapped] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [data, setData] = useState<ITerminalMapping[]>([]);

  const [selectedItem, setSelectedItem] = useState<ITerminalMapping | null>(
    null
  );

  const getTerminalMappings = () => {
    setLoading(true);
    get(GET_TERMINAL_MAPPINGS)
      .then((response) => {
        setData(response?.data || []);
      })
      .catch(() => {
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTerminalMappings();
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const renderData = (text: string, mappings: ITerminalMapping[]) => {
    return searchByText(mappings, text, [
      'zKey',
      'unit',
      'sourceProductionField',
      'productionField',
      'terminal',
      'subTerminal',
      'timer',
      'formula',
      'daVarianceBreach',
      'wdVarianceBreach',
    ]);
  };

  const handleOnOpenClose = (value: boolean) => {
    setOpen(value);
    if (!value) {
      setSelectedItem(null);
    }
  };

  const handleEditOnClick = (item: ITerminalMapping) => {
    setSelectedItem(item);
    handleOnOpenClose(true);
  };

  const handleOnOpenCloseConfirm = (value: boolean) => {
    setOpenConfirm(value);
    if (!value) {
      setSelectedItem(null);
    }
  };

  const handleOnOpenCloseUnMapped = (value: boolean) => {
    setOpenUnMapped(value);
  };

  const handleDeleteOnClick = (item: ITerminalMapping) => {
    setSelectedItem(item);
    handleOnOpenCloseConfirm(true);
  };

  const handleDownloadOnClick = () => {
    setDownloading(true);
    COPApi({
      url: DOWNLOAD_TERMINAL_MAPPINGS,
      method: 'POST',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Mappings_${dayjs().format()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const handleCloseOnClick = () => {
    navigate(-1);
  };

  const checkExisting = (
    keys: string[],
    existingData: ITerminalMapping[],
    formData: ITerminalMapping
  ): boolean => {
    return existingData.some((record) =>
      keys.every((key) => record[key] === formData[key])
    );
  };

  const handleIsVisible = (sourceProdFieldId: number, checked: boolean) => {
    setLoadingIsVisible((prevState) => ({
      ...prevState,
      [sourceProdFieldId]: true,
    }));
    put(
      `${UPDATE_NOMINATION_VISIBILITY}?sourceProductionFieldId=${sourceProdFieldId}&isVisible=${checked}`,
      {}
    )
      .then((response) => {
        toast?.open(response?.data, 'success');
        setLoadingIsVisible((prevState) => ({
          ...prevState,
          [sourceProdFieldId]: false,
        }));
        setData((prevState) => {
          return prevState.map((item) => {
            if (item.sourceProductionFieldId === sourceProdFieldId) {
              return {
                ...item,
                isVisible: checked,
              };
            }
            return item;
          });
        });
      })
      .catch(() => {
        setLoadingIsVisible((prevState) => ({
          ...prevState,
          [sourceProdFieldId]: false,
        }));
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      });
  };

  const checkTerminalMappingExists = (frmData) => {
    const formData: ITerminalMapping = {
      ...frmData,
      zKey: frmData.zKey ? parseFloat(frmData.zKey) : frmData.zKey,
    };
    let objData = data;
    if (selectedItem) {
      objData = data.filter((item) => item.id !== selectedItem.id);
    }
    const mappingExists: boolean = checkExisting(
      [
        'zKey',
        'unit',
        'sourceProductionFieldId',
        'productionFieldId',
        'terminalId',
        'subTerminalId',
      ],
      objData,
      formData
    );
    if (mappingExists) {
      return {
        error: true,
        message:
          'A Production Field and terminal can be mapped to only one zKey',
      };
    }
    return {
      error: false,
      message: '',
    };
  };

  const sourceProductionFieldExists = (sourceProductionFieldId: number) => {
    const result = data.filter(
      (_) => _.sourceProductionFieldId === sourceProductionFieldId
    );
    if (selectedItem) return result.length > 1;
    return result.length > 0;
  };

  const renderActions = (item: ITerminalMapping) => (
    <div className='grid-actions'>
      <Button
        iconOnly
        icon={<Edit />}
        variant='outlined'
        size='xsmall'
        title='Edit'
        onClick={() => handleEditOnClick(item)}
        disabled={
          !(userAccess[applicationName.genesisAdmin] === userRole.readWrite)
        }
      />
      <Button
        iconOnly
        icon={<DeleteIcon />}
        variant='outlined'
        size='xsmall'
        title='Delete'
        onClick={() => handleDeleteOnClick(item)}
        disabled={
          !(userAccess[applicationName.genesisAdmin] === userRole.readWrite)
        }
      />
    </div>
  );

  const columns = [
    {
      dataIndex: 'zKey',
      key: 'zKey',
      title: 'zKey',
      sorting: true,
    },
    {
      dataIndex: 'unit',
      key: 'unit',
      title: 'Deal Unit',
      sorting: true,
    },
    {
      dataIndex: 'sourceProductionField',
      key: 'sourceProductionField',
      title: 'Source Production Field',
      sorting: true,
    },
    {
      dataIndex: 'productionField',
      key: 'productionField',
      title: 'Production Field',
      sorting: true,
    },
    {
      dataIndex: 'terminal',
      key: 'terminal',
      title: 'Terminal',
      sorting: true,
    },
    {
      dataIndex: 'subTerminal',
      key: 'subTerminal',
      title: 'Sub Terminal',
      sorting: true,
    },
    {
      dataIndex: 'timer',
      key: 'timer',
      title: 'Deadline Time',
    },
    {
      dataIndex: 'formula',
      key: 'formula',
      title: 'Formula',
    },
    {
      dataIndex: 'wdVarianceBreach',
      key: 'wdVarianceBreach',
      title: 'WD Variance Breach',
      sorting: true,
    },
    {
      dataIndex: 'daVarianceBreach',
      key: 'daVarianceBreach',
      title: 'DA Variance Breach',
      sorting: true,
    },
    {
      dataIndex: '',
      title: 'Is Visible',
      render: (row) => {
        if (loadingIsVisible[row.sourceProductionFieldId]) {
          return <Loading />;
        }
        return (
          <Toggle
            disabled={
              !(userAccess[applicationName.genesisAdmin] === userRole.readWrite)
            }
            checked={row?.isVisible}
            onChange={() =>
              handleIsVisible(row?.sourceProductionFieldId, !row?.isVisible)
            }
          />
        );
      },
    },
    {
      title: 'Actions',
      render: renderActions,
    },
  ];

  const handleUnMappedModal = () => {
    handleOnOpenCloseUnMapped(true);
  };

  return (
    <FieldNominationWrapper>
      <Row>
        <Col size={12}>
          <Card>
            <FormRow>
              <Col size={10}>
                <Heading type='h2'>
                  Production Field - zKey - Terminal - SubTerminal mapping
                </Heading>
              </Col>
              <Col size={2}>
                <Button
                  className='close-mapping'
                  iconOnly
                  icon={<Cross />}
                  onClick={handleCloseOnClick}
                  variant='transparent'
                />
              </Col>
            </FormRow>
            <FormRow className='filter-container'>
              <Col size={3} className='date-range'>
                <TextInput placeholder='Search' onChange={handleSearch} />
              </Col>
              <Col size={9} className='button-wrapper'>
                <Button
                  size='small'
                  onClick={() => handleOnOpenClose(true)}
                  disabled={
                    !(
                      userAccess[applicationName.genesisAdmin] ===
                      userRole.readWrite
                    )
                  }
                >
                  Add New
                </Button>
                <Button onClick={handleUnMappedModal}>UnMapped</Button>
                <Button
                  size='small'
                  icon={<DownloadSolid />}
                  onClick={handleDownloadOnClick}
                  loading={downloading}
                  disabled={downloading}
                >
                  Download
                </Button>
              </Col>
            </FormRow>
            <FormRow className='table-container'>
              {loading && (
                <TableLoaderContainer>
                  <div data-testid='loader' className='loader-wrapper'>
                    <Loader />
                  </div>
                </TableLoaderContainer>
              )}
              <Col size={12}>
                <Table columns={columns} data={renderData(searchText, data)} />
              </Col>
            </FormRow>
          </Card>
        </Col>
      </Row>
      <AddEditTerminalMapping
        open={open}
        selectedItem={selectedItem}
        onClose={() => handleOnOpenClose(false)}
        refreshData={getTerminalMappings}
        checkExisting={checkTerminalMappingExists}
        sourceProductionFieldExists={sourceProductionFieldExists}
      />
      <ConfirmDeleteMapping
        open={openConfirm}
        selectedItem={selectedItem}
        onClose={() => handleOnOpenCloseConfirm(false)}
        refreshData={getTerminalMappings}
      />
      <UnMappedMapping
        open={openUnMapped}
        onClose={() => handleOnOpenCloseUnMapped(false)}
      />
    </FieldNominationWrapper>
  );
};

export default TerminalMapping;
