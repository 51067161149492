/* eslint-disable no-param-reassign */

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { UserStateI } from '../../types/UserState.type';
import { ISideNavMenu } from '../../layout/SideNav/type';
import processMenuAndSubMenu from '../../layout/SideNav/common';

interface SetNameAccessTockenPropI {
  givenName: string;
  sn: string;
  uid: string;
  accessToken: string;
  mail: string;
}

const initialState: UserStateI = {
  isAuthorized: null,
  isFetching: false,
  error: null,
  userName: '',
  accessToken: '',
  uid: '',
  userRolesAndMenu: [],
  userRole: {},
  mail: '',
};
export const fetchUserRole = createAsyncThunk(
  'userRole/fetchUserRole',
  async (token: string) =>
    axios
      .get(
        `${process.env.REACT_APP_COP_API_BASE_URL}/api/cop/DynamicMenu/GetDynamicMenuByUser`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp: AxiosResponse) => {
        const sideMenu = resp.data || [];
        return sideMenu.sort(
          (item_a, item_b) => item_a.menuOrder - item_b.menuOrder
        );
      })
);

const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setNameAccessTocken: (
      state: UserStateI,
      { payload }: PayloadAction<SetNameAccessTockenPropI>
    ) => {
      state.userName = `${payload.givenName} ${payload.sn}`;
      state.accessToken = payload.accessToken;
      state.uid = payload.uid;
      state.mail = payload.mail;
    },
    logOut: (state: UserStateI) => {
      state.isAuthorized = null;
      state.userName = '';
      state.accessToken = '';
      state.uid = '';
      state.userRolesAndMenu = [];
      state.mail = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRole.pending, (state: UserStateI) => {
        state.isFetching = true;
        state.error = null;
        state.userRolesAndMenu = [];
        state.userRole = {};
      })
      .addCase(
        fetchUserRole.fulfilled,
        (state: UserStateI, { payload }: PayloadAction<ISideNavMenu[]>) => {
          if (!!payload && Array.isArray(payload) && payload.length > 0) {
            state.isFetching = false;
            state.isAuthorized = true;
            state.error = null;
            state.userRolesAndMenu = payload;
            state.userRole = processMenuAndSubMenu(payload) || {};
          } else {
            state.isFetching = false;
            state.isAuthorized = false;
            state.error = null;
            state.userRolesAndMenu = [];
            state.userRole = {};
          }
        }
      )
      .addCase(fetchUserRole.rejected, (state: UserStateI) => {
        state.isFetching = false;
        state.isAuthorized = false;
        state.error = 'Error';
        state.userRolesAndMenu = [];
        state.userRole = {};
      });
  },
});

export const userRoleActions = userRoleSlice.actions;
export default userRoleSlice.reducer;
