import React from 'react';
import moment, { Moment } from 'moment';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import DatePicker from '../../../../UI/DatePicker';

interface DateControlPropsI {
  taskDate: Moment;
  setTaskDate: (date: Moment | ((prevState: Moment) => Moment)) => void;
  max?: string;
}

const DateControl: React.FC<DateControlPropsI> = ({
  taskDate,
  setTaskDate,
  max,
}) => {
  const showPrevTaskDate = () => {
    setTaskDate((prevState: Moment) => prevState.clone().subtract(1, 'days'));
  };
  const showNextTaskDate = () => {
    setTaskDate((prevState: Moment) => prevState.clone().add(1, 'days'));
  };

  const onDateChange = (e: Moment | null) => {
    if (e && e.isValid()) {
      setTaskDate(e);
    }
  };
  const today = moment();

  const { ChevronLeft: ChevronLeftIcon, ChevronRight: ChevronRightIcon } =
    Icons;
  return (
    <>
      <Button size='medium' variant='outlined' onClick={showPrevTaskDate}>
        <ChevronLeftIcon height={20} width={20} />
      </Button>
      {/* {taskDate.isValid() ? (
        <SingleDatePicker
          onChange={(value) => {
            onDateChange(value as Moment | null);
          }}
          aria-label='TaskDate'
          format='YYYY-MM-DD'
          size='medium'
          placeholder={taskDate.format('YYYY-MM-DD')}
          disabled={false}
        />
      ) : (
        <SingleDatePicker
          prefixCls='shell-calendar'
          size='medium'
          aria-label='TaskDate'
          onChange={(value) => {
            onDateChange(value as Moment | null);
          }}
        />
      )} */}
      <DatePicker
        placeholder='YYYY-MM-DD'
        value={taskDate}
        onChange={onDateChange}
        max={max}
      />

      <Button
        size='medium'
        variant='outlined'
        onClick={showNextTaskDate}
        disabled={
          max !== '' &&
          taskDate.format('YYYY-MM-DD') >= today.format('YYYY-MM-DD')
        }
      >
        <ChevronRightIcon height={20} width={20} />
      </Button>
    </>
  );
};
DateControl.defaultProps = {
  max: '',
};

export default DateControl;
