import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem } from '@sede-x/shell-ds-react-framework';
import { useNavigate } from 'react-router-dom';
import routepaths from '../../../router/routepaths';
import { get as GetApi } from '../../../services/COPApi';
import Loader from '../../../UI/Loader';

const UserOptionsContainer = styled.div`
  z-index: 5;
  width: 100%;
  & > div {
    width: 100%;
  }
`;

const UserOptions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);

      const response = await GetApi(`/api/User/Logout`);
      if (response.status === 200) {
        setLoading(false);
        navigate(routepaths.logout);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <UserOptionsContainer>
        <Menu size='large'>
          <MenuItem onClick={onClick}>Logout</MenuItem>
        </Menu>
      </UserOptionsContainer>
    </>
  );
};

export default UserOptions;
