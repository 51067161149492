import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SideNav from './SideNav';
import MainMenu from './MainMenu';
import useDispatchOnLoadActions from '../hooks/useDispatchOnLoadActions';
import { FullStateI } from '../types/All.type';
import { toggleActions } from '../store/Toggle';

interface IContentType {
  showsidenav: string;
}

const LayoutContainer = styled.div`
  font-family: 'Shell Font';
  background-color: ${(prop) => prop.color};
  min-height: 100vh;
`;

const MidSection = styled.section`
  display: flex;
  flex: 0;
  min-height: calc(100vh - 76px);
  position: relative;
`;

const ContentContainer = styled.section<IContentType>`
  flex: 1;
  box-sizing: border-box;
  padding: 15px;
  margin-left: ${(props) => props.showsidenav};
  width: ${(props) => (props.showsidenav ? 'calc(100% - 215px)' : '100%')};
  transition: margin-left 0.5s;
`;

const Layout = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const { isDark } = useSelector((state: FullStateI) => state.Theme);
  useDispatchOnLoadActions();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      toggleActions.updateToggle({
        isToggle: showSideNav,
      })
    );
  }, [dispatch, showSideNav]);

  const togelSideNav = () => {
    setShowSideNav((show) => !show);
  };

  return (
    <LayoutContainer color={isDark ? 'rgba(42,48,55,1)' : 'rgb(242, 242, 242)'}>
      <MainMenu togelSideNav={togelSideNav} />
      <MidSection>
        <SideNav showSideNav={showSideNav} />
        <ContentContainer showsidenav={showSideNav ? '215px' : '0'}>
          <Outlet />
        </ContentContainer>
      </MidSection>
    </LayoutContainer>
  );
};

export default Layout;
