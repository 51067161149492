import styled from 'styled-components';
import theme from 'styled-theming';

export const backgroundColor = theme('name', {
  light: '#fff',
  dark: 'rgba(30,36,43,1)',
});

const Card = styled.div`
  word-wrap: break-word;
  background-color: ${backgroundColor};
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 10%) 0 0 2px 0, rgb(0 0 0 / 10%) 0 2px 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
  padding: 15px;
  width: 100%;
`;

export const CardSection = styled(Card)`
  margin-bottom: 20px;
`;

export default Card;
