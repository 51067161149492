/* stylelint-disable */
import styled from 'styled-components';
import { Modal as ShellModal } from '../../../UI/Modal';

export const FieldNominationWrapper = styled.div`
  .card {
    padding-bottom: unset;
  }
  .heading {
    display: flex;
    .shell-text-heading {
      margin-right: 5px;
    }
  }

  .unmapped_table_container {
    .shell-table-container {
      max-height: 30vh;
      position: relative;
      overflow-y: auto;
    }
  }

  .mail-counts {
    display: flex;
    align-items: center;
    margin-left: 17px;
    font-weight: 500;
    position: relative;
    font-size: 15px;
    .counts {
      background: red;
      color: white;
      padding: 5px;
      height: 18px;
      min-width: 18px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -10px;
      top: -5px;
      font-size: 10px;
      font-weight: 700;
    }
  }
  .last-refreshed {
    color: rgb(0, 133, 87);
    font-size: 15px;
    align-self: center;
    font-weight: 500;
  }
  .filter-container {
    margin: 30px 0 20px 0;
    justify-content: space-between;
    .filters {
      display: flex;
      gap: 20px;
    }
    .select-filter {
      width: 135px;
      .shell-select-container-selection-item {
        font-weight: 600;
      }
    }
    .date-range {
      width: 270px;
    }
    span {
      font-weight: 500;
      min-width: fit-content;
      font-size: 14px;
    }

    .energy {
      display: flex;
      span {
        align-self: center;
        margin-right: 10px;
      }
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    .shell-toggle span {
      margin: 0 10px 0 5px;
    }
  }
  .show-success-status {
    display: flex;
  }
  .close-mapping {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .grid-actions {
    display: flex;
    button:last-child {
      margin-left: 5px;
    }
  }
  .compare-energy {
    background-color: transparent;
    &.check {
      svg > path {
        fill: green;
      }
    }
    &.cross {
      svg > path {
        fill: red;
      }
    }
  }
  .aligne-status__wrapper {
    display: flex;
    gap: 5px;

    .undo-acknowledge {
      height: 24px;
      width: 24px;
      min-height: 24px;
    }
  }
  .aligne-status {
    width: fit-content;
    min-width: 80px;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    border-radius: 16px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 13px;
    &.not_requested {
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: rgba(64, 64, 64, 1);
    }
    &.submitted {
      background-color: rgba(251, 207, 9, 1);
      color: rgba(64, 64, 64, 1);
    }
    &.success,
    &.acknowledged {
      background-color: rgba(28, 132, 80, 1);
    }
    &.failure {
      background-color: rgba(217, 54, 56, 1);
    }
  }

  .upload-progress {
    display: flex;
    justify-content: center;
    .shell-alert {
      width: fit-content;
      div:has(.refresh-field_noms) {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .refresh-field_noms {
        text-decoration: underline;
      }
    }
  }

  .shell-table-cell:has(.table-row_disabled) {
    opacity: 0.7;
  }

  .shell-table-cell:has(.da-variance-breach_alert) {
    background-color: rgba(251, 207, 9, 1);
    color: black;
  }

  .shell-table-cell:has(.wd-variance-breach_alert) {
    background-color: rgba(217, 54, 56, 1);
    color: white;
    .compare-energy {
      &.cross {
        svg > path {
          fill: white;
        }
      }
    }
  }

  .shell-table-row:hover > .shell-table-cell:has(.da-variance-breach_alert) {
    background-color: rgba(251, 207, 9, 1);
    color: black;
  }

  .shell-table-row:hover > .shell-table-cell:has(.wd-variance-breach_alert) {
    background-color: rgba(217, 54, 56, 1);
    color: white;
    .compare-energy {
      &.cross {
        svg > path {
          fill: white;
        }
      }
    }
  }
`;

export const ColumnTitleContainer = styled.div`
  display: flex;
  .sorting-wrapper {
    display: flex;
    .shell-table-column-sorter {
      margin: unset;
      svg:first-child path {
        fill: ${(prop) => prop.color};
      }
    }
    .shell-table-column-title {
      display: flex;
      align-items: center;
    }
  }
  .pointer {
    cursor: pointer;
  }
`;

export const TableLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.05);
  z-index: 1000;

  .loader-wrapper {
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
  }
`;

export const UploadToAligneModal = styled(ShellModal)`
  .shell-modal-container {
    width: 85% !important;
  }
  .shell-table-container {
    max-height: 50vh;
    position: relative;
    overflow-y: auto;
  }
  .shell-table-thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .zKey_wrapper {
    display: flex;
    align-items: center;
    button {
      margin-left: 3px;
    }
  }
  .shell-text-input {
    width: 180px;
  }
  .shell-select-container {
    width: 150px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 15px;
  button:last-child {
    margin-left: 10px;
  }
  &.upload-aligne {
    .button-wrapper {
      display: flex;
    }
  }
  &.upload-aligne__confirm {
    justify-content: space-between;
    .message {
      display: flex;
      align-items: center;
      margin-right: 10px;
      font-weight: 500;
      color: rgba(217, 54, 56, 1);
    }
  }
`;

export const AuditDetailWrapper = styled.div`
  min-height: 250px;
  max-height: 60vh;
  overflow-y: auto;
  .title {
    font-weight: 600;
    font-size: 14px;
  }
  .logs-info {
    width: 25px;
    height: 25px;
    min-height: 25px;
    margin-left: 5px;
  }
  th,
  td {
    padding: 3px 14px 3px 0 !important;
    background-color: unset !important;
  }
  tr td:first-child,
  tr td:last-child {
    display: flex;
  }
  .shell-table tr:hover td {
    background-color: unset !important;
  }

  pre {
    background: rgba(0, 0, 0, 0.1);
    color: unset;
    padding: 5px;
    border-radius: 3px;
  }
  .__json-pretty__ .__json-value__ {
    color: rgb(51, 204, 51);
  }
  .__json-pretty__ .__json-key__ {
    color: #eb0f5f;
  }
`;

export const Modal = styled(ShellModal)`
  .button-style {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
  }
`;
