import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const BeachSwapsComp = React.lazy(() => import('../../components/BeachSwaps'));
const CoastKeyMapperComp = React.lazy(
  () => import('../../components/BeachSwaps/CoastKeyMapper')
);

type BSMainProps = 'beachSwap' | 'coastKeyMapper';

interface BeachSwapMainCompProps {
  type: BSMainProps;
}

const BeachSwapsMainComponent: React.FC<BeachSwapMainCompProps> = ({
  type,
}) => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.genesis}>
      {type === 'beachSwap' && <BeachSwapsComp />}
      {type === 'coastKeyMapper' && <CoastKeyMapperComp />}
    </CheckAuthorizationWithSuspense>
  );
};

export default BeachSwapsMainComponent;
